import { flatten } from 'lodash';
import isEqual from 'lodash/isEqual';
import { createSelector } from 'reselect';

import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { selectTabs } from '../tab/selectors';
import { Entries } from '../typings';
import { IAudienceComparisonSlice } from './typings';

export const selectAppliedAudiencesToCompare = ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.appliedAudiencesToCompare;

export const selectAudiencesToCompare = ( state: IAudienceComparisonSlice ) => state.audienceComparison.audiencesToCompare;

export const selectIsEqualComparisons = ( state: IAudienceComparisonSlice ) =>
  isEqual( state.audienceComparison.audiencesToCompare, state.audienceComparison.appliedAudiencesToCompare );
export const selectBuyerTypesByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.buyerTypes;
export const selectPreBuiltAudiencesByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.preBuiltAudienceIds;

export const selectBrandsByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.brands;

export const selectCategoryByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.category || 0;
export const selectCampaignsByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.marketingCampaigns;
export const selectD2CByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.d2c;
export const selectEngagementByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.engagementStatus;
export const selectManufacturerByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.manufacturers;
export const selectOriginByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.origin;
export const selectFlavorsByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.flavors;
export const selectSizesByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.sizes;
export const selectTimeFilterFromByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.fromDate;
export const selectTimeFilterToByAudienceId = ( audienceId: AUDIENCE_ID ) => ( state: IAudienceComparisonSlice ) =>
  state.audienceComparison.audiencesToCompare[audienceId]?.audienceParameters?.toDate;
export const selectAudienceComparisonStats = ( state: IAudienceComparisonSlice ) => state.audienceComparison.stats;
export const selectAudienceComparisonBenchmarkId = ( state: IAudienceComparisonSlice ) => state.audienceComparison.stats.only
  .find( ( segment ) => segment.benchmark )?.segmentId ?? '';
export const selectAudienceComparisonIsLoading = ( state: IAudienceComparisonSlice ) => state.audienceComparison.isLoading;
export const selectAudienceComparisonIsAborting = ( state: IAudienceComparisonSlice ) => state.audienceComparison.isAborting;
export const selectNewAudienceId = createSelector( [selectTabs, selectAudiencesToCompare], ( tabs, audiencesToCompare ) =>
  Object.keys( tabs )
    .filter( ( tabId ) => !Object.keys( audiencesToCompare ).includes( tabId ) )[0] as AUDIENCE_ID );
export const selectScenario = ( state: IAudienceComparisonSlice ) => state.audienceComparison.scenario;
export const selectAppliedScenario = ( state: IAudienceComparisonSlice ) => state.audienceComparison.appliedScenario;
export const selectFinishedTabsIsEqualComparisons = createSelector(
  selectTabs,
  selectAudiencesToCompare,
  selectAppliedAudiencesToCompare,
  selectScenario,
  selectAppliedScenario,
  ( tabs, audiencesToCompare, appliedAudiencesToCompare, scenario, appliedScenario ) => isEqual(
    ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> ).filter( ( audience ) => tabs[audience[0]]?.isFinished ),
    Object.entries( appliedAudiencesToCompare )
  ) && scenario === appliedScenario
);
