import { flatten, intersectionBy, sortBy, uniqBy } from 'lodash';

import { FlavorSizes } from '../../../../../apollo/graphql-types';

export const calculateNewSizes = ( flavorsSizes?: FlavorSizes, selectedFlavors?: string[] | null ) => {
  const flavors = flatten( [
    ...( flatten( flavorsSizes?.groups?.map( ( group )=> group.flavors ) ) || [] ),
    ...( flavorsSizes?.flavors || [] ),
  ] );

  return !selectedFlavors?.length
    ? sortBy(
      uniqBy(
        flatten(
          flavors.map( ( flavor ) => flavor.sizes || [] ) ),
        'sizeIdentifier'
      ),
      'orderingIdx'
    )
    : sortBy(
      flatten(
        intersectionBy(
          ...flavors
            .filter( ( flavor ) => selectedFlavors?.includes( flavor.flavorName || '' ) )
            .map( ( flavor ) => flavor.sizes || []
            ),
          'sizeIdentifier'
        )
      ),
      'orderingIdx'
    );
};
