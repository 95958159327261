import './Flavors.scss';

import { UpOutlined } from '@ant-design/icons';
import { NetworkStatus } from '@apollo/client';
import { Switch, Tree, TreeProps } from 'antd';
import Search from 'antd/es/input/Search';
import cn from 'classnames';
import { Key, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFlavorsSizesLazyQuery } from '../../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../../store/audienceComparison/actions';
import {
  selectBrandsByAudienceId,
  selectFlavorsByAudienceId,
} from '../../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../../store/tab/actions';
import { checkSearchValue } from '../../../../AudienceComparison/AttributesComparison/Filter/utils';
import { ErrorRetryCircle } from '../../../../Error/ErrorRetryCircle';
import { AUDIENCE_ID, options } from '../../const';

interface IFlavorsProps {
  activeTab: AUDIENCE_ID;
}

export const Flavors = ( { activeTab }: IFlavorsProps ) => {
  const dispatch = useDispatch();

  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedFlavors = useSelector( selectFlavorsByAudienceId( activeTab ) );

  const [showFlavors, setShowFlavours] = useState( false );
  const [disabled, setDisabled] = useState( false );
  const [searchText, setSearchText] = useState<string>( '' );
  const [searchValue, setSearchValue] = useState<string>( '' );
  const [searchCount, setSearchCount] = useState( 0 );
  const [expandedKeys, setExpandedKeys] = useState<Key[]>( [] );

  const messageSelectedTooManyBrands = 'Flavours can be chosen for single brand only.';
  const [flavorsMessage, setFlavoursMessage] = useState(
    selectedBrands.length > 1 ? messageSelectedTooManyBrands : ''
  );

  const [loadFlavorsAndSizes, { data, error, loading, refetch, networkStatus }] = useFlavorsSizesLazyQuery();

  const handleFlavorCLick = ( checked: boolean ) => {
    setShowFlavours( checked );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( checked ) {
      loadFlavorsAndSizes( {
        ...options(),
        notifyOnNetworkStatusChange: true,
        variables:                   {
          brandId: selectedBrands[0].toString(),
        },
      } );
    } else {
      dispatch( setAudienceParameters( [activeTab, { flavors: undefined }] ) );
    }
  };

  useEffect( () => {
    if ( !!data ) {
      if ( networkStatus !== NetworkStatus.loading
        && ( ( !data?.flavorsSizes.flavors?.length && !data?.flavorsSizes.groups?.length ) || error )
      ) {
        if ( !data?.flavorsSizes.flavors?.length && !data?.flavorsSizes.groups?.length ) {
          setDisabled( true );
        }
        setShowFlavours( false );
        setFlavoursMessage( 'There are no flavours for this brand.' );
      }
    }
  }, [data, networkStatus, error] );

  useEffect( () => {
    setShowFlavours( false );
    setDisabled( false );
    setFlavoursMessage( selectedBrands.length > 1 ? messageSelectedTooManyBrands : '' );
  }, [selectedBrands] );

  useEffect( () => {
    if ( !!selectedFlavors?.length ) {
      handleFlavorCLick( true );
    }
  }, [] );

  const generateFlavorItem = ( name: string, isParent?: boolean ) => ( {
    key:       name,
    title:     name,
    className: cn( {
      highlight:  checkSearchValue( name, searchText ),
      parent:    !!isParent,
    } ),
  } );

  const generateTreeData = useMemo( () => {
    let searchCount = 0;
    let groupsData: TreeProps['treeData'] = [];
    let flavorsData: TreeProps['treeData'] = [];
    const expandedKeysToSet: Key[] = [];

    if ( !!data?.flavorsSizes.groups?.length ) {
      groupsData = ( data?.flavorsSizes.groups || [] ).map( ( group ) => {
        if ( checkSearchValue( group.flavorGroupName, searchText ) ) {
          searchCount++;

          expandedKeysToSet.push( group.flavorGroupName as Key );
        }

        return {
          ...generateFlavorItem( group.flavorGroupName || '', true ),
          children: group.flavors.map( ( flavor ) => {
            if ( checkSearchValue( flavor.flavorName, searchText ) ) {
              searchCount++;

              if ( !expandedKeysToSet.includes( group.flavorGroupName as Key ) ) {
                expandedKeysToSet.push( group.flavorGroupName as Key );
              }
            }

            return generateFlavorItem( flavor.flavorName || '' );
          } ),
        };
      } );
    }

    if ( !!data?.flavorsSizes.flavors?.length ) {
      flavorsData = ( data?.flavorsSizes.flavors || [] ).map( ( flavor ) => {
        if ( checkSearchValue( flavor.flavorName, searchText ) ) {
          searchCount++;
        }

        return {
          ...generateFlavorItem( flavor.flavorName || '' ),
        };
      } );
    }

    if ( !!searchCount ) {
      setSearchCount( searchCount );
    }

    if ( !!expandedKeysToSet ) {
      setExpandedKeys( expandedKeysToSet );
    }

    return [...groupsData, ...flavorsData];
  }, [data, searchText] );

  return (
    <div className={cn( 'flavors-sizes', {
      message: !!flavorsMessage,
      visible: showFlavors && !loading,
    } )}>
      <Switch
        onClick={handleFlavorCLick}
        checked={showFlavors}
        disabled={ selectedBrands.length > 1 || disabled}
        loading={[ NetworkStatus.loading, NetworkStatus.refetch].includes( networkStatus ) }
      /> Specify Flavour

      { flavorsMessage && !loading && (
        <div className='flavors-sizes-message'>
          {flavorsMessage}
        </div>
      )}

      {showFlavors && error && (
        <ErrorRetryCircle
          title='Flavours Load Failed'
          description='Some error occured, please try again'
          onClick={() => {
            refetch();
          }}
        />
      )
      }

      {!flavorsMessage && showFlavors && !error && !loading && (
        <div className='flavors-sizes-content'>
          <div className='flavors-sizes-title'>
          Select Flavour
          </div>
          <div className='search'>
            <Search
              allowClear
              placeholder='Search for Flavour'
              onSearch={( value ) => {
                const validatedValue = value.trim().replace( / +/g, ' ' );
                setSearchText( validatedValue );
                setSearchValue( validatedValue );
                setSearchCount( 0 );
              }}
              onChange={( e ) => setSearchValue( e.target.value )}
              value={searchValue}
              className={cn( {
                'show-clear-icon': !!searchValue || !!searchText,
              } )}
            />

            {( !!searchText.length && !!searchCount )
             && <span className='search-matches'>{searchCount} match{searchCount > 1 ? 'es' : ''} found</span>}

            {!!searchText.length && !searchCount && <span className='search-matches'>No matches found</span>}
          </div>
          <div className='flavors-sizes-list'>
            <Tree
              checkable
              selectable={false}
              switcherIcon={<UpOutlined />}
              treeData={generateTreeData}
              checkedKeys={selectedFlavors as Key[]}
              expandedKeys={expandedKeys}
              onExpand={( expandedKeys ) => setExpandedKeys( expandedKeys )}
              onCheck={( checked ) => {
                dispatch( setAudienceParameters( [activeTab, {
                  flavors: ( checked as string[] ).length ? checked as string[] : undefined,
                }] ) );
                dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
