import Search from 'antd/es/input/Search';
import cn from 'classnames';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import { CampaignAffinity } from '../../../../apollo/graphql-types';
import { checkSearchValue } from '../../../AudienceComparison/AttributesComparison/Filter/utils';
import { AUDIENCE_ID } from '../const';
import { MarketingCampaingsContent } from './MarketingCampaingsContent';

interface IAffinityListProps {
  activeTab: AUDIENCE_ID;
  affinityList: CampaignAffinity[];
}

export const AffinityList = ( { activeTab, affinityList }: IAffinityListProps ) => {
  const [searchText, setSearchText] = useState<string>( '' );
  const [searchValue, setSearchValue] = useState<string>( '' );

  const filteredAffinityList = affinityList.map( ( affinity ) => {
    const marketingCampaigns = affinity.marketingCampaigns.filter( ( campaing ) => !!searchText
      ? checkSearchValue( campaing.marketingCampaignName, searchText )
      : true );

    return {
      ...affinity,
      marketingCampaigns,
    };
  }
  ).filter( ( affinity ) => !!affinity.marketingCampaigns.length );

  // const handleEngagementRadioGroupChange = ( e: RadioChangeEvent ) => {
  //   dispatch( setAdditionalParametersWizard( { isFinished: false, activeTab } ) );
  //   dispatch( setAudienceParameters( [activeTab, { engagementStatus: e.target.value }] ) );
  // };

  return (
    <>
      <div className='search-affinity'>
        <Search
          allowClear
          onSearch={( value ) => {
            const validatedValue = value.trim().replace( / +/g, ' ' );
            setSearchText( validatedValue );
            setSearchValue( validatedValue );
          }}
          onChange={( e ) => setSearchValue( e.target.value )}
          value={searchValue}
          className={cn( {
            'show-clear-icon': !!searchValue || !!searchText,
          } )}
        />

        {!!searchText.length && !filteredAffinityList.length && (
          <span className='search-matches'>No matches found</span>
        )}
      </div>
      <div className='affinity-list'>
        {
          ( filteredAffinityList.length !== 0 ? filteredAffinityList : affinityList )?.map( ( affinity ) => (
            <Fragment key={affinity.affinityName}>
              <p className='affinity-list-name'>{affinity.affinityName}</p>
              <MarketingCampaingsContent
                activeTab={activeTab}
                marketingCampaignsList={affinity.marketingCampaigns}
                affinityName={affinity.affinityName || ''}
              />
            </Fragment>
          ) )
        }
        {/* {selectedCampaigns !== undefined && isEngagement && (
          <div className='engagement'>
            <Divider />
            <div className='title'>
              <TitleWithCaption caption={ENGAGEMENT} captionFontSize={16} />
            </div>
            <Radio.Group
              options={engagementOptions}
              optionType='button'
              buttonStyle='solid'
              value={selectedEngagement}
              onChange={handleEngagementRadioGroupChange}
            />
            <div className='note-caption'>
              <TitleWithCaption
                caption='*Engagement is available only for Email Campaigns'
                captionFontSize={12}
              />
            </div>
          </div>
        )} */}
      </div>
    </>
  );

};
