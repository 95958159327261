import { IncomingScenario, Origin } from '../../apollo/graphql-types';

export const webLinks: Record<number, string> = {
  97:  'mms.com',
  242: 'ethelm.com',
};

export const partyData = {
  [Origin.FirstParty]: '1PD',
  [Origin.ThirdParty]: '3PD',
};

export const partyDataToName = {
  [Origin.FirstParty]: 'First-Party Data',
  [Origin.ThirdParty]: 'Third-Party Data',
};

export const getOverviewDescriptionByScenario = ( scenario: IncomingScenario ) =>
  scenario === IncomingScenario.Exclusive
    ? 'Comparison of the National Reference Audience and Audience created as an exclusive segment present in Audience 1 but not in Audience 2' //eslint-disable-line
    : scenario === IncomingScenario.Overlap
      ? 'Comparison of the National Reference Audience and Audience created as the overlap of the previously selected audiences'
      : scenario === IncomingScenario.Profile
        ? 'Comparison of the National Reference Audience and your custom Audience'
        : 'Comparison of previously selected audience profiles ';
