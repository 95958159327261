import './FilledAudienceComparison.scss';

import { EditOutlined } from '@ant-design/icons';
import { Button, Col, Flex, Layout, Row, Spin, Typography } from 'antd';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  ComparisonInitiationResult,
  IncomingScenario,
  useAudienceDistributionLazyQuery,
  useInitiateAudienceDistributionCalcMutation,
  useSummaryCardsLazyQuery,
} from '../../apollo/graphql-types';
import ErrorSvg from '../../assets/icons/404.svg';
import {
  resetAudienceComparison,
  setAudienceComparisonIsAborting,
  setAudienceComparisonIsLoading,
  setAudienceComparisonStats,
} from '../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAppliedScenario,
  selectAudienceComparisonIsAborting,
  selectAudienceComparisonIsLoading,
  selectScenario,
} from '../../store/audienceComparison/selectors';
import { resetTabs, setActiveTab } from '../../store/tab/actions';
import { resetWizard, setWizardIsOpen } from '../../store/wizzard/actions';
import { AUDIENCE_ID, options } from '../Blade/collapse/const';
import TitleWithCaption from '../TitleWithCaption/TitleWithCaption';
import { AttributesComparison } from './AttributesComparison/AttributesComparison';
import { useAudienceShortName } from './AudienceShortName';
import { getOverviewDescriptionByScenario } from './const';
import { ExportXLSX } from './Header/ExportXLSX';
import { HeaderCaption } from './Header/HeaderCaption';
import { SaveTemplate } from './Header/SaveTemplate/SaveTemplate';
import { SummaryCardList } from './SummaryCards/SummaryCardList';

export const AudienceNamesContext = React.createContext( {} as Record<AUDIENCE_ID, string> );
export const AudienceRequestContext = React.createContext<ComparisonInitiationResult | undefined>( undefined );

const FilledAudienceComparison = () => {
  const [error, setError] = useState( false );
  const [retry, setRetry] = useState( false );
  const [namesUpdated, setNamesUpdated] = useState( false );

  const audienceNames = useAudienceShortName();

  const isAudienceComparisonLoading = useSelector( selectAudienceComparisonIsLoading );
  const scenario = useSelector( selectScenario );
  const appliedScenario = useSelector( selectAppliedScenario );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const audienceComparisonIsAborting = useSelector( selectAudienceComparisonIsAborting );

  const dispatch = useDispatch();

  const onEditClick = () => {
    dispatch( setWizardIsOpen( true ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE_A ) );
  };

  const abortController = useRef( new AbortController() );

  const [summaryCards, { data: summaryCardsData }] = useSummaryCardsLazyQuery();

  const [initiateComparison, {
    data: dataInitiate,
    error: initiateError,
    reset: resetInitialLoading,
  }] = useInitiateAudienceDistributionCalcMutation();

  const [audienceDistribution, {
    data: dataDistribution,
    error: distributionError,
  }] = useAudienceDistributionLazyQuery();

  const abort = () => {
    abortController.current.abort();
    abortController.current = new AbortController();
  };

  useEffect( () => {
    if ( audienceComparisonIsAborting ) {
      abort();
      dispatch( setAudienceComparisonIsAborting( false ) );
      dispatch( setAudienceComparisonIsLoading( false ) );

      dispatch( resetAudienceComparison() );
      dispatch( resetWizard() );
      dispatch( resetTabs() );
    }
  }, [audienceComparisonIsAborting] );

  useEffect( () => {
    if ( !namesUpdated && !Object.values( audienceNames ).includes( 'loading' ) ) {
      setNamesUpdated( true );
    }
  }, [audienceNames] );

  useEffect( () => {
    if ( !!dataInitiate
      && !!dataDistribution
      && namesUpdated
      && !!Object.values( audienceNames ).length
    ) {
      try {
        summaryCards( {
          ...options(),
          variables: {
            summaryCardsPrerequisites: {
              scenario:  appliedScenario,
              requestId: dataInitiate?.initiateAudienceDistributionCalc?.requestId,
            },
          },
        } ).then( () => dispatch( setAudienceComparisonIsLoading( false ) ) )
          .catch( ( error ) => {
            if ( error.message !== 'The user aborted a request.' ) {
              setError( true );
            console.error(error); //eslint-disable-line
            }
          } );
      } catch ( error ) {
        console.error(error); //eslint-disable-line
      }
    }
  }, [dataInitiate, dataDistribution, namesUpdated] );

  const initialLoading = async () => {
    try {
      setError( false );
      setRetry( false );
      resetInitialLoading();

      const initialCalcResponse = await initiateComparison( {
        ...options( abortController.current.signal ),
        variables: {
          comparisonScenario: {
            scenario,
            audiences: Object.values( appliedAudienceToCompare ),
          },
        },
      } );

      if ( !!initialCalcResponse.data && !isEmpty( appliedAudienceToCompare ) ) {
        dispatch( resetWizard() );
        dispatch( resetTabs() );

        const { data, error } = await audienceDistribution( {
          ...options( abortController.current.signal ),
          variables: {
            totalsPrerequisites: {
              requestId:     initialCalcResponse.data.initiateAudienceDistributionCalc.requestId,
              queryId:       initialCalcResponse.data.initiateAudienceDistributionCalc?.queryId,
              audienceNames: [],
            } },
        } );

        if ( !data?.audienceDistribution ) {
          throw new Error( 'Something went wrong' );
        }

        dispatch( setAudienceComparisonStats( data?.audienceDistribution ) );
      }
    } catch ( error ) {
      setError( true );
      console.error( error )  // eslint-disable-line
    }
  };

  useEffect( () => {
    if ( isAudienceComparisonLoading ) {
      initialLoading();
    }
  }, [isAudienceComparisonLoading, retry] );

  return (
    <AudienceNamesContext.Provider value={audienceNames}>
      <AudienceRequestContext.Provider value={dataInitiate?.initiateAudienceDistributionCalc}>
        <Layout className={cn( 'audience-comparison', {
          loading: isAudienceComparisonLoading,
          error:   initiateError || distributionError || error,
        } )}>
          {
            initiateError || distributionError || error
              ? (
                <>
                  <img src={ErrorSvg} alt='Service Temporarily Unavailable' className='error-image'/>
                  <Typography.Title level={3} >Service Temporarily Unavailable</Typography.Title>
                  <p className='caption'>Service disruption detected. Please try again soon or reach out to
                    <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
                for help.</p>
                  <Button type='primary' onClick={() => initialLoading()}>Retry</Button>
                </>
              )
              :isAudienceComparisonLoading
                ? <Flex vertical align='center' justify='center' className='audience-comparison-spinner'>
                  <Spin />
                  <span>The request might take a little longer...</span>
                </Flex>
                : <>
                  <Row className='audience-comparison-header'>
                    <Col span={12}>
                      <TitleWithCaption
                        headingLevel={1}
                        heading={`${appliedScenario === IncomingScenario.Compare ? 'Audience Comparison' : 'Audience Profile'}`}
                      />
                    </Col>
                    <Col span={12}>
                      <Flex wrap='wrap' gap='small' justify='flex-end' className='audience-comparison-header-buttons'>
                        <Button onClick={onEditClick} icon={<EditOutlined />}>Edit Parameters</Button>
                        <ExportXLSX />
                        <SaveTemplate />
                      </Flex>
                    </Col>
                    <HeaderCaption />
                  </Row>
                  <Row>
                    <Col span={24} className='overview'>
                      <div className='section-title'>
                        <TitleWithCaption
                          headingLevel={2}
                          heading='Overview'
                        />
                      </div>
                      <div className='scenario-overview-description'>
                        {getOverviewDescriptionByScenario( appliedScenario )}
                      </div>
                      {
                        !!summaryCardsData?.summaryCards
                      && !!dataInitiate?.initiateAudienceDistributionCalc.requestId
                      && (
                        <SummaryCardList
                          summaryCards={summaryCardsData.summaryCards}
                          attributesCalculations={dataInitiate?.initiateAudienceDistributionCalc}
                        />
                      )
                      }
                    </Col>
                  </Row>
                  <AttributesComparison requestId={dataInitiate?.initiateAudienceDistributionCalc?.requestId || ''}
                  />
                </>
          }
        </Layout>
      </AudienceRequestContext.Provider>
    </AudienceNamesContext.Provider>
  );
};

export default FilledAudienceComparison;
