import './App.scss';

import { ConfigProvider } from 'antd';
import { Provider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import AudienceComparison from './pages/AudienceComparison';
import { Login } from './pages/Login/Login';
import { store } from './store/store';

function App () {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          components: {
            Layout: { colorBgLayout: 'white', algorithm: true },
            Button: { primaryShadow: '0', algorithm: true, colorPrimaryHover: 'rgba(29, 57, 196, 1)' },
            Form:   {
              verticalLabelPadding: '0 0 4px 0',
              itemMarginBottom:     12,
              algorithm:            true,
              labelColor:           'rgba(0, 0, 0, 0.65)',
            },
            Select: {
              optionActiveBg:   'rgba(0, 0, 0, 0.06)',
              optionSelectedBg: 'rgb(240, 245, 255)',
            },
            Typography: {
              titleMarginBottom: 0,
              titleMarginTop:    0,
              fontFamily:        'WorkSans-SemiBold, sans-serif',
            },
            Collapse:   { headerBg: '#fff' },
            Tabs:       { horizontalItemGutter: 20 },
          },
          token: {
            fontFamily:       'WorkSans, sans-serif',
            colorBgContainer: '#fff',
            colorPrimary:     '#0000A0',
            colorLink:        'rgba(0, 0, 160, 1)',
            colorErrorActive: 'rgba(207, 19, 34, 1)',
          },
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route element={<PrivateRoute />}>
              <Route path='/' element={<AudienceComparison />} />
              <Route path='*' element={<Navigate to='/' />} />
            </Route>
            <Route path='/login' element={<Login />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </Provider>
  );
}

export default App;
