/* eslint-disable max-len */

import { createAction } from '@reduxjs/toolkit';

import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { IDuplicatePayload } from '../audienceComparison/actions';
import {
  ICommonWizardInterface,
  IMarsBrandsWizardInterface,
  ISourceWizardInterface,
} from './wizardSlice';

export const setSingleWizzardById = createAction<AUDIENCE_ID>( 'wizzard/setSingleWizzardById' );
export const duplicateWizzardById = createAction<IDuplicatePayload>( 'wizzard/duplicateWizzardById' );
export const setWizzardForExclusiveScenario = createAction( 'wizzard/setWizzardForExclusiveScenario' );
export const deleteWizzardById = createAction<AUDIENCE_ID>( 'wizzard/deleteWizzardById' );
export const resetWizzardById = createAction<AUDIENCE_ID>( 'wizzard/resetWizzardById' );
export const setWizardIsOpen = createAction<boolean>( 'wizzard/setWizardIsOpen' );
export const collapseAllWizards = createAction<AUDIENCE_ID>( 'wizzard/collapseAllWizards' );
export const setSourceWizard = createAction<Partial<ISourceWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setSourceWizard' );
export const setPrebuiltAudienceWizard = createAction<Partial<ICommonWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setPrebuiltAudienceWizard' );
export const setMarsBrandsWizard = createAction<Partial<IMarsBrandsWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setMarsBrandsWizard' );
export const setOriginWizard = createAction<Partial<ICommonWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setOriginWizard' );
export const setAdditionalParametersWizard = createAction<Partial<ICommonWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setAdditionalParametersWizard' );
export const setSizesNames = createAction<Record<string, string>>( 'wizzard/setSizesNames' );
export const setCompetitorsWizard = createAction<Partial<ICommonWizardInterface> & {activeTab: AUDIENCE_ID}>( 'wizzard/setCompetitorsWizard' );
export const duplicateAudienceById = createAction<IDuplicatePayload>( 'wizzard/duplicateAudienceById' );
export const resetAllWizards = createAction<AUDIENCE_ID>( 'wizzard/resetAllWizards' );
export const resetSourceWizard = createAction<AUDIENCE_ID>( 'wizzard/resetSourceWizard' );
export const resetPrebuiltAudienceWizard = createAction<AUDIENCE_ID>( 'wizzard/resetPrebuiltAudienceWizard' );
export const resetMarsBrandsWizard = createAction<AUDIENCE_ID>( 'wizzard/resetMarsBrandsWizard' );
export const resetOriginWizard = createAction<AUDIENCE_ID>( 'wizzard/resetOriginWizard' );
export const resetAdditionalParametersWizard = createAction<AUDIENCE_ID>( 'wizzard/resetAdditionalParametersWizard' );
export const resetCompetitorsWizard = createAction<AUDIENCE_ID>( 'wizzard/resetCompetitorsWizard' );
export const resetWizard = createAction( 'wizzard/resetWizard' );
