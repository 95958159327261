import { createReducer } from '@reduxjs/toolkit';
import { fromPairs } from 'lodash';

import { AudienceParameters, IncomingScenario } from '../../apollo/graphql-types';
import { AUDIENCE_ID, audiencesIdList } from '../../components/Blade/collapse/const';
import * as actions from './actions';
import { IAudienceComparisonState, IAudienceToCompare } from './typings';

const initialState: IAudienceComparisonState = {
  stats: {
    only:      [],
    exclusive: [],
    overlap:   [],
  },
  isLoading:          false,
  isAborting:         false,
  scenario:           IncomingScenario.Compare,
  appliedScenario:    IncomingScenario.Compare,
  audiencesToCompare: {
    [AUDIENCE_ID.AUDIENCE_A]: { benchmark: true },
    [AUDIENCE_ID.AUDIENCE_B]: { benchmark: false },
  } as IAudienceComparisonState['audiencesToCompare'],
  appliedAudiencesToCompare: {} as IAudienceComparisonState['appliedAudiencesToCompare'],
};

export const audienceComparisonReducer = createReducer<IAudienceComparisonState>(
  initialState,
  ( builder ) =>
	  builder
      .addCase( actions.deleteAudience, ( state, { payload } ) => {
        delete state.audiencesToCompare[payload];
        const filteredAudiences = Object.values( state.audiencesToCompare ).filter( ( value ) => !!value );

        const newAudiencesList = fromPairs(
          audiencesIdList
            .map( ( id, index ) => ( [id, filteredAudiences[index]] ) )
            .filter( ( audience ) => !!audience[1] )
        ) as Record<AUDIENCE_ID, IAudienceToCompare>;

        state.audiencesToCompare = newAudiencesList;
        return state;
      } )
      .addCase( actions.setSingleAudienceById, ( state, { payload } ) => {
        const singleAudience = state.audiencesToCompare[payload];

        state.audiencesToCompare = {
          [AUDIENCE_ID.AUDIENCE_A]: {
            ...singleAudience,
            benchmark: false,
          },
        } as IAudienceComparisonState['audiencesToCompare'];

        return state;
      } )
      .addCase( actions.duplicateAudienceById, ( state, { payload } ) => {
        const audienceToDuplicate = state.audiencesToCompare[payload.audienceToDuplicate];
        const newAudienceId = payload.newAudienceId;

        if ( !!audienceToDuplicate.audienceParameters ) {
          state.audiencesToCompare = {
            ...state.audiencesToCompare,
            [newAudienceId]: {
              benchmark:          false,
              audienceParameters: audienceToDuplicate.audienceParameters,
            },
          };
        } else {
          state.audiencesToCompare = {
            ...state.audiencesToCompare,
            [newAudienceId]: {
              benchmark:           false,
              preBuiltAudienceIds: audienceToDuplicate.preBuiltAudienceIds,
            },
          };
        }

        return state;
      } )
      .addCase( actions.setPreBuiltAudienceIds, ( state, { payload } ) => {
        const [audienceId, preBuildIds] = payload;

        if ( !state.audiencesToCompare[audienceId] ) {
          state.audiencesToCompare[audienceId] = { benchmark: false };
        }

        state.audiencesToCompare[audienceId].preBuiltAudienceIds = preBuildIds;

        state.audiencesToCompare[audienceId].benchmark = audienceId === AUDIENCE_ID.AUDIENCE_A
          ? state.scenario !== IncomingScenario.Compare ? false : true
          : false;

        if ( state.audiencesToCompare[audienceId].audienceParameters !== undefined ) {
          delete state.audiencesToCompare[audienceId].audienceParameters;
        }
      } )
      .addCase( actions.setAudienceParameters, ( state, { payload } ) => {
        const [audienceId, parameters] = payload;

        if ( !state.audiencesToCompare[audienceId] ) {
          state.audiencesToCompare[audienceId] = { benchmark: false, audienceParameters: {} as AudienceParameters };
        }

        if ( parameters === undefined ) {
          state.audiencesToCompare[audienceId].audienceParameters = undefined;
        } else {
          state.audiencesToCompare[audienceId].audienceParameters
					= { ...state.audiencesToCompare[audienceId].audienceParameters, ...parameters } as AudienceParameters;
        }

        state.audiencesToCompare[audienceId].benchmark = audienceId === AUDIENCE_ID.AUDIENCE_A
          ? state.scenario !== IncomingScenario.Compare ? false : true
          : false;

        if ( state.audiencesToCompare[audienceId].preBuiltAudienceIds !== undefined ) {
          delete state.audiencesToCompare[audienceId].preBuiltAudienceIds;
        }
      } )
      .addCase( actions.setAudienceComparisonStats, ( state, { payload } ) => {
        state.stats = payload;
      } )
      .addCase( actions.setAudienceComparisonIsLoading, ( state, { payload } ) => {
        state.isLoading = payload;
      } )
      .addCase( actions.setAudienceComparisonIsAborting, ( state, { payload } ) => {
        state.isAborting = payload;
      } )
      .addCase( actions.resetAudienceComparison, ( state ) => {
        state = { ...initialState, isAborting: state.isAborting };

        return state;
      } )
      .addCase( actions.setAudiencesToCompare, ( state, { payload } ) => {
        state.audiencesToCompare = payload;
      } )
      .addCase( actions.setAppliedAudiencesToCompare, ( state, { payload } ) => {
        state.appliedAudiencesToCompare = payload;
      } )
      .addCase( actions.setScenario, ( state, { payload } ) => {
        state.scenario = payload;

        state.audiencesToCompare.audienceA.benchmark = state.scenario !== IncomingScenario.Compare ? false : true;

        return state;
      } )
      .addCase( actions.setAppliedScenario, ( state, { payload } ) => {
        state.appliedScenario = payload;

        return state;
      } )
);
