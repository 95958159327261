import { configureStore } from '@reduxjs/toolkit';

import { audienceComparisonReducer } from './audienceComparison/audienceComparisonSlice';
import { tabsReducer } from './tab/tabSlice';
import { wizardReducer } from './wizzard/wizardSlice';

export const store = configureStore( {
  reducer: {
    wizard:             wizardReducer,
    audienceComparison: audienceComparisonReducer,
    tabs:               tabsReducer,
  },
} );

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
