import { createReducer } from '@reduxjs/toolkit';
import { fromPairs, omit } from 'lodash';

import { AUDIENCE_ID, audiencesIdList } from '../../components/Blade/collapse/const';
import * as actions from './actions';

export interface ICommonWizardInterface {
  isFinished: boolean;
  isExpand: boolean;
  isVisible: boolean;
  isLoading: boolean;
}

export interface ISourceWizardInterface extends ICommonWizardInterface {
  value: string;
}

export interface IMarsBrandsWizardInterface extends ICommonWizardInterface {
}

export interface ITabState {
  source: ISourceWizardInterface;
  prebuiltAudience: ICommonWizardInterface;
  marsBrands: IMarsBrandsWizardInterface;
  origin: ICommonWizardInterface;
  additionalParameters: ICommonWizardInterface;
  competitors: ICommonWizardInterface;
}

export interface IWizardState {
  isOpen: boolean;
  wizards: Record<AUDIENCE_ID, ITabState>;
  sizesNames: Record<string, string>;
}

const commonWizardState = {
  isFinished: false,
  isExpand:   false,
  isVisible:  false,
  isLoading:  false,
};

const defaultSourceState: ISourceWizardInterface = {
  ...commonWizardState,
  isExpand: true,
  value:    '',
};
const defaultPrebuiltAudienceState: ICommonWizardInterface = {
  ...commonWizardState,
};
const defaultMarsBrandsState: IMarsBrandsWizardInterface = {
  ...commonWizardState,
};

const initialTabState: ITabState = {
  source:               defaultSourceState,
  prebuiltAudience:     defaultPrebuiltAudienceState,
  marsBrands:           defaultMarsBrandsState,
  competitors:          commonWizardState,
  origin:               commonWizardState,
  additionalParameters: commonWizardState,
};

const wizzardsInitialState = {
  [AUDIENCE_ID.AUDIENCE_A]: initialTabState,
  [AUDIENCE_ID.AUDIENCE_B]: initialTabState,
  [AUDIENCE_ID.AUDIENCE_C]: initialTabState,
  [AUDIENCE_ID.AUDIENCE_D]: initialTabState,
  [AUDIENCE_ID.AUDIENCE_E]: initialTabState,
  [AUDIENCE_ID.AUDIENCE_F]: initialTabState,
};

const initialState: IWizardState = {
  isOpen:     false,
  sizesNames: {},
  wizards:    wizzardsInitialState,
};

export const wizardReducer = createReducer<IWizardState>(
  initialState,
  ( builder ) =>
	  builder
      .addCase( actions.setSingleWizzardById, ( state, { payload } ) => {
        state.wizards = {
          [AUDIENCE_ID.AUDIENCE_A]: state.wizards[payload],
        } as IWizardState['wizards'];

        return state;
      } )
      .addCase( actions.duplicateWizzardById, ( state, { payload } ) => {
        state.wizards = {
          ...state.wizards,
          [payload.newAudienceId]: state.wizards[payload.audienceToDuplicate],
        } as IWizardState['wizards'];

        return state;
      } )
      .addCase( actions.setWizzardForExclusiveScenario, ( state, { payload } ) => {
        const audienceA = state.wizards.audienceA;
        const audienceB = state.wizards.audienceB;

        state.wizards = {
          ...wizzardsInitialState,
          [AUDIENCE_ID.AUDIENCE_A]: audienceA,
          [AUDIENCE_ID.AUDIENCE_B]: audienceB,
        } as IWizardState['wizards'];

        return state;
      } )
      .addCase( actions.deleteWizzardById, ( state, { payload } ) => {
        delete state.wizards[payload];
        const filteredWizzards = Object.values( state.wizards ).filter( ( value ) => !!value );

        const newWizzardList = fromPairs(
          audiencesIdList
            .map( ( id, index ) => ( [id, filteredWizzards[index]] ) )
            .filter( ( audience ) => !!audience[1] )
        ) as IWizardState['wizards'];

        state.wizards = newWizzardList;
        return state;
      } )
      .addCase( actions.resetWizzardById, ( state, { payload } ) => {
        state.wizards[payload] = initialState.wizards.audienceA;

        return state;
      } )
      .addCase( actions.setWizardIsOpen, ( state, { payload } ) => {
        state.isOpen = payload;

        return state;
      } )
      .addCase( actions.setSizesNames, ( state, { payload } ) => {
        state.sizesNames = {
          ...state.sizesNames,
          ...payload,
        };

        return state;
      } )
      .addCase( actions.collapseAllWizards, ( state, { payload } ) => {
        state.wizards[payload].source.isExpand = false;
        state.wizards[payload].prebuiltAudience.isExpand = false;
        state.wizards[payload].competitors.isExpand = false;
        state.wizards[payload].marsBrands.isExpand = false;
        state.wizards[payload].origin.isExpand = false;
        state.wizards[payload].additionalParameters.isExpand = false;

        return state;
      } )
      .addCase( actions.resetAllWizards, ( state, { payload } ) => {
        state.wizards[payload].source = initialState.wizards[payload].source;
        state.wizards[payload].source = {
          ...state.wizards[payload].source,
          isExpand: true,
        };
        state.wizards[payload].prebuiltAudience = initialState.wizards[payload].prebuiltAudience;
        state.wizards[payload].competitors = initialState.wizards[payload].competitors;
        state.wizards[payload].marsBrands = initialState.wizards[payload].marsBrands;
        state.wizards[payload].origin = initialState.wizards[payload].origin;
        state.wizards[payload].additionalParameters = initialState.wizards[payload].additionalParameters;

        return state;
      } )
      .addCase( actions.resetSourceWizard, ( state, { payload } ) => {
        state.wizards[payload].source = initialState.wizards[payload].source;

        return state;
      } )
      .addCase( actions.resetPrebuiltAudienceWizard, ( state, { payload } ) => {
        state.wizards[payload].prebuiltAudience = initialState.wizards[payload].prebuiltAudience;

        return state;
      } )
      .addCase( actions.resetMarsBrandsWizard, ( state, { payload } ) => {
        state.wizards[payload].marsBrands = initialState.wizards[payload].marsBrands;

        return state;
      } )
      .addCase( actions.resetOriginWizard, ( state, { payload } ) => {
        state.wizards[payload].origin = initialState.wizards[payload].origin;

        return state;
      } )
      .addCase( actions.resetAdditionalParametersWizard, ( state, { payload } ) => {
        state.wizards[payload].additionalParameters = initialState.wizards[payload].additionalParameters;

        return state;
      } )
      .addCase( actions.resetCompetitorsWizard, ( state, { payload } ) => {
        state.wizards[payload].competitors = initialState.wizards[payload].competitors;

        return state;
      } )
      .addCase( actions.setSourceWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].source = { ...state.wizards[payload.activeTab].source, ...omit( payload, 'activeTab' ) };

        return state;
      } )
      .addCase( actions.setPrebuiltAudienceWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].prebuiltAudience
        = { ...state.wizards[payload.activeTab].prebuiltAudience, ...omit( payload, 'activeTab' ) };

        return state;
      } )
      .addCase( actions.setMarsBrandsWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].marsBrands = { ...state.wizards[payload.activeTab].marsBrands, ...omit( payload, 'activeTab' ) };

        return state;
      } )
      .addCase( actions.setOriginWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].origin = { ...state.wizards[payload.activeTab].origin, ...omit( payload, 'activeTab' ) };

        return state;
      } )
      .addCase( actions.setAdditionalParametersWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].additionalParameters = {
          ...state.wizards[payload.activeTab].additionalParameters,
          ...omit( payload, 'activeTab' ),
        };

        return state;
      } )
      .addCase( actions.setCompetitorsWizard, ( state, { payload } ) => {
        state.wizards[payload.activeTab].competitors = { ...state.wizards[payload.activeTab].competitors, ...omit( payload, 'activeTab' ) };


        return state;
      } )
      .addCase( actions.resetWizard, ( state ) => {
        state = {
          ...initialState,
          sizesNames: state.sizesNames,
        };
        return state;
      } )
);
