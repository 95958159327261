import fromPairs from 'lodash/fromPairs';

import { Category } from '../apollo/graphql-types';

export const collectManufacturerNames = ( categorizedBrands: Category[] ) => fromPairs( categorizedBrands
  .map( ( item ) => item.manufacturers )
  .flat()
  .map( ( item ) => [item.manufacturerId, item.manufacturerName] )
);

export const collectBrandsNames = ( categorizedBrands: Category[] ) => fromPairs( categorizedBrands
  .map( ( item ) => item.manufacturers )
  .flat()
  .map( ( item ) => item.brands )
  .flat()
  .map( ( item ) => [item.brandId, item.brandName] )
);

export const getBrandsByCategoryId = ( categoryId: number, categorizedBrands?: Category[] ) => categorizedBrands
  ?.find( ( brands: Category ) => brands.categoryId === categoryId )
  ?.manufacturers[0].brands.filter( ( brand ) => brand.brandName );

export const getManufacturersByCategoryId = ( categoryId: number, categorizedBrands?: Category[] )=>
  categorizedBrands?.find( ( category: Category ) => category.categoryId === categoryId )?.manufacturers;

export const getMarsManufacturerId = ( categorizedBrands?: Category[] ) =>
  categorizedBrands?.[0]?.manufacturers
    .find( ( manufacturer ) => manufacturer.manufacturerName === 'Mars Wrigley' )?.manufacturerId || 18;

export const isMarsManufacturer = ( manufacturer: number[] | undefined ) => manufacturer?.includes( 18 );
