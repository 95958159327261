import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { selectAppliedScenario } from '../../../store/audienceComparison/selectors';
import { SCENARIO_TYPE_TO_NAME } from '../../Blade/collapse/const';
import { TextPopover } from '../../TextPopover/TextPopover';
import { AudienceNamesContext } from '../FilledAudienceComparison';

export const HeaderCaption = () => {
  const audienceNames = useContext( AudienceNamesContext );
  const scenario = useSelector( selectAppliedScenario );

  const namesToShow = Object.values( audienceNames ).map( ( name, index ) => (
    <div key={name + index}>
      {index !== 0 && <span className='vs'>{[SCENARIO_TYPE_TO_NAME[scenario ]]}</span>}
      <TextPopover text={name} />
    </div>
  ) );

  return (
    <>
      <div className='caption'>
        {namesToShow}
      </div>
    </>
  );
};
