import './BarChartDiagram.scss';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import cn from 'classnames';
import { flatten, sortBy } from 'lodash';
import { memo, useContext } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector } from 'react-redux';

import { AttributeValue, IncomingScenario } from '../../../../apollo/graphql-types';
import {
  selectAppliedScenario,
  selectAudienceComparisonBenchmarkId,
  selectAudienceComparisonStats,
} from '../../../../store/audienceComparison/selectors';
import { TextPopover } from '../../../TextPopover/TextPopover';
import { ShowSwitcherContext } from '../AttributesComparison';
import { generateBenchmarkData, optionsBenchmark } from './barChartConfig';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  ChartDataLabels,
  Tooltip
);

interface IBarChartDiagramProps {
  attributes: AttributeValue[];
}

export const BarChartDiagram = memo( function ( { attributes }: IBarChartDiagramProps ) {
  const stats = useSelector( selectAudienceComparisonStats );
  const scenario = useSelector( selectAppliedScenario );

  const segments = sortBy(
    flatten( [...stats.exclusive, ...stats.only, ...stats.overlap] ),
    scenario === IncomingScenario.Compare ? 'position' : ( segment ) => segment.benchmark ? -1 : 1
  );

  const benchmarkSegmentId = useSelector( selectAudienceComparisonBenchmarkId );
  const showSwitcher = useContext( ShowSwitcherContext );

  const data = generateBenchmarkData(
    attributes,
    showSwitcher.showNationalReference,
    benchmarkSegmentId,
    segments
  );
  const options = optionsBenchmark( showSwitcher.showIndexes );

  return (
    <div className={cn( 'bar-chart-diagram', {
      'with-index': showSwitcher.showIndexes,
    } )}>
      <ul className='diagram-labels'>
        {
          data.labels?.map( ( label ) =>
            <li
              key={label as 'string'}
              className='label'
              style={{ '--label-height': `${segments.length * 14}px` } as React.CSSProperties}
            >
              <div className='label-wrapper'>
                <TextPopover maxHeight={36} maxWidth={600} text={label as 'string'} />
              </div>
            </li>
          )
        }
      </ul>

      <div
        className='bar-diagram'
        style={{ height: ( data.labels?.length || 1 ) * ( segments.length * 14 + 18 ) }
          // 14 - it's a height of bar (11.5) + gap between bars (2.5) -> 11.5 + 2.5 = 14
          // 18 - gaps around one bar chart category, 9 + 9 = 18
        }
      >
        {
          showSwitcher.showIndexes && <span className='index-label'>Index</span>
        }
        <Bar options={options} data={data} />
      </div>
    </div>
  );
} );
