import './Summary.scss';

import { Space } from 'antd';
import { isEmpty } from 'lodash';
import { useSelector } from 'react-redux';

import {
  selectAppliedAudiencesToCompare,
  selectAppliedScenario,
  selectAudiencesToCompare,
  selectScenario,
} from '../../../store/audienceComparison/selectors';
import { selectTabs } from '../../../store/tab/selectors';
import { Tags } from '../../Tags/Tags';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AUDIENCE_ID } from '../collapse/const';

interface ISummaryProps {
  audienceToShow?: AUDIENCE_ID;
  finishedOnly?: boolean;
}

const Summary = ( { audienceToShow, finishedOnly = true }: ISummaryProps ) => {
  const tabs = useSelector( selectTabs );
  const scenario = useSelector( selectScenario );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );

  const audiences = Object.entries( audiencesToCompare )
    .filter( ( audience ) => !!audienceToShow ? audienceToShow === audience[0] : true )
    .map( ( [key, audience] ) => tabs[key as AUDIENCE_ID]?.isFinished || !!audienceToShow || !finishedOnly
      ? audience
      : key as AUDIENCE_ID
    );

  return (
    <div className='summary-wizard'>
      <TitleWithCaption heading='Summary' />
      <Space className='collapse-tag__wrapper summary-tags' wrap style={{ maxWidth: 640, display: 'flex', gap: 0 }}>
        <Tags audiences={audiences} visible={true} selectedScenario={scenario} />
      </Space>
    </div>
  );
};

export default Summary;
