import { createAction } from '@reduxjs/toolkit';

import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { ITabItem } from './tabSlice';

export const setActiveTab = createAction<AUDIENCE_ID>( 'tabs/setActiveTab' );
export const setSpecificTab = createAction<Partial<ITabItem & {activeTab: AUDIENCE_ID; reset?: boolean}>>( 'tabs/setSpecificTab' );
export const setExclusiveTabs = createAction( 'tabs/setExclusiveTabs' );
export const resetTabs = createAction( 'tabs/resetTabs' );
export const deleteTab = createAction<AUDIENCE_ID>( 'tabs/deleteTab' );
