import './Blade.scss';

import { Button, Drawer } from 'antd';
import { fromPairs, isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import { IncomingScenario } from '../../../apollo/graphql-types';
import {
  resetAudienceComparison,
  setAppliedAudiencesToCompare,
  setAppliedScenario,
  setAudienceComparisonIsLoading,
  setAudiencesToCompare,
  setScenario,
} from '../../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAppliedScenario,
  selectAudiencesToCompare,
  selectFinishedTabsIsEqualComparisons,
  selectScenario,
} from '../../../store/audienceComparison/selectors';
import { IAudienceToCompare } from '../../../store/audienceComparison/typings';
import { resetTabs } from '../../../store/tab/actions';
import { selectTabs } from '../../../store/tab/selectors';
import { Entries } from '../../../store/typings';
import { resetWizard, setWizardIsOpen } from '../../../store/wizzard/actions';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AUDIENCE_ID, audiencesIdList } from '../collapse/const';
import Summary from '../Summary/Summary';
import { IBladeProps } from '../types';
import WizardTabs from '../WizardTabs/WizardTabs';
import { ScenarioSwitcher } from './ScenarioSwitcher';

const Blade: React.FC<IBladeProps> = ( { open } ) => {
  const dispatch = useDispatch();
  const tabs = useSelector( selectTabs );
  const selectedScenario = useSelector( selectScenario );
  const isEqualComparisons = useSelector( selectFinishedTabsIsEqualComparisons );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const scenario = useSelector( selectScenario );
  const appliedScenario = useSelector( selectAppliedScenario );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const filteredAudiencesToCompare = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> )
    .filter( ( [key, audience] ) => {
      if ( ( tabs[AUDIENCE_ID.AUDIENCE_A].isFinished ? tabs[key].isFinished : true )
    && ( !isEmpty( audience?.audienceParameters ) || !isEmpty( audience?.preBuiltAudienceIds ) ) ) {
        return true;
      }

      return false;
    } ).map( ( [key, audience] ) => audience );

  const filledAudiences = fromPairs(
    audiencesIdList.map( ( id, index ) => ( [id, filteredAudiencesToCompare[index]] ) ).filter( ( audience ) => !!audience[1] )
  ) as Record<AUDIENCE_ID, IAudienceToCompare>;


  const checkIsScenarioFinished = () => {
    if ( selectedScenario === IncomingScenario.Profile ) {
      return tabs[AUDIENCE_ID.AUDIENCE_A]?.isFinished;
    }

    if ( selectedScenario === IncomingScenario.Overlap ) {
      return Object.values( tabs ).filter( ( tab ) => tab.isFinished ).length > 1;
    }

    return tabs[AUDIENCE_ID.AUDIENCE_A]?.isFinished
    && Object.values( tabs ).filter( ( tab ) => tab.isFinished ).length > 1;
  };

  const isApplyEnabled = !isEqualComparisons && checkIsScenarioFinished();

  const handleApply = () => {
    dispatch( setAppliedAudiencesToCompare( filledAudiences ) );
    dispatch( setAppliedScenario( scenario ) );
    dispatch( setAudiencesToCompare( filledAudiences ) );

    dispatch( setAudienceComparisonIsLoading( true ) );
    dispatch( setWizardIsOpen( false ) );
  };

  const handleClose = () => {
    dispatch( setWizardIsOpen( false ) );
    dispatch( resetTabs() );
    dispatch( resetWizard() );
    if ( !isEmpty( appliedAudienceToCompare ) ) {
      dispatch( setAudiencesToCompare( appliedAudienceToCompare ) );
      dispatch( setScenario( appliedScenario ) );
    } else {
      dispatch( resetAudienceComparison() );
    }
  };

  return (
    <div>
      <Drawer
        rootClassName='blade'
        destroyOnClose
        maskClosable={false}
        title={<TitleWithCaption
          headingLevel={4}
          heading='Audience Parameters'
          headingStyles={{ fontFamily: 'WorkSans-SemiBold' }}
        />}
        placement='right'
        size='large'
        onClose={handleClose}
        open={open}
        extra={
          <>
            <ScenarioSwitcher />
            <Button type='primary' disabled={!isApplyEnabled} onClick={handleApply}>
              {selectedScenario === IncomingScenario.Compare ? 'Compare' : 'Profile'}
            </Button>
          </>
        }
      >
        <div className='steps-content'>
          <Summary />
          <WizardTabs />
        </div>
      </Drawer>
    </div>
  );
};

export default Blade;
