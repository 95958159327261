import { CopyOutlined, FileAddOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, MenuProps } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import { duplicateAudienceById, setAudienceParameters } from '../../../store/audienceComparison/actions';
import { selectAudiencesToCompare, selectNewAudienceId, selectScenario } from '../../../store/audienceComparison/selectors';
import { setActiveTab, setSpecificTab } from '../../../store/tab/actions';
import { selectTabs } from '../../../store/tab/selectors';
import { duplicateWizzardById, resetWizzardById } from '../../../store/wizzard/actions';
import { AUDIENCE_ID } from '../collapse/const';
import { generateTabName } from './utils';

export const AddAudience = () => {
  const dispatch = useDispatch();
  const tabs = useSelector( selectTabs );
  const finishedTabs = Object.entries( tabs ).filter( ( [key, tab] ) => tab.isFinished );
  const selectedScenario = useSelector( selectScenario );
  const newAudienceId = useSelector( selectNewAudienceId );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );

  const handleDuplicate = ( audienceId: AUDIENCE_ID ) => {
    dispatch( duplicateAudienceById( { audienceToDuplicate: audienceId, newAudienceId } ) );
    dispatch( duplicateWizzardById( { audienceToDuplicate: audienceId, newAudienceId } ) );
    dispatch( setSpecificTab( { activeTab: newAudienceId, isFinished: true } ) );
    dispatch( setActiveTab( newAudienceId ) );
  };

  const duplicateChildren = finishedTabs.map( ( [key, tab], index ) => ( {
    key:   `duplicate-${index}`,
    label: <a onClick={() => {
      handleDuplicate( key as AUDIENCE_ID );
    }}>{generateTabName( selectedScenario, key as AUDIENCE_ID )}</a>,
    icon:  <CopyOutlined />,
  } ) );

  const itemsMenu: MenuProps['items'] = [
    {
      label: <a onClick={() => {
        dispatch( setAudienceParameters( [newAudienceId, undefined] ) );
        dispatch( resetWizzardById( newAudienceId ) );
        dispatch( setSpecificTab( { activeTab: newAudienceId } ) );
        dispatch( setActiveTab( newAudienceId ) );
      }}>Create New Audience</a>,
      icon: <FileAddOutlined />,
      key:   'create-new',
    },
    {
      label:    <a onClick={( e ) => {
        if ( finishedTabs.length === 1 ) {
          handleDuplicate( finishedTabs[0]?.[0] as AUDIENCE_ID );
        } else {
          e.preventDefault();
        }
      }}>Duplicate {finishedTabs.length === 1
          ? generateTabName( selectedScenario, finishedTabs[0]?.[0] as AUDIENCE_ID )
          : ''}</a>,
      icon:     finishedTabs.length === 1 ? <CopyOutlined /> : null,
      disabled: !finishedTabs.length,
      key:      'duplicate',
      children: finishedTabs.length > 1 ? duplicateChildren : undefined,
    },
  ];

  return <Dropdown menu={{ items: itemsMenu }} trigger={['click']} rootClassName='custom-styled-dropdown' autoAdjustOverflow={true}>
    <a onClick={( e ) => e.preventDefault()}>
      <PlusOutlined /> {Object.keys( audiencesToCompare ).length > 2 ? '' : ' Add Audience'}
    </a>
  </Dropdown>;
};
