import './SaveTemplate.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Form, Input, message, Modal } from 'antd';
import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  CheckAudienceTemplateUniqueQueryVariables,
  ListAudienceTemplatesDocument,
  TemplateCreationMutationVariables,
  useCheckAudienceTemplateUniqueLazyQuery,
  useRenameAudienceTemplateMutation,
  useTemplateCreationMutation,
} from '../../../../apollo/graphql-types';
import { selectAudiencesToCompare, selectScenario } from '../../../../store/audienceComparison/selectors';
import { options } from '../../../Blade/collapse/const';
import { AudienceNamesContext } from '../../FilledAudienceComparison';

export const SaveTemplate = () => {
  const audienceNamesFilled = useContext( AudienceNamesContext );
  const audienceNames = Object.values( audienceNamesFilled || '' ).join( ' VS ' );
  const saveTemplateError = <>Template Save Failed: Please retry or contact
    <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
    for assistance
  </>;

  const [open, setOpen] = useState( false );
  const [modalType, setModalType] = useState<'create' | 'rename'>( 'rename' );
  const [inputValue, setInputValue] = useState( 'Generated value' );
  const [form] = Form.useForm();
  const scenario = useSelector( selectScenario );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const filledAudiences = Object.values( audiencesToCompare )
    .filter( ( audience ) => !!audience?.audienceParameters || !!audience?.preBuiltAudienceIds );
  const [messageApi, contextHolder] = message.useMessage();
  const [isOkDisabled, setIsOkDisabled] = useState( false );

  const errorMessage = ( content: React.ReactNode = 'Something went wrong. Please retry' ) => {
    messageApi.open( {
      type:    'error',
      content,
    } );
  };

  const successMessage = ( content: React.ReactNode ) => {
    messageApi.open( {
      type: 'success',
      content,
    } );
  };

  const [checkTemplateUnique, { data: checkTemplateData, loading: checkTemplateUniqueLoading }] = useCheckAudienceTemplateUniqueLazyQuery( {
    ...options(),
    fetchPolicy: 'network-only',
  } );

  const [createTemplate, { loading: createTemplateLoading }] = useTemplateCreationMutation( {
    ...options(),
    update: ( cache, { data } ) => {
      cache.modify( {
        fields: {
          listAudienceTemplates ( currentTemplates ) {
            return [
              data?.templateCreation,
              ...currentTemplates,
            ];
          },
        },
      } );
    },
  },
  );
  const [renameTemplate, { loading: renameTemplateLoading }] = useRenameAudienceTemplateMutation( {
    ...options(),
    refetchQueries: [{ query: ListAudienceTemplatesDocument }],
  } );

  const handleClick = () => {
    const click = async () => {
      try {
        const variables: CheckAudienceTemplateUniqueQueryVariables = {
          audienceComparisonTemplate: {
            name:      '',
            scenario: {
              scenario,
              audiences: filledAudiences,
            },
          },
        };

        const { data, error } = await checkTemplateUnique( { variables } );
        if ( error ) return errorMessage( saveTemplateError );

        if ( !data?.checkAudienceTemplateUnique.id && !data?.checkAudienceTemplateUnique.name ) {
          setModalType( 'create' );

          setInputValue( audienceNames.length > 150 ? audienceNames.slice( 0, 147 ).concat( '...' ) : audienceNames );
        } else {
          setModalType( 'rename' );
          setInputValue( data?.checkAudienceTemplateUnique.name || '' );
        }

        setOpen( true );
      } catch ( error ) {
        errorMessage();
      }
    };

    click();
  };

  const handleOk = () => {
    const handleOkClick = async () => {
      try {
        if ( modalType === 'create' ) {
          const variables: TemplateCreationMutationVariables = {
            audienceComparisonTemplate: {
              name:      inputValue,
              scenario: {
                scenario,
                audiences: filledAudiences,
              },
            },
          };

          const { errors } = await createTemplate( { variables } );

          if ( !errors ) {
            setOpen( false );
            successMessage( 'Template was successfully saved' );
          } else {
            errorMessage( saveTemplateError );
          }
        } else {
          if ( !checkTemplateData?.checkAudienceTemplateUnique.id ) {
            return false;
          }

          const { errors } = await renameTemplate( { variables: {
            audienceTemplateAnnotation: {
              id:   checkTemplateData?.checkAudienceTemplateUnique.id,
              name: inputValue,
            },
          } } );

          if ( !errors ) {
            setOpen( false );
            successMessage( 'Template was successfully renamed' );
          } else {
            errorMessage( 'Rename failed, please try again later.' );
          }
        }
      } catch ( error ) {

        if ( modalType === 'create' ) {
          errorMessage( saveTemplateError );
        } else {
          errorMessage( 'Rename failed, please try again later.' );
        }
      }
    };

    handleOkClick();
  };

  useEffect( () => {
    if ( modalType === 'rename' ) {
      if ( inputValue === checkTemplateData?.checkAudienceTemplateUnique.name || !inputValue ) {
        setIsOkDisabled( true );
      } else {
        setIsOkDisabled( false );
      }
    } else {
      if ( !inputValue ) {
        setIsOkDisabled( true );
      } else {
        setIsOkDisabled( false );
      }
    }
  }, [inputValue, checkTemplateData, modalType] );

  return (
    <div className='save-template'>
      {contextHolder}
      <Button
        type='primary'
        icon={<SaveOutlined />}
        onClick={handleClick}
        loading={checkTemplateUniqueLoading}
      >
                Save as a Template
      </Button>

      <Modal
        destroyOnClose
        centered
        maskClosable={false}
        okText={`${modalType === 'create' ? 'Save to my Templates' : 'Rename Template'}`}
        title={`${modalType === 'create' ? 'Save as a template' : 'This template already exists'}`}
        open={open}
        onOk={handleOk}
        okButtonProps={{
          disabled: isOkDisabled,
          loading:  createTemplateLoading || renameTemplateLoading,
        }}
        onCancel={() => setOpen( false )}
        className='save-template'

      >
        <p className='modal-template-content'>
          {`${modalType === 'create' ? 'Save these parameters as a template.' : 'You can rename this template or keep the current name.'}`}
        </p>
        <Form
          layout='vertical'
          form={form}
        >
          <Form.Item label='Template Name'>
            <Input
              showCount
              maxLength={150}
              placeholder='Template Name'
              value={inputValue}
              onChange={( e ) => setInputValue( e.target.value.trimStart() )}
            />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

