import './SummaryCardList.scss';

import { Button, Popover } from 'antd';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { AudienceSummaryCard, IncomingScenario } from '../../../apollo/graphql-types';
import { selectAppliedScenario } from '../../../store/audienceComparison/selectors';
import { AUDIENCE_ID, NATIONAL_REFERENCE, TAB_HEADERS } from '../../Blade/collapse/const';
import { generateSummaryCardName } from '../../Blade/WizardTabs/utils';
import { TextPopover } from '../../TextPopover/TextPopover';
import { DefaultAttributes } from '../DefaultAttributes/DefaultAttributes';
import { AudienceNamesContext, AudienceRequestContext } from '../FilledAudienceComparison';
import { SummaryCardDetails } from '../SummaryCardsDetails/SummaryCardDetails';
import { SubmitForActivation } from './SubmitForActivation/SubmitForActivation';


interface ISummaryCardProps {
  card: AudienceSummaryCard;
  requestId?: string;
}

export const SummaryCard = ( { card }: ISummaryCardProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const selectedScenario = useSelector( selectAppliedScenario );

  const [open, setOpen] = useState( false );

  const handleCloseDetails =() => {
    setOpen( false );
  };

  const requestData = useContext( AudienceRequestContext );

  return <div className='attribute-summary-item' key={card.audiencePosition}>
    <div className='attribute-summary-section'>
      <span className='title'>{generateSummaryCardName( selectedScenario, card.audiencePosition as AUDIENCE_ID )}</span><br />
      <div className='name-count'>
        {
          [IncomingScenario.Exclusive, IncomingScenario.Overlap].includes( selectedScenario )
          && card.audiencePosition as AUDIENCE_ID !== 'audienceNR' as AUDIENCE_ID
            ? (
              <div className='name'>
                <Popover
                  getPopupContainer={( node ) => node.parentElement!}
                  content={ Object.entries( audienceNames ).map( ( [key, name] ) => (
                    <p>{TAB_HEADERS[key as AUDIENCE_ID]}: <span>{name}</span></p>
                  ) )}
                  placement='bottom'
                  arrow={false}
                  overlayClassName='names-popover'
                >{Object.keys( audienceNames ).length} Audiences:</Popover>
              </div>
            )
            : (
              <TextPopover
                text={`${card.audiencePosition as AUDIENCE_ID === 'audienceNR' as AUDIENCE_ID
                  ? NATIONAL_REFERENCE
                  : audienceNames[card.audiencePosition as AUDIENCE_ID]}`}
                className='name'
              />
            )
        }
        <b>{card.audienceSize.toLocaleString( 'en-US' )}</b>
      </div>
      {!!requestData?.requestId && card.audiencePosition !== 'audienceNR' && <SubmitForActivation
        requestId={requestData.requestId}
        segmentId={card.segmentId}
        position={card.audiencePosition}
      />}
    </div>

    <div className='attribute-summary-section center'>
      <p className='short-description'>{card.shortDescription}</p>
      <DefaultAttributes defaultAttributes={card.defaultAttributes} />
    </div>
    <div className='attribute-summary-section bottom'>
      <Button onClick={() => setOpen( true )}>See Details</Button>
      <SummaryCardDetails
        open={open}
        audienceId={card.audiencePosition as AUDIENCE_ID}
        segmentId={card.segmentId}
        handleClose={handleCloseDetails}
        name={generateSummaryCardName( selectedScenario, card.audiencePosition as AUDIENCE_ID )}
      />
    </div>
  </div>;
};
