import { audienceClient } from '../../../apollo/apollo';
import { Brand, IncomingScenario } from '../../../apollo/graphql-types';

export const MARS_BRANDS = 'Mars Brands';
export const PREBUILT_AUDIENCES = 'Prebuilt Audiences';
export const COMPETITORS = 'Competitors';
export const ALL_BRANDS = 'All Brands';
export const ALL_MANUFACTURERS = 'All Manufacturers';
export const NATIONAL_REFERENCE = 'National Reference';

export const options = ( signal?: AbortSignal ) => ( {
  client:       audienceClient,
  context: {
    fetchOptions: {
      signal,
    },
    credentials: 'include',
  },
} );

export const ALL_BUYER_TYPES = 'All Buyer Types';
export const BUYER_TYPES = 'Buyer Types';
export const BUYER_TYPE_DESCRIPTION = [
  { name: 'Heavy', description: 'Relevant HH Spend in Top 20%*' },
  { name: 'Medium', description: 'Relevant HH Spend in Middle 30%*' },
  { name: 'Light', description: 'Relevant HH Spend in Lowest 50%*' },
  { name: 'Lapsed', description: 'No purchase in the last 6 months' },
  { name: 'Non-Buyer', description: 'HH purchased within the category but not the selected brands' },
];

export const SCENARIO_TYPE_TO_NAME = {
  [IncomingScenario.Compare]:   'Compare',
  [IncomingScenario.Overlap]:   'Overlap',
  [IncomingScenario.Exclusive]: 'Exclusive (Audience 1)',
  [IncomingScenario.Profile]:   'Single Audience',
};

export enum AUDIENCE_ID {
  AUDIENCE_A = 'audienceA',
  AUDIENCE_B = 'audienceB',
  AUDIENCE_C = 'audienceC',
  AUDIENCE_D = 'audienceD',
  AUDIENCE_E = 'audienceE',
  AUDIENCE_F = 'audienceF',
}

export const audiencesIdList = [
  AUDIENCE_ID.AUDIENCE_A,
  AUDIENCE_ID.AUDIENCE_B,
  AUDIENCE_ID.AUDIENCE_C,
  AUDIENCE_ID.AUDIENCE_D,
  AUDIENCE_ID.AUDIENCE_E,
  AUDIENCE_ID.AUDIENCE_F,
];
export const TAB_HEADERS = {
  [AUDIENCE_ID.AUDIENCE_A]:  'Audience 1',
  [AUDIENCE_ID.AUDIENCE_B]:  'Audience 2',
  [AUDIENCE_ID.AUDIENCE_C]:  'Audience 3',
  [AUDIENCE_ID.AUDIENCE_D]:  'Audience 4',
  [AUDIENCE_ID.AUDIENCE_E]:  'Audience 5',
  [AUDIENCE_ID.AUDIENCE_F]:  'Audience 6',
};

export const tooltipContent = ( data: { name: string; description: string }[] ) => (
  <div className='buyer-type-tooltip__d2c'>
    {data.map( ( item ) => (
      <div key={item.name}>
        <span className='buyer-type-name__tooltip'>{item.name}:</span>
        <span className='buyer-type-description__tooltip'>{item.description}</span>
      </div>
    ) )}

    <small>*Consumers from Heavy, Medium, Light categories may overlap with Lapsed category consumers</small>
  </div>
);

export const stepErrorDescription = <>
  Data for the next step couldn\'t be loaded. Please retry or contact
  <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
 if the issue persists.
</>;


// TODO: check if it works correct
export const getSortedBrandList = (
  selectedBrands: number[] | undefined,
  availableBrands: any
) => selectedBrands?.slice().sort( ( a, b ) => {
  const brandA = availableBrands.find( ( brand: Brand ) => brand.brandId === a );
  const brandB = availableBrands.find( ( brand: Brand ) => brand.brandId === b );

  return brandA?.id - brandB?.id;
} );
