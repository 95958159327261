import { flatten } from 'lodash';

import { AudienceParameters, BrandMarketingCampaignsQuery, MarketingCampaign } from '../apollo/graphql-types';

export const generateCampaignsNames = (
  campaignsList: MarketingCampaign[],
  selectedCampaigns: AudienceParameters['marketingCampaigns'],
  campaignsData: BrandMarketingCampaignsQuery | undefined
) =>
  campaignsList.length === selectedCampaigns?.length && selectedCampaigns?.length > 1
    ? 'All Campaigns'
    : flatten(
      campaignsData?.brandMarketingCampaigns.campaigns?.map( ( affinity ) => {
        const selectedCampaingsFromAffinity = affinity.marketingCampaigns
          .filter( ( campaign ) => selectedCampaigns?.includes( campaign.id ) );

        if (
          selectedCampaingsFromAffinity.length === affinity.marketingCampaigns.length && affinity.marketingCampaigns.length > 1
        ) {
          return `All ${affinity.affinityName} Campaigns`;
        } else {
          return selectedCampaingsFromAffinity?.map( ( id ) =>
            campaignsList
              .find( ( campaignId ) => campaignId === id )?.marketingCampaignName ).filter( ( item ) => !!item ).join( ', ' );
        }
      } )
    ).filter( ( campaign ) => !!campaign ).join( ', ' );
