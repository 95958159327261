import { CheckOutlined, InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Popover, Space } from 'antd';
import cn from 'classnames';
import { isEmpty, sortBy } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Brand, useGetBrandsQuery } from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectCategoryByAudienceId,
  selectIsEqualComparisons,
} from '../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../store/tab/actions';
import {
  collapseAllWizards,
  resetAdditionalParametersWizard,
  resetOriginWizard,
  setMarsBrandsWizard,
  setOriginWizard,
  setSourceWizard,
} from '../../../../store/wizzard/actions';
import { selectWizardMarsBrandsByTabName } from '../../../../store/wizzard/selectors';
import {
  collectBrandsNames,
  getBrandsByCategoryId,
  getMarsManufacturerId,
  isMarsManufacturer,
} from '../../../../utils/brand.utils';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import {
  ALL_BRANDS,
  AUDIENCE_ID,
  getSortedBrandList,
  options,
  stepErrorDescription,
} from '../const';
import { ICollapseProps } from '../types';
import { handleScrollIntoView } from '../utils';

interface IBrandCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const BrandCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IBrandCollapseProps ) => {
  const dispatch = useDispatch();
  const { data: marsBrandsData, loading, error, refetch } = useGetBrandsQuery(
    { ...options(), notifyOnNetworkStatusChange: true }
  );

  const marsBrandsWizardSelector = useSelector( selectWizardMarsBrandsByTabName( activeTab ) );
  const marsBrandsWizard = marsBrandsWizardSelector || [];
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );
  const marsManufacturer = getMarsManufacturerId( marsBrandsData?.categorizedBrands );
  const marsBrandsNames = collectBrandsNames( marsBrandsData?.categorizedBrands || [] );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );

  const [showAllMap, setShowAllMap] = useState<{ [key: string]: boolean }>( {} );
  const [brandsForReduction, setBrandsForReduction] = useState<Brand[]>( [] );

  const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData?.categorizedBrands ) || [];
  const scrollRef = useRef<HTMLDivElement | null>( null );

  useEffect( () => {
    if ( !!error?.message ) {
      console.error(`Loading data error: ${error.message}`); //eslint-disable-line
    }
  }, [error] );

  const selectedCategoryName = marsBrandsData?.categorizedBrands
    ?.find( ( category ) => selectedCategory === category.categoryId )?.categoryName || '';
  const ALL_BRANDS_CAPTION = `${selectedCategoryName} ${ALL_BRANDS}`;
  const isMultipleBrands = availableBrands.length > 1;
  const isAllBrandsSelected = availableBrands.length === selectedBrands.length;

  const initialDataForNextSteps = {
    buyerTypes:         undefined,
    origin:             undefined,
    engagementStatus:   undefined,
    marketingCampaigns: undefined,
    d2c:                undefined,
    flavors:            undefined,
    sizes:              undefined,
    toDate:             undefined,
    fromDate:           undefined,
  };

  const goNextStep = () => {
    dispatch(
      setMarsBrandsWizard( {
        isFinished: true,
        isExpand:   false,
        activeTab,
      } ),
    );
  };

  const handleCategoryClick = ( id: number ) => {
    if ( selectedCategory ) {
      setShowAllMap( ( prevState ) => ( {
        ...prevState,
        [selectedCategory]: false,
      } ) );
    }

    if ( selectedCategory !== id ) {
      dispatch( setMarsBrandsWizard( { activeTab, isFinished: false } ) );
      dispatch( setAudienceParameters( [activeTab, {
        category:           id,
        manufacturers:      [marsManufacturer],
        brands:             [],
      },
      ]
      ) );
      dispatch( resetOriginWizard( activeTab ) );
      dispatch( resetAdditionalParametersWizard( activeTab ) );
    }
  };

  const handleClickAllBrands = () => {
    const availableBrandsList = availableBrands.map( ( brand ) => brand.brandId );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, {
      brands:             !isAllBrandsSelected ? sortBy( availableBrandsList, 'brandId' ) : [],
      ...initialDataForNextSteps,
    }] ) );
    dispatch( resetOriginWizard( activeTab ) );
    dispatch( resetAdditionalParametersWizard( activeTab ) );
  };

  const handleBrandButtonClick = ( name: number ) => {
    let selectedBrandsList: number[];
    if ( selectedBrands.includes( name ) ) {
      selectedBrandsList = selectedBrands.filter( ( item ) => item !== name );
    } else {
      selectedBrandsList = sortBy( [...selectedBrands, name], 'brandId' );
    }
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, {
      brands:             sortBy( selectedBrandsList, 'brandId' ),
      ...initialDataForNextSteps,
    }] ) );
    dispatch( resetOriginWizard( activeTab ) );
    dispatch( resetAdditionalParametersWizard( activeTab ) );
  };

  const handleBrandNextClick = () => {
    goNextStep();
    dispatch( setSourceWizard( { activeTab, isExpand: false } ) );
    dispatch( setOriginWizard( { isExpand: true, isVisible: true, activeTab } ) );
  };

  const handleFinishWizardClick = () => {
    goNextStep();
    dispatch( collapseAllWizards( activeTab ) );
    dispatch( resetOriginWizard( activeTab ) );
    dispatch( resetAdditionalParametersWizard( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
    dispatch( setAudienceParameters( [activeTab, initialDataForNextSteps] ) );
  };

  const handleShowAllClick = () => {
    if ( selectedCategory ) {
      setShowAllMap( ( prevState ) => ( {
        ...prevState,
        [selectedCategory]: true,
      } ) );
    }
  };

  useEffect( () => {
    if ( !loading || !!selectedBrands || !!selectedCategory ) {
      handleScrollIntoView( scrollRef );
    }
  }, [loading, selectedBrands, selectedCategory] );

  useEffect( () => {

    const shouldShowAll = showAllMap[selectedCategory || 0] || false;
    setBrandsForReduction(
      availableBrands.slice( 0, shouldShowAll ? availableBrands.length : 10 )
    );
  }, [selectedCategory, showAllMap] );

  useEffect( () => {
    if ( marsBrandsWizard.isLoading === false && ( marsBrandsData?.categorizedBrands?.length || 0 ) > 0 && !selectedCategory ) {
      dispatch( setAudienceParameters( [activeTab, {
        category: marsBrandsData?.categorizedBrands[0]?.categoryId, manufacturers: [marsManufacturer],
      }] ) );
    }
  }, [marsBrandsWizard.isLoading, marsBrandsData?.categorizedBrands] );

  useEffect( () => {
    if ( !isEmpty( appliedAudienceToCompare ) && isEqualComparisons ) {
      const audience = audiencesToCompare[activeTab];
      const isMarsBrandsSource = isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isMarsBrandsSource ) {
        dispatch(
          setMarsBrandsWizard( {
            isFinished: true,
            activeTab,
          } )
        );

        if ( !!audience.audienceParameters?.origin ) {
          dispatch( setOriginWizard( { activeTab, isVisible: true } ) );
        } else {
          dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
        }
      }
    }
  }, [] );

  const collapseitems = [
    {
      key:   WIZARD_NAME.MARS_BRANDS,
      label: <CollapseHeader
        step={WIZARD_NAME.MARS_BRANDS}
        heading='Step 02: Brand'
        caption='Select a brand to proceed with'
        tags={[
          {
            name: TAG_VALUES.BRAND,
            value:
              isAllBrandsSelected && isMultipleBrands
                ? [ALL_BRANDS_CAPTION]
                : getSortedBrandList( selectedBrands, availableBrands )?.map( ( brand ) => marsBrandsNames[brand] ) || [],
          },
        ]}
      />,
      children: <>
        <Space className='blade-buttons' wrap>
          {marsBrandsData?.categorizedBrands.map( ( brand ) => (
            <Button
              key={brand.categoryName}
              type={selectedCategory === brand.categoryId ? 'primary' : 'default'}
              className={cn( 'category', { selected: brand.categoryId === selectedCategory } )}
              onClick={() => handleCategoryClick( brand.categoryId )}
            >
              {brand.categoryName}
            </Button>
          ) )}
        </Space>
        {!!selectedCategory && (
          <>
            <div style={{ paddingTop: 16 }}>
              <TitleWithCaption caption='Select Brand' captionFontSize={16} />
            </div>
            {isMultipleBrands && (
              <Space className='blade-buttons' style={{ display: 'block' }}>
                <Button
                  key={ALL_BRANDS}
                  type={isAllBrandsSelected ? 'primary' : 'default'}
                  style={{ marginTop: 16, width: '100%' }}
                  onClick={handleClickAllBrands}
                >
                  {ALL_BRANDS}
                </Button>
              </Space>
            )}
            <Space className='blade-buttons' wrap style={{ paddingTop: 16 }}>
              {brandsForReduction?.map( ( brand ) => {
                const { brandId, brandName } = brand;
                return (
                  <Button
                    key={brandId}
                    className={cn( { selected: selectedBrands.includes( brandId ) } )}
                    icon={selectedBrands.includes( brandId ) ? <CheckOutlined /> : ''}
                    onClick={() => handleBrandButtonClick( brandId )}
                  >
                    {brandName}
                  </Button>
                );
              } )}
              {!showAllMap[selectedCategory]
              && brandsForReduction.length >= 10
              && availableBrands.length !== 10 && (
                <div className='link-button'>
                  <Button type='link' onClick={handleShowAllClick}>
                    Show All
                  </Button>
                </div>
              )}
            </Space>
            <div ref={scrollRef}>
              {selectedBrands.length > 0 && (
                <>
                  <Divider style={{ margin: '1rem 0 1rem' }} />
                  <Space className='next-button'>
                    <Button onClick={handleBrandNextClick}>Next - Origin</Button>
                    <Button onClick={handleFinishWizardClick}>
                      Finish Wizard
                      <Popover
                        content='The audience will be created based on campaigns, websites, and 3PD offline brand buyers'
                        trigger='hover'
                        placement='bottom'
                      >
                        <InfoCircleOutlined className='info-circle' />
                      </Popover>
                    </Button>
                  </Space>
                </>
              )}
            </div>
          </>
        )}
      </>,
    },
  ];

  return error?.message
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          refetch();
        }}
      />
    )
    : loading
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          items={collapseitems}
          defaultActiveKey={WIZARD_NAME.MARS_BRANDS}
          activeKey={marsBrandsWizard.isExpand ? WIZARD_NAME.MARS_BRANDS : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setMarsBrandsWizard, marsBrandsWizard )}
          expandIcon={() =>
            marsBrandsWizard.isExpand
              ? (
                <MinusOutlined style={expandIconStyles} />
              )
              : (
                <PlusOutlined style={expandIconStyles} />
              )
          }
        />
      );
};

export default BrandCollapse;
