import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  datetime: { input: any; output: any; }
};

export type ActivationAttribute = {
  attributeName: Scalars['String']['input'];
  attributeValues: Array<Scalars['String']['input']>;
};

export type Attribute = {
  __typename?: 'Attribute';
  attributeName: Scalars['String']['output'];
  attributeValues: Array<AttributeValue>;
  favorite: Scalars['Boolean']['output'];
  identifierName: Scalars['String']['output'];
  lowCoverage?: Maybe<Array<AttributeCoverageIndex>>;
};

export type AttributeCoverageIndex = {
  __typename?: 'AttributeCoverageIndex';
  coverageValue: Scalars['Float']['output'];
  segmentId: Scalars['String']['output'];
};

export type AttributeGroup = {
  __typename?: 'AttributeGroup';
  attributes: Array<Attribute>;
  groupName: Scalars['String']['output'];
};

export type AttributeValue = {
  __typename?: 'AttributeValue';
  attributeValueName: Scalars['String']['output'];
  segmentValues: Array<SegmentValue>;
};

export type AttributesCalcInitResult = {
  __typename?: 'AttributesCalcInitResult';
  exclusive: Array<SegmentCalculationAnnotation>;
  only: Array<SegmentCalculationAnnotation>;
  overlap: Array<SegmentCalculationAnnotation>;
  requestId: Scalars['String']['output'];
};

export type AudienceActivation = {
  excludeAttributes?: InputMaybe<Array<ActivationAttribute>>;
  limitToAttributes?: InputMaybe<Array<ActivationAttribute>>;
  requestId: Scalars['String']['input'];
  scenario: IncomingScenario;
  segmentId: Scalars['String']['input'];
  submittedAudiences: Scalars['String']['input'];
};

export type AudienceComparisonParameters = {
  __typename?: 'AudienceComparisonParameters';
  brands: Array<Scalars['Int']['output']>;
  buyerTypes?: Maybe<Array<BuyerType>>;
  category: Scalars['Int']['output'];
  d2c?: Maybe<Scalars['Boolean']['output']>;
  engagementStatus?: Maybe<EngagementStatus>;
  flavors?: Maybe<Array<Scalars['String']['output']>>;
  fromDate?: Maybe<Scalars['datetime']['output']>;
  manufacturers: Array<Scalars['Int']['output']>;
  marketingCampaigns?: Maybe<Array<Scalars['Int']['output']>>;
  origin?: Maybe<Origin>;
  sizes?: Maybe<Array<Scalars['String']['output']>>;
  toDate?: Maybe<Scalars['datetime']['output']>;
};

export type AudienceComparisonTemplate = {
  __typename?: 'AudienceComparisonTemplate';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
  scenario: AudienceComparisonTemplateScenario;
  userId: Scalars['Int']['output'];
};

export type AudienceComparisonTemplateScenario = {
  __typename?: 'AudienceComparisonTemplateScenario';
  audiences: Array<AudienceToCompare>;
  scenario: Scenario;
};

export type AudienceDistribution = {
  __typename?: 'AudienceDistribution';
  exclusive: Array<AudienceSegment>;
  only: Array<AudienceSegment>;
  overlap: Array<AudienceSegment>;
};

export type AudienceMutation = {
  __typename?: 'AudienceMutation';
  addFavoriteAttributes: Array<Scalars['String']['output']>;
  deleteAudienceTemplate: AudienceComparisonTemplate;
  deleteFavoriteAttributes?: Maybe<Array<Scalars['String']['output']>>;
  initiateAttributesCalculations: AttributesCalcInitResult;
  initiateAudienceDistributionCalc: ComparisonInitiationResult;
  renameAudienceTemplate: AudienceTemplateAnnotation;
  submitAudienceForActivation: EmailSendResult;
  templateCreation: AudienceComparisonTemplate;
};


export type AudienceMutationAddFavoriteAttributesArgs = {
  attributes: Array<Scalars['String']['input']>;
};


export type AudienceMutationDeleteAudienceTemplateArgs = {
  templateId: Scalars['Int']['input'];
};


export type AudienceMutationDeleteFavoriteAttributesArgs = {
  attributes: Array<Scalars['String']['input']>;
};


export type AudienceMutationInitiateAttributesCalculationsArgs = {
  totalsPrerequisites: TotalsPrerequisites;
};


export type AudienceMutationInitiateAudienceDistributionCalcArgs = {
  comparisonScenario: ComparisonScenario;
};


export type AudienceMutationRenameAudienceTemplateArgs = {
  audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation;
};


export type AudienceMutationSubmitAudienceForActivationArgs = {
  audienceActivation: AudienceActivation;
};


export type AudienceMutationTemplateCreationArgs = {
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
};

export type AudienceParameters = {
  brands: Array<Scalars['Int']['input']>;
  buyerTypes?: InputMaybe<Array<BuyerType>>;
  category: Scalars['Int']['input'];
  d2c?: InputMaybe<Scalars['Boolean']['input']>;
  engagementStatus?: InputMaybe<EngagementStatus>;
  flavors?: InputMaybe<Array<Scalars['String']['input']>>;
  fromDate?: InputMaybe<Scalars['DateTime']['input']>;
  manufacturers: Array<Scalars['Int']['input']>;
  marketingCampaigns?: InputMaybe<Array<Scalars['Int']['input']>>;
  origin?: InputMaybe<Origin>;
  sizes?: InputMaybe<Array<Scalars['String']['input']>>;
  toDate?: InputMaybe<Scalars['DateTime']['input']>;
};

export type AudienceQuery = {
  __typename?: 'AudienceQuery';
  audienceDistribution: AudienceDistribution;
  barcharts: Array<BarchartTab>;
  brandMarketingCampaigns: D2C;
  categorizedBrands: Array<Category>;
  checkAudienceTemplateUnique: AudienceTemplateAnnotation;
  detailedSummaryCard: DetailedAudienceSummaryCard;
  filteringAttributes: Array<FilterAttributeCategory>;
  flavorsSizes: FlavorSizes;
  getAudienceTemplate: AudienceComparisonTemplate;
  getWizardAudienceSize: Scalars['Int']['output'];
  listAudienceTemplates: Array<AudienceTemplateAnnotation>;
  preBuiltAudienceNames: Array<PreBuiltAudience>;
  summaryCards: Array<AudienceSummaryCard>;
};


export type AudienceQueryAudienceDistributionArgs = {
  totalsPrerequisites: TotalsPrerequisites;
};


export type AudienceQueryBarchartsArgs = {
  barchartPrerequisites: BarchartPrerequisites;
};


export type AudienceQueryBrandMarketingCampaignsArgs = {
  brands: Array<IncomingBrand>;
};


export type AudienceQueryCategorizedBrandsArgs = {
  isCompetitor?: Scalars['Boolean']['input'];
};


export type AudienceQueryCheckAudienceTemplateUniqueArgs = {
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
};


export type AudienceQueryDetailedSummaryCardArgs = {
  summaryCardPrerequisites: SummaryCardPrerequisites;
};


export type AudienceQueryFlavorsSizesArgs = {
  brandId: Scalars['String']['input'];
};


export type AudienceQueryGetAudienceTemplateArgs = {
  templateId: Scalars['Int']['input'];
};


export type AudienceQueryGetWizardAudienceSizeArgs = {
  audience: IndividualAudience;
};


export type AudienceQuerySummaryCardsArgs = {
  summaryCardsPrerequisites: ShortSummaryCardsPrerequisites;
};

export type AudienceSegment = {
  __typename?: 'AudienceSegment';
  benchmark: Scalars['Boolean']['output'];
  membersNumber: Scalars['Int']['output'];
  position: Scalars['String']['output'];
  segmentId: Scalars['String']['output'];
};

export type AudienceSummaryCard = {
  __typename?: 'AudienceSummaryCard';
  audiencePosition: Scalars['String']['output'];
  audienceSize: Scalars['Int']['output'];
  benchmark: Scalars['Boolean']['output'];
  defaultAttributes: Array<DefaultSummaryCardAttributes>;
  segmentId: Scalars['String']['output'];
  shortDescription?: Maybe<Scalars['String']['output']>;
};

export type AudienceTemplateAnnotation = {
  __typename?: 'AudienceTemplateAnnotation';
  id?: Maybe<Scalars['Int']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type AudienceToCompare = {
  __typename?: 'AudienceToCompare';
  audienceParameters?: Maybe<AudienceComparisonParameters>;
  benchmark: Scalars['Boolean']['output'];
  preBuiltAudienceIds?: Maybe<Array<Scalars['Int']['output']>>;
};

export type BarchartPrerequisites = {
  attributeNames?: InputMaybe<Array<Scalars['String']['input']>>;
  requestId: Scalars['String']['input'];
  scenario: IncomingScenario;
};

export type BarchartTab = {
  __typename?: 'BarchartTab';
  attributes?: Maybe<Array<Attribute>>;
  categoryName: Scalars['String']['output'];
  groups?: Maybe<Array<AttributeGroup>>;
};

export type Brand = {
  __typename?: 'Brand';
  brandId: Scalars['Int']['output'];
  brandName: Scalars['String']['output'];
  firstParty: Scalars['Boolean']['output'];
  thirdParty: Scalars['Boolean']['output'];
};

export enum BuyerType {
  Heavy = 'HEAVY',
  Lapsed = 'LAPSED',
  Light = 'LIGHT',
  Medium = 'MEDIUM',
  NonBuyer = 'NON_BUYER'
}

export type CampaignAffinity = {
  __typename?: 'CampaignAffinity';
  affinityName?: Maybe<Scalars['String']['output']>;
  marketingCampaigns: Array<MarketingCampaign>;
};

export type Category = {
  __typename?: 'Category';
  categoryId: Scalars['Int']['output'];
  categoryName: Scalars['String']['output'];
  manufacturers: Array<Manufacturer>;
};

export type ComparisonInitiationResult = {
  __typename?: 'ComparisonInitiationResult';
  queryId: Scalars['String']['output'];
  requestId: Scalars['String']['output'];
};

export type ComparisonScenario = {
  audiences: Array<IncomingAudience>;
  scenario: IncomingScenario;
};

export type D2C = {
  __typename?: 'D2C';
  campaigns?: Maybe<Array<CampaignAffinity>>;
  d2cCampaigns?: Maybe<Array<CampaignAffinity>>;
};

export type DefaultAttributeValueRatioIndex = {
  __typename?: 'DefaultAttributeValueRatioIndex';
  percentage: Scalars['Int']['output'];
  ratioIndex?: Maybe<Scalars['Int']['output']>;
  valueName: Scalars['String']['output'];
};

export type DefaultSummaryCardAttributes = {
  __typename?: 'DefaultSummaryCardAttributes';
  attributeName: Scalars['String']['output'];
  attributePercentage: Scalars['Int']['output'];
  attributeValue: Scalars['String']['output'];
};

export type DetailedAttributeValueRatioIndex = {
  __typename?: 'DetailedAttributeValueRatioIndex';
  attributeName: Scalars['String']['output'];
  percentage: Scalars['Int']['output'];
  ratioIndex?: Maybe<Scalars['Int']['output']>;
  valueName: Scalars['String']['output'];
};

export type DetailedAudienceSummaryCard = {
  __typename?: 'DetailedAudienceSummaryCard';
  defaultAttributes: Array<DetailedDefaultSummaryCardAttribute>;
  description?: Maybe<Scalars['String']['output']>;
  detailedAttributes: Array<DetailedSummaryCardAttribute>;
};

export type DetailedDefaultSummaryCardAttribute = {
  __typename?: 'DetailedDefaultSummaryCardAttribute';
  attributeName: Scalars['String']['output'];
  maxPercentage?: Maybe<DefaultAttributeValueRatioIndex>;
  maxRatioIndex?: Maybe<DefaultAttributeValueRatioIndex>;
  minRatioIndex?: Maybe<DefaultAttributeValueRatioIndex>;
};

export type DetailedSummaryCardAttribute = {
  __typename?: 'DetailedSummaryCardAttribute';
  categoryName: Scalars['String']['output'];
  highRatioIndexes?: Maybe<Array<DetailedAttributeValueRatioIndex>>;
  lowRatioIndexes?: Maybe<Array<DetailedAttributeValueRatioIndex>>;
};

export type EmailSendResult = {
  __typename?: 'EmailSendResult';
  attributesSpecified: Scalars['String']['output'];
  authorEmail: Scalars['String']['output'];
  scenario: Scalars['String']['output'];
  submittedAudiences: Scalars['String']['output'];
};

export enum EngagementStatus {
  Clicked = 'CLICKED',
  Opened = 'OPENED',
  Reached = 'REACHED'
}

export type FilterAttribute = {
  __typename?: 'FilterAttribute';
  attributeName: Scalars['String']['output'];
  attributeValues?: Maybe<Array<Scalars['String']['output']>>;
  default?: Maybe<Scalars['Boolean']['output']>;
  techNameId: Scalars['String']['output'];
};

export type FilterAttributeCategory = {
  __typename?: 'FilterAttributeCategory';
  attributeGroups?: Maybe<Array<FilterAttributeGroup>>;
  attributes?: Maybe<Array<FilterAttribute>>;
  categoryName: Scalars['String']['output'];
};

export type FilterAttributeGroup = {
  __typename?: 'FilterAttributeGroup';
  attributes?: Maybe<Array<FilterAttribute>>;
  groupName: Scalars['String']['output'];
};

export type Flavor = {
  __typename?: 'Flavor';
  flavorName?: Maybe<Scalars['String']['output']>;
  sizes?: Maybe<Array<Size>>;
};

export type FlavorGroup = {
  __typename?: 'FlavorGroup';
  flavorGroupName?: Maybe<Scalars['String']['output']>;
  flavors: Array<Flavor>;
};

export type FlavorSizes = {
  __typename?: 'FlavorSizes';
  flavors?: Maybe<Array<Flavor>>;
  groups?: Maybe<Array<FlavorGroup>>;
};

export type IncomingAudience = {
  audienceParameters?: InputMaybe<AudienceParameters>;
  benchmark: Scalars['Boolean']['input'];
  preBuiltAudienceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type IncomingAudienceComparisonTemplate = {
  name: Scalars['String']['input'];
  scenario: ComparisonScenario;
};

export type IncomingAudienceTemplateAnnotation = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export type IncomingBrand = {
  id: Scalars['Int']['input'];
  name: Scalars['String']['input'];
};

export enum IncomingScenario {
  Compare = 'COMPARE',
  Exclusive = 'EXCLUSIVE',
  Overlap = 'OVERLAP',
  Profile = 'PROFILE'
}

export type IndividualAudience = {
  audienceParameters?: InputMaybe<AudienceParameters>;
  preBuiltAudienceIds?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type Manufacturer = {
  __typename?: 'Manufacturer';
  brands: Array<Brand>;
  manufacturerId: Scalars['Int']['output'];
  manufacturerName: Scalars['String']['output'];
};

export type MarketingCampaign = {
  __typename?: 'MarketingCampaign';
  brandName: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  isEmail: Scalars['Boolean']['output'];
  marketingCampaignName: Scalars['String']['output'];
};

export enum Origin {
  FirstParty = 'FIRST_PARTY',
  ThirdParty = 'THIRD_PARTY'
}

export type PreBuiltAudience = {
  __typename?: 'PreBuiltAudience';
  id: Scalars['Int']['output'];
  name: Scalars['String']['output'];
};

export enum Scenario {
  Compare = 'COMPARE',
  Exclusive = 'EXCLUSIVE',
  Overlap = 'OVERLAP',
  Profile = 'PROFILE'
}

export type SegmentCalculationAnnotation = {
  __typename?: 'SegmentCalculationAnnotation';
  benchmark: Scalars['Boolean']['output'];
  position: Scalars['String']['output'];
  queryId: Scalars['String']['output'];
  segmentId: Scalars['String']['output'];
};

export type SegmentValue = {
  __typename?: 'SegmentValue';
  percentage: Scalars['Int']['output'];
  ratioIndex?: Maybe<Scalars['Int']['output']>;
  segmentId: Scalars['String']['output'];
};

export type ShortSummaryCardsPrerequisites = {
  requestId: Scalars['String']['input'];
  scenario: IncomingScenario;
};

export type Size = {
  __typename?: 'Size';
  orderingIdx: Scalars['Int']['output'];
  sizeIdentifier: Scalars['String']['output'];
  sizeName: Scalars['String']['output'];
};

export type SummaryCardPrerequisites = {
  queryId?: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  segmentId: Scalars['String']['input'];
};

export type TotalsPrerequisites = {
  audienceNames?: InputMaybe<Array<Scalars['String']['input']>>;
  queryId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};

export type PreBuiltAudienceNamesQueryVariables = Exact<{ [key: string]: never; }>;


export type PreBuiltAudienceNamesQuery = { __typename?: 'AudienceQuery', preBuiltAudienceNames: Array<{ __typename?: 'PreBuiltAudience', name: string, id: number }> };

export type GetBrandsQueryVariables = Exact<{
  isCompetitor?: InputMaybe<Scalars['Boolean']['input']>;
}>;


export type GetBrandsQuery = { __typename?: 'AudienceQuery', categorizedBrands: Array<{ __typename?: 'Category', categoryId: number, categoryName: string, manufacturers: Array<{ __typename?: 'Manufacturer', manufacturerId: number, manufacturerName: string, brands: Array<{ __typename?: 'Brand', brandId: number, brandName: string, firstParty: boolean, thirdParty: boolean }> }> }> };

export type BrandMarketingCampaignsQueryVariables = Exact<{
  brands: Array<IncomingBrand> | IncomingBrand;
}>;


export type BrandMarketingCampaignsQuery = { __typename?: 'AudienceQuery', brandMarketingCampaigns: { __typename?: 'D2C', d2cCampaigns?: Array<{ __typename?: 'CampaignAffinity', affinityName?: string | null, marketingCampaigns: Array<{ __typename?: 'MarketingCampaign', id: number, marketingCampaignName: string, brandName: string, isEmail: boolean }> }> | null, campaigns?: Array<{ __typename?: 'CampaignAffinity', affinityName?: string | null, marketingCampaigns: Array<{ __typename?: 'MarketingCampaign', id: number, marketingCampaignName: string, brandName: string, isEmail: boolean }> }> | null } };

export type InitiateAudienceDistributionCalcMutationVariables = Exact<{
  comparisonScenario: ComparisonScenario;
}>;


export type InitiateAudienceDistributionCalcMutation = { __typename?: 'AudienceMutation', initiateAudienceDistributionCalc: { __typename?: 'ComparisonInitiationResult', requestId: string, queryId: string } };

export type AudienceDistributionQueryVariables = Exact<{
  totalsPrerequisites: TotalsPrerequisites;
}>;


export type AudienceDistributionQuery = { __typename?: 'AudienceQuery', audienceDistribution: { __typename?: 'AudienceDistribution', only: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: string, benchmark: boolean, membersNumber: number }>, exclusive: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: string, benchmark: boolean, membersNumber: number }>, overlap: Array<{ __typename?: 'AudienceSegment', segmentId: string, position: string, benchmark: boolean, membersNumber: number }> } };

export type TemplateCreationMutationVariables = Exact<{
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
}>;


export type TemplateCreationMutation = { __typename?: 'AudienceMutation', templateCreation: { __typename?: 'AudienceComparisonTemplate', id: number, name: string } };

export type RenameAudienceTemplateMutationVariables = Exact<{
  audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation;
}>;


export type RenameAudienceTemplateMutation = { __typename?: 'AudienceMutation', renameAudienceTemplate: { __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null } };

export type CheckAudienceTemplateUniqueQueryVariables = Exact<{
  audienceComparisonTemplate: IncomingAudienceComparisonTemplate;
}>;


export type CheckAudienceTemplateUniqueQuery = { __typename?: 'AudienceQuery', checkAudienceTemplateUnique: { __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null } };

export type ListAudienceTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type ListAudienceTemplatesQuery = { __typename?: 'AudienceQuery', listAudienceTemplates: Array<{ __typename?: 'AudienceTemplateAnnotation', id?: number | null, name?: string | null }> };

export type DeleteAudienceTemplateMutationVariables = Exact<{
  templateId: Scalars['Int']['input'];
}>;


export type DeleteAudienceTemplateMutation = { __typename?: 'AudienceMutation', deleteAudienceTemplate: { __typename?: 'AudienceComparisonTemplate', id: number, name: string } };

export type GetAudienceTemplateQueryVariables = Exact<{
  templateId: Scalars['Int']['input'];
}>;


export type GetAudienceTemplateQuery = { __typename?: 'AudienceQuery', getAudienceTemplate: { __typename?: 'AudienceComparisonTemplate', id: number, name: string, userId: number, scenario: { __typename?: 'AudienceComparisonTemplateScenario', scenario: Scenario, audiences: Array<{ __typename?: 'AudienceToCompare', benchmark: boolean, preBuiltAudienceIds?: Array<number> | null, audienceParameters?: { __typename?: 'AudienceComparisonParameters', origin?: Origin | null, engagementStatus?: EngagementStatus | null, buyerTypes?: Array<BuyerType> | null, category: number, manufacturers: Array<number>, brands: Array<number>, marketingCampaigns?: Array<number> | null, flavors?: Array<string> | null, fromDate?: any | null, sizes?: Array<string> | null, toDate?: any | null } | null }> } } };

export type SubmitAudienceForActivationMutationVariables = Exact<{
  audienceActivation: AudienceActivation;
}>;


export type SubmitAudienceForActivationMutation = { __typename?: 'AudienceMutation', submitAudienceForActivation: { __typename?: 'EmailSendResult', authorEmail: string, submittedAudiences: string, attributesSpecified: string, scenario: string } };

export type FilteringAttributesQueryVariables = Exact<{ [key: string]: never; }>;


export type FilteringAttributesQuery = { __typename?: 'AudienceQuery', filteringAttributes: Array<{ __typename?: 'FilterAttributeCategory', categoryName: string, attributeGroups?: Array<{ __typename?: 'FilterAttributeGroup', groupName: string, attributes?: Array<{ __typename?: 'FilterAttribute', techNameId: string, attributeName: string, default?: boolean | null, attributeValues?: Array<string> | null }> | null }> | null, attributes?: Array<{ __typename?: 'FilterAttribute', techNameId: string, attributeName: string, default?: boolean | null, attributeValues?: Array<string> | null }> | null }> };

export type SummaryCardsQueryVariables = Exact<{
  summaryCardsPrerequisites: ShortSummaryCardsPrerequisites;
}>;


export type SummaryCardsQuery = { __typename?: 'AudienceQuery', summaryCards: Array<{ __typename?: 'AudienceSummaryCard', audienceSize: number, segmentId: string, shortDescription?: string | null, benchmark: boolean, audiencePosition: string, defaultAttributes: Array<{ __typename?: 'DefaultSummaryCardAttributes', attributeName: string, attributeValue: string, attributePercentage: number }> }> };

export type DetailedSummaryCardQueryVariables = Exact<{
  summaryCardPrerequisites: SummaryCardPrerequisites;
}>;


export type DetailedSummaryCardQuery = { __typename?: 'AudienceQuery', detailedSummaryCard: { __typename?: 'DetailedAudienceSummaryCard', description?: string | null, defaultAttributes: Array<{ __typename?: 'DetailedDefaultSummaryCardAttribute', attributeName: string, maxPercentage?: { __typename?: 'DefaultAttributeValueRatioIndex', valueName: string, percentage: number, ratioIndex?: number | null } | null, maxRatioIndex?: { __typename?: 'DefaultAttributeValueRatioIndex', valueName: string, percentage: number, ratioIndex?: number | null } | null, minRatioIndex?: { __typename?: 'DefaultAttributeValueRatioIndex', valueName: string, percentage: number, ratioIndex?: number | null } | null }>, detailedAttributes: Array<{ __typename?: 'DetailedSummaryCardAttribute', categoryName: string, lowRatioIndexes?: Array<{ __typename?: 'DetailedAttributeValueRatioIndex', attributeName: string, valueName: string, percentage: number, ratioIndex?: number | null }> | null, highRatioIndexes?: Array<{ __typename?: 'DetailedAttributeValueRatioIndex', attributeName: string, valueName: string, percentage: number, ratioIndex?: number | null }> | null }> } };

export type BarchartsQueryVariables = Exact<{
  barchartPrerequisites: BarchartPrerequisites;
}>;


export type BarchartsQuery = { __typename?: 'AudienceQuery', barcharts: Array<{ __typename?: 'BarchartTab', categoryName: string, groups?: Array<{ __typename?: 'AttributeGroup', groupName: string, attributes: Array<{ __typename?: 'Attribute', attributeName: string, identifierName: string, favorite: boolean, lowCoverage?: Array<{ __typename?: 'AttributeCoverageIndex', segmentId: string, coverageValue: number }> | null, attributeValues: Array<{ __typename?: 'AttributeValue', attributeValueName: string, segmentValues: Array<{ __typename?: 'SegmentValue', segmentId: string, percentage: number, ratioIndex?: number | null }> }> }> }> | null, attributes?: Array<{ __typename?: 'Attribute', attributeName: string, identifierName: string, favorite: boolean, lowCoverage?: Array<{ __typename?: 'AttributeCoverageIndex', segmentId: string, coverageValue: number }> | null, attributeValues: Array<{ __typename?: 'AttributeValue', attributeValueName: string, segmentValues: Array<{ __typename?: 'SegmentValue', segmentId: string, percentage: number, ratioIndex?: number | null }> }> }> | null }> };

export type FlavorsSizesQueryVariables = Exact<{
  brandId: Scalars['String']['input'];
}>;


export type FlavorsSizesQuery = { __typename?: 'AudienceQuery', flavorsSizes: { __typename?: 'FlavorSizes', flavors?: Array<{ __typename?: 'Flavor', flavorName?: string | null, sizes?: Array<{ __typename?: 'Size', sizeIdentifier: string, sizeName: string, orderingIdx: number }> | null }> | null, groups?: Array<{ __typename?: 'FlavorGroup', flavorGroupName?: string | null, flavors: Array<{ __typename?: 'Flavor', flavorName?: string | null, sizes?: Array<{ __typename?: 'Size', sizeIdentifier: string, sizeName: string, orderingIdx: number }> | null }> }> | null } };

export type DeleteFavoriteAttributesMutationVariables = Exact<{
  attributes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type DeleteFavoriteAttributesMutation = { __typename?: 'AudienceMutation', deleteFavoriteAttributes?: Array<string> | null };

export type AddFavoriteAttributesMutationVariables = Exact<{
  attributes: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type AddFavoriteAttributesMutation = { __typename?: 'AudienceMutation', addFavoriteAttributes: Array<string> };

export type GetWizardAudienceSizeQueryVariables = Exact<{
  audience: IndividualAudience;
}>;


export type GetWizardAudienceSizeQuery = { __typename?: 'AudienceQuery', getWizardAudienceSize: number };


export const PreBuiltAudienceNamesDocument = gql`
    query preBuiltAudienceNames {
  preBuiltAudienceNames {
    name
    id
  }
}
    `;

/**
 * __usePreBuiltAudienceNamesQuery__
 *
 * To run a query within a React component, call `usePreBuiltAudienceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePreBuiltAudienceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePreBuiltAudienceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePreBuiltAudienceNamesQuery(baseOptions?: Apollo.QueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
      }
export function usePreBuiltAudienceNamesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
        }
export function usePreBuiltAudienceNamesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>(PreBuiltAudienceNamesDocument, options);
        }
export type PreBuiltAudienceNamesQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesQuery>;
export type PreBuiltAudienceNamesLazyQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesLazyQuery>;
export type PreBuiltAudienceNamesSuspenseQueryHookResult = ReturnType<typeof usePreBuiltAudienceNamesSuspenseQuery>;
export type PreBuiltAudienceNamesQueryResult = Apollo.QueryResult<PreBuiltAudienceNamesQuery, PreBuiltAudienceNamesQueryVariables>;
export const GetBrandsDocument = gql`
    query getBrands($isCompetitor: Boolean = false) {
  categorizedBrands(isCompetitor: $isCompetitor) {
    categoryId
    categoryName
    manufacturers {
      manufacturerId
      manufacturerName
      brands {
        brandId
        brandName
        firstParty
        thirdParty
      }
    }
  }
}
    `;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      isCompetitor: // value for 'isCompetitor'
 *   },
 * });
 */
export function useGetBrandsQuery(baseOptions?: Apollo.QueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
      }
export function useGetBrandsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export function useGetBrandsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetBrandsQuery, GetBrandsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(GetBrandsDocument, options);
        }
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<typeof useGetBrandsLazyQuery>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<typeof useGetBrandsSuspenseQuery>;
export type GetBrandsQueryResult = Apollo.QueryResult<GetBrandsQuery, GetBrandsQueryVariables>;
export const BrandMarketingCampaignsDocument = gql`
    query brandMarketingCampaigns($brands: [IncomingBrand!]!) {
  brandMarketingCampaigns(brands: $brands) {
    d2cCampaigns {
      affinityName
      marketingCampaigns {
        id
        marketingCampaignName
        brandName
        isEmail
      }
    }
    campaigns {
      affinityName
      marketingCampaigns {
        id
        marketingCampaignName
        brandName
        isEmail
      }
    }
  }
}
    `;

/**
 * __useBrandMarketingCampaignsQuery__
 *
 * To run a query within a React component, call `useBrandMarketingCampaignsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBrandMarketingCampaignsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBrandMarketingCampaignsQuery({
 *   variables: {
 *      brands: // value for 'brands'
 *   },
 * });
 */
export function useBrandMarketingCampaignsQuery(baseOptions: Apollo.QueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables> & ({ variables: BrandMarketingCampaignsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
      }
export function useBrandMarketingCampaignsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
        }
export function useBrandMarketingCampaignsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>(BrandMarketingCampaignsDocument, options);
        }
export type BrandMarketingCampaignsQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsQuery>;
export type BrandMarketingCampaignsLazyQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsLazyQuery>;
export type BrandMarketingCampaignsSuspenseQueryHookResult = ReturnType<typeof useBrandMarketingCampaignsSuspenseQuery>;
export type BrandMarketingCampaignsQueryResult = Apollo.QueryResult<BrandMarketingCampaignsQuery, BrandMarketingCampaignsQueryVariables>;
export const InitiateAudienceDistributionCalcDocument = gql`
    mutation initiateAudienceDistributionCalc($comparisonScenario: ComparisonScenario!) {
  initiateAudienceDistributionCalc(comparisonScenario: $comparisonScenario) {
    requestId
    queryId
  }
}
    `;
export type InitiateAudienceDistributionCalcMutationFn = Apollo.MutationFunction<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>;

/**
 * __useInitiateAudienceDistributionCalcMutation__
 *
 * To run a mutation, you first call `useInitiateAudienceDistributionCalcMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitiateAudienceDistributionCalcMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initiateAudienceDistributionCalcMutation, { data, loading, error }] = useInitiateAudienceDistributionCalcMutation({
 *   variables: {
 *      comparisonScenario: // value for 'comparisonScenario'
 *   },
 * });
 */
export function useInitiateAudienceDistributionCalcMutation(baseOptions?: Apollo.MutationHookOptions<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>(InitiateAudienceDistributionCalcDocument, options);
      }
export type InitiateAudienceDistributionCalcMutationHookResult = ReturnType<typeof useInitiateAudienceDistributionCalcMutation>;
export type InitiateAudienceDistributionCalcMutationResult = Apollo.MutationResult<InitiateAudienceDistributionCalcMutation>;
export type InitiateAudienceDistributionCalcMutationOptions = Apollo.BaseMutationOptions<InitiateAudienceDistributionCalcMutation, InitiateAudienceDistributionCalcMutationVariables>;
export const AudienceDistributionDocument = gql`
    query audienceDistribution($totalsPrerequisites: TotalsPrerequisites!) {
  audienceDistribution(totalsPrerequisites: $totalsPrerequisites) {
    only {
      segmentId
      position
      benchmark
      membersNumber
    }
    exclusive {
      segmentId
      position
      benchmark
      membersNumber
    }
    overlap {
      segmentId
      position
      benchmark
      membersNumber
    }
  }
}
    `;

/**
 * __useAudienceDistributionQuery__
 *
 * To run a query within a React component, call `useAudienceDistributionQuery` and pass it any options that fit your needs.
 * When your component renders, `useAudienceDistributionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAudienceDistributionQuery({
 *   variables: {
 *      totalsPrerequisites: // value for 'totalsPrerequisites'
 *   },
 * });
 */
export function useAudienceDistributionQuery(baseOptions: Apollo.QueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables> & ({ variables: AudienceDistributionQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
      }
export function useAudienceDistributionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
        }
export function useAudienceDistributionSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<AudienceDistributionQuery, AudienceDistributionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<AudienceDistributionQuery, AudienceDistributionQueryVariables>(AudienceDistributionDocument, options);
        }
export type AudienceDistributionQueryHookResult = ReturnType<typeof useAudienceDistributionQuery>;
export type AudienceDistributionLazyQueryHookResult = ReturnType<typeof useAudienceDistributionLazyQuery>;
export type AudienceDistributionSuspenseQueryHookResult = ReturnType<typeof useAudienceDistributionSuspenseQuery>;
export type AudienceDistributionQueryResult = Apollo.QueryResult<AudienceDistributionQuery, AudienceDistributionQueryVariables>;
export const TemplateCreationDocument = gql`
    mutation templateCreation($audienceComparisonTemplate: IncomingAudienceComparisonTemplate!) {
  templateCreation(audienceComparisonTemplate: $audienceComparisonTemplate) {
    id
    name
  }
}
    `;
export type TemplateCreationMutationFn = Apollo.MutationFunction<TemplateCreationMutation, TemplateCreationMutationVariables>;

/**
 * __useTemplateCreationMutation__
 *
 * To run a mutation, you first call `useTemplateCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTemplateCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [templateCreationMutation, { data, loading, error }] = useTemplateCreationMutation({
 *   variables: {
 *      audienceComparisonTemplate: // value for 'audienceComparisonTemplate'
 *   },
 * });
 */
export function useTemplateCreationMutation(baseOptions?: Apollo.MutationHookOptions<TemplateCreationMutation, TemplateCreationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<TemplateCreationMutation, TemplateCreationMutationVariables>(TemplateCreationDocument, options);
      }
export type TemplateCreationMutationHookResult = ReturnType<typeof useTemplateCreationMutation>;
export type TemplateCreationMutationResult = Apollo.MutationResult<TemplateCreationMutation>;
export type TemplateCreationMutationOptions = Apollo.BaseMutationOptions<TemplateCreationMutation, TemplateCreationMutationVariables>;
export const RenameAudienceTemplateDocument = gql`
    mutation renameAudienceTemplate($audienceTemplateAnnotation: IncomingAudienceTemplateAnnotation!) {
  renameAudienceTemplate(audienceTemplateAnnotation: $audienceTemplateAnnotation) {
    id
    name
  }
}
    `;
export type RenameAudienceTemplateMutationFn = Apollo.MutationFunction<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>;

/**
 * __useRenameAudienceTemplateMutation__
 *
 * To run a mutation, you first call `useRenameAudienceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameAudienceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameAudienceTemplateMutation, { data, loading, error }] = useRenameAudienceTemplateMutation({
 *   variables: {
 *      audienceTemplateAnnotation: // value for 'audienceTemplateAnnotation'
 *   },
 * });
 */
export function useRenameAudienceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>(RenameAudienceTemplateDocument, options);
      }
export type RenameAudienceTemplateMutationHookResult = ReturnType<typeof useRenameAudienceTemplateMutation>;
export type RenameAudienceTemplateMutationResult = Apollo.MutationResult<RenameAudienceTemplateMutation>;
export type RenameAudienceTemplateMutationOptions = Apollo.BaseMutationOptions<RenameAudienceTemplateMutation, RenameAudienceTemplateMutationVariables>;
export const CheckAudienceTemplateUniqueDocument = gql`
    query checkAudienceTemplateUnique($audienceComparisonTemplate: IncomingAudienceComparisonTemplate!) {
  checkAudienceTemplateUnique(
    audienceComparisonTemplate: $audienceComparisonTemplate
  ) {
    id
    name
  }
}
    `;

/**
 * __useCheckAudienceTemplateUniqueQuery__
 *
 * To run a query within a React component, call `useCheckAudienceTemplateUniqueQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAudienceTemplateUniqueQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAudienceTemplateUniqueQuery({
 *   variables: {
 *      audienceComparisonTemplate: // value for 'audienceComparisonTemplate'
 *   },
 * });
 */
export function useCheckAudienceTemplateUniqueQuery(baseOptions: Apollo.QueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables> & ({ variables: CheckAudienceTemplateUniqueQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
      }
export function useCheckAudienceTemplateUniqueLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
        }
export function useCheckAudienceTemplateUniqueSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>(CheckAudienceTemplateUniqueDocument, options);
        }
export type CheckAudienceTemplateUniqueQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueQuery>;
export type CheckAudienceTemplateUniqueLazyQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueLazyQuery>;
export type CheckAudienceTemplateUniqueSuspenseQueryHookResult = ReturnType<typeof useCheckAudienceTemplateUniqueSuspenseQuery>;
export type CheckAudienceTemplateUniqueQueryResult = Apollo.QueryResult<CheckAudienceTemplateUniqueQuery, CheckAudienceTemplateUniqueQueryVariables>;
export const ListAudienceTemplatesDocument = gql`
    query listAudienceTemplates {
  listAudienceTemplates {
    id
    name
  }
}
    `;

/**
 * __useListAudienceTemplatesQuery__
 *
 * To run a query within a React component, call `useListAudienceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAudienceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAudienceTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAudienceTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
      }
export function useListAudienceTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
        }
export function useListAudienceTemplatesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>(ListAudienceTemplatesDocument, options);
        }
export type ListAudienceTemplatesQueryHookResult = ReturnType<typeof useListAudienceTemplatesQuery>;
export type ListAudienceTemplatesLazyQueryHookResult = ReturnType<typeof useListAudienceTemplatesLazyQuery>;
export type ListAudienceTemplatesSuspenseQueryHookResult = ReturnType<typeof useListAudienceTemplatesSuspenseQuery>;
export type ListAudienceTemplatesQueryResult = Apollo.QueryResult<ListAudienceTemplatesQuery, ListAudienceTemplatesQueryVariables>;
export const DeleteAudienceTemplateDocument = gql`
    mutation DeleteAudienceTemplate($templateId: Int!) {
  deleteAudienceTemplate(templateId: $templateId) {
    id
    name
  }
}
    `;
export type DeleteAudienceTemplateMutationFn = Apollo.MutationFunction<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>;

/**
 * __useDeleteAudienceTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteAudienceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAudienceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAudienceTemplateMutation, { data, loading, error }] = useDeleteAudienceTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteAudienceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>(DeleteAudienceTemplateDocument, options);
      }
export type DeleteAudienceTemplateMutationHookResult = ReturnType<typeof useDeleteAudienceTemplateMutation>;
export type DeleteAudienceTemplateMutationResult = Apollo.MutationResult<DeleteAudienceTemplateMutation>;
export type DeleteAudienceTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteAudienceTemplateMutation, DeleteAudienceTemplateMutationVariables>;
export const GetAudienceTemplateDocument = gql`
    query GetAudienceTemplate($templateId: Int!) {
  getAudienceTemplate(templateId: $templateId) {
    id
    name
    userId
    scenario {
      scenario
      audiences {
        benchmark
        preBuiltAudienceIds
        audienceParameters {
          origin
          engagementStatus
          buyerTypes
          category
          manufacturers
          brands
          marketingCampaigns
          flavors
          fromDate
          sizes
          toDate
        }
      }
    }
  }
}
    `;

/**
 * __useGetAudienceTemplateQuery__
 *
 * To run a query within a React component, call `useGetAudienceTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAudienceTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAudienceTemplateQuery({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGetAudienceTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables> & ({ variables: GetAudienceTemplateQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
      }
export function useGetAudienceTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
        }
export function useGetAudienceTemplateSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>(GetAudienceTemplateDocument, options);
        }
export type GetAudienceTemplateQueryHookResult = ReturnType<typeof useGetAudienceTemplateQuery>;
export type GetAudienceTemplateLazyQueryHookResult = ReturnType<typeof useGetAudienceTemplateLazyQuery>;
export type GetAudienceTemplateSuspenseQueryHookResult = ReturnType<typeof useGetAudienceTemplateSuspenseQuery>;
export type GetAudienceTemplateQueryResult = Apollo.QueryResult<GetAudienceTemplateQuery, GetAudienceTemplateQueryVariables>;
export const SubmitAudienceForActivationDocument = gql`
    mutation submitAudienceForActivation($audienceActivation: AudienceActivation!) {
  submitAudienceForActivation(audienceActivation: $audienceActivation) {
    authorEmail
    submittedAudiences
    attributesSpecified
    scenario
  }
}
    `;
export type SubmitAudienceForActivationMutationFn = Apollo.MutationFunction<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>;

/**
 * __useSubmitAudienceForActivationMutation__
 *
 * To run a mutation, you first call `useSubmitAudienceForActivationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAudienceForActivationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAudienceForActivationMutation, { data, loading, error }] = useSubmitAudienceForActivationMutation({
 *   variables: {
 *      audienceActivation: // value for 'audienceActivation'
 *   },
 * });
 */
export function useSubmitAudienceForActivationMutation(baseOptions?: Apollo.MutationHookOptions<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>(SubmitAudienceForActivationDocument, options);
      }
export type SubmitAudienceForActivationMutationHookResult = ReturnType<typeof useSubmitAudienceForActivationMutation>;
export type SubmitAudienceForActivationMutationResult = Apollo.MutationResult<SubmitAudienceForActivationMutation>;
export type SubmitAudienceForActivationMutationOptions = Apollo.BaseMutationOptions<SubmitAudienceForActivationMutation, SubmitAudienceForActivationMutationVariables>;
export const FilteringAttributesDocument = gql`
    query filteringAttributes {
  filteringAttributes {
    categoryName
    attributeGroups {
      groupName
      attributes {
        techNameId
        attributeName
        default
        attributeValues
      }
    }
    attributes {
      techNameId
      attributeName
      default
      attributeValues
    }
  }
}
    `;

/**
 * __useFilteringAttributesQuery__
 *
 * To run a query within a React component, call `useFilteringAttributesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteringAttributesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteringAttributesQuery({
 *   variables: {
 *   },
 * });
 */
export function useFilteringAttributesQuery(baseOptions?: Apollo.QueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
      }
export function useFilteringAttributesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
        }
export function useFilteringAttributesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FilteringAttributesQuery, FilteringAttributesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FilteringAttributesQuery, FilteringAttributesQueryVariables>(FilteringAttributesDocument, options);
        }
export type FilteringAttributesQueryHookResult = ReturnType<typeof useFilteringAttributesQuery>;
export type FilteringAttributesLazyQueryHookResult = ReturnType<typeof useFilteringAttributesLazyQuery>;
export type FilteringAttributesSuspenseQueryHookResult = ReturnType<typeof useFilteringAttributesSuspenseQuery>;
export type FilteringAttributesQueryResult = Apollo.QueryResult<FilteringAttributesQuery, FilteringAttributesQueryVariables>;
export const SummaryCardsDocument = gql`
    query summaryCards($summaryCardsPrerequisites: ShortSummaryCardsPrerequisites!) {
  summaryCards(summaryCardsPrerequisites: $summaryCardsPrerequisites) {
    audienceSize
    segmentId
    shortDescription
    benchmark
    audiencePosition
    defaultAttributes {
      attributeName
      attributeValue
      attributePercentage
    }
  }
}
    `;

/**
 * __useSummaryCardsQuery__
 *
 * To run a query within a React component, call `useSummaryCardsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSummaryCardsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSummaryCardsQuery({
 *   variables: {
 *      summaryCardsPrerequisites: // value for 'summaryCardsPrerequisites'
 *   },
 * });
 */
export function useSummaryCardsQuery(baseOptions: Apollo.QueryHookOptions<SummaryCardsQuery, SummaryCardsQueryVariables> & ({ variables: SummaryCardsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SummaryCardsQuery, SummaryCardsQueryVariables>(SummaryCardsDocument, options);
      }
export function useSummaryCardsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SummaryCardsQuery, SummaryCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SummaryCardsQuery, SummaryCardsQueryVariables>(SummaryCardsDocument, options);
        }
export function useSummaryCardsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<SummaryCardsQuery, SummaryCardsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<SummaryCardsQuery, SummaryCardsQueryVariables>(SummaryCardsDocument, options);
        }
export type SummaryCardsQueryHookResult = ReturnType<typeof useSummaryCardsQuery>;
export type SummaryCardsLazyQueryHookResult = ReturnType<typeof useSummaryCardsLazyQuery>;
export type SummaryCardsSuspenseQueryHookResult = ReturnType<typeof useSummaryCardsSuspenseQuery>;
export type SummaryCardsQueryResult = Apollo.QueryResult<SummaryCardsQuery, SummaryCardsQueryVariables>;
export const DetailedSummaryCardDocument = gql`
    query detailedSummaryCard($summaryCardPrerequisites: SummaryCardPrerequisites!) {
  detailedSummaryCard(summaryCardPrerequisites: $summaryCardPrerequisites) {
    description
    defaultAttributes {
      attributeName
      maxPercentage {
        valueName
        percentage
        ratioIndex
      }
      maxRatioIndex {
        valueName
        percentage
        ratioIndex
      }
      minRatioIndex {
        valueName
        percentage
        ratioIndex
      }
    }
    detailedAttributes {
      categoryName
      lowRatioIndexes {
        attributeName
        valueName
        percentage
        ratioIndex
      }
      highRatioIndexes {
        attributeName
        valueName
        percentage
        ratioIndex
      }
    }
  }
}
    `;

/**
 * __useDetailedSummaryCardQuery__
 *
 * To run a query within a React component, call `useDetailedSummaryCardQuery` and pass it any options that fit your needs.
 * When your component renders, `useDetailedSummaryCardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDetailedSummaryCardQuery({
 *   variables: {
 *      summaryCardPrerequisites: // value for 'summaryCardPrerequisites'
 *   },
 * });
 */
export function useDetailedSummaryCardQuery(baseOptions: Apollo.QueryHookOptions<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables> & ({ variables: DetailedSummaryCardQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>(DetailedSummaryCardDocument, options);
      }
export function useDetailedSummaryCardLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>(DetailedSummaryCardDocument, options);
        }
export function useDetailedSummaryCardSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>(DetailedSummaryCardDocument, options);
        }
export type DetailedSummaryCardQueryHookResult = ReturnType<typeof useDetailedSummaryCardQuery>;
export type DetailedSummaryCardLazyQueryHookResult = ReturnType<typeof useDetailedSummaryCardLazyQuery>;
export type DetailedSummaryCardSuspenseQueryHookResult = ReturnType<typeof useDetailedSummaryCardSuspenseQuery>;
export type DetailedSummaryCardQueryResult = Apollo.QueryResult<DetailedSummaryCardQuery, DetailedSummaryCardQueryVariables>;
export const BarchartsDocument = gql`
    query barcharts($barchartPrerequisites: BarchartPrerequisites!) {
  barcharts(barchartPrerequisites: $barchartPrerequisites) {
    categoryName
    groups {
      groupName
      attributes {
        attributeName
        identifierName
        favorite
        lowCoverage {
          segmentId
          coverageValue
        }
        attributeValues {
          attributeValueName
          segmentValues {
            segmentId
            percentage
            ratioIndex
          }
        }
      }
    }
    attributes {
      attributeName
      identifierName
      favorite
      lowCoverage {
        segmentId
        coverageValue
      }
      attributeValues {
        attributeValueName
        segmentValues {
          segmentId
          percentage
          ratioIndex
        }
      }
    }
  }
}
    `;

/**
 * __useBarchartsQuery__
 *
 * To run a query within a React component, call `useBarchartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBarchartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBarchartsQuery({
 *   variables: {
 *      barchartPrerequisites: // value for 'barchartPrerequisites'
 *   },
 * });
 */
export function useBarchartsQuery(baseOptions: Apollo.QueryHookOptions<BarchartsQuery, BarchartsQueryVariables> & ({ variables: BarchartsQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<BarchartsQuery, BarchartsQueryVariables>(BarchartsDocument, options);
      }
export function useBarchartsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<BarchartsQuery, BarchartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<BarchartsQuery, BarchartsQueryVariables>(BarchartsDocument, options);
        }
export function useBarchartsSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<BarchartsQuery, BarchartsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<BarchartsQuery, BarchartsQueryVariables>(BarchartsDocument, options);
        }
export type BarchartsQueryHookResult = ReturnType<typeof useBarchartsQuery>;
export type BarchartsLazyQueryHookResult = ReturnType<typeof useBarchartsLazyQuery>;
export type BarchartsSuspenseQueryHookResult = ReturnType<typeof useBarchartsSuspenseQuery>;
export type BarchartsQueryResult = Apollo.QueryResult<BarchartsQuery, BarchartsQueryVariables>;
export const FlavorsSizesDocument = gql`
    query flavorsSizes($brandId: String!) {
  flavorsSizes(brandId: $brandId) {
    flavors {
      flavorName
      sizes {
        sizeIdentifier
        sizeName
        orderingIdx
      }
    }
    groups {
      flavorGroupName
      flavors {
        flavorName
        sizes {
          sizeIdentifier
          sizeName
          orderingIdx
        }
      }
    }
  }
}
    `;

/**
 * __useFlavorsSizesQuery__
 *
 * To run a query within a React component, call `useFlavorsSizesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFlavorsSizesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFlavorsSizesQuery({
 *   variables: {
 *      brandId: // value for 'brandId'
 *   },
 * });
 */
export function useFlavorsSizesQuery(baseOptions: Apollo.QueryHookOptions<FlavorsSizesQuery, FlavorsSizesQueryVariables> & ({ variables: FlavorsSizesQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FlavorsSizesQuery, FlavorsSizesQueryVariables>(FlavorsSizesDocument, options);
      }
export function useFlavorsSizesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FlavorsSizesQuery, FlavorsSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FlavorsSizesQuery, FlavorsSizesQueryVariables>(FlavorsSizesDocument, options);
        }
export function useFlavorsSizesSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<FlavorsSizesQuery, FlavorsSizesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FlavorsSizesQuery, FlavorsSizesQueryVariables>(FlavorsSizesDocument, options);
        }
export type FlavorsSizesQueryHookResult = ReturnType<typeof useFlavorsSizesQuery>;
export type FlavorsSizesLazyQueryHookResult = ReturnType<typeof useFlavorsSizesLazyQuery>;
export type FlavorsSizesSuspenseQueryHookResult = ReturnType<typeof useFlavorsSizesSuspenseQuery>;
export type FlavorsSizesQueryResult = Apollo.QueryResult<FlavorsSizesQuery, FlavorsSizesQueryVariables>;
export const DeleteFavoriteAttributesDocument = gql`
    mutation deleteFavoriteAttributes($attributes: [String!]!) {
  deleteFavoriteAttributes(attributes: $attributes)
}
    `;
export type DeleteFavoriteAttributesMutationFn = Apollo.MutationFunction<DeleteFavoriteAttributesMutation, DeleteFavoriteAttributesMutationVariables>;

/**
 * __useDeleteFavoriteAttributesMutation__
 *
 * To run a mutation, you first call `useDeleteFavoriteAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFavoriteAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFavoriteAttributesMutation, { data, loading, error }] = useDeleteFavoriteAttributesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useDeleteFavoriteAttributesMutation(baseOptions?: Apollo.MutationHookOptions<DeleteFavoriteAttributesMutation, DeleteFavoriteAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteFavoriteAttributesMutation, DeleteFavoriteAttributesMutationVariables>(DeleteFavoriteAttributesDocument, options);
      }
export type DeleteFavoriteAttributesMutationHookResult = ReturnType<typeof useDeleteFavoriteAttributesMutation>;
export type DeleteFavoriteAttributesMutationResult = Apollo.MutationResult<DeleteFavoriteAttributesMutation>;
export type DeleteFavoriteAttributesMutationOptions = Apollo.BaseMutationOptions<DeleteFavoriteAttributesMutation, DeleteFavoriteAttributesMutationVariables>;
export const AddFavoriteAttributesDocument = gql`
    mutation addFavoriteAttributes($attributes: [String!]!) {
  addFavoriteAttributes(attributes: $attributes)
}
    `;
export type AddFavoriteAttributesMutationFn = Apollo.MutationFunction<AddFavoriteAttributesMutation, AddFavoriteAttributesMutationVariables>;

/**
 * __useAddFavoriteAttributesMutation__
 *
 * To run a mutation, you first call `useAddFavoriteAttributesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddFavoriteAttributesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addFavoriteAttributesMutation, { data, loading, error }] = useAddFavoriteAttributesMutation({
 *   variables: {
 *      attributes: // value for 'attributes'
 *   },
 * });
 */
export function useAddFavoriteAttributesMutation(baseOptions?: Apollo.MutationHookOptions<AddFavoriteAttributesMutation, AddFavoriteAttributesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddFavoriteAttributesMutation, AddFavoriteAttributesMutationVariables>(AddFavoriteAttributesDocument, options);
      }
export type AddFavoriteAttributesMutationHookResult = ReturnType<typeof useAddFavoriteAttributesMutation>;
export type AddFavoriteAttributesMutationResult = Apollo.MutationResult<AddFavoriteAttributesMutation>;
export type AddFavoriteAttributesMutationOptions = Apollo.BaseMutationOptions<AddFavoriteAttributesMutation, AddFavoriteAttributesMutationVariables>;
export const GetWizardAudienceSizeDocument = gql`
    query getWizardAudienceSize($audience: IndividualAudience!) {
  getWizardAudienceSize(audience: $audience)
}
    `;

/**
 * __useGetWizardAudienceSizeQuery__
 *
 * To run a query within a React component, call `useGetWizardAudienceSizeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWizardAudienceSizeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWizardAudienceSizeQuery({
 *   variables: {
 *      audience: // value for 'audience'
 *   },
 * });
 */
export function useGetWizardAudienceSizeQuery(baseOptions: Apollo.QueryHookOptions<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables> & ({ variables: GetWizardAudienceSizeQueryVariables; skip?: boolean; } | { skip: boolean; }) ) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>(GetWizardAudienceSizeDocument, options);
      }
export function useGetWizardAudienceSizeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>(GetWizardAudienceSizeDocument, options);
        }
export function useGetWizardAudienceSizeSuspenseQuery(baseOptions?: Apollo.SuspenseQueryHookOptions<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>(GetWizardAudienceSizeDocument, options);
        }
export type GetWizardAudienceSizeQueryHookResult = ReturnType<typeof useGetWizardAudienceSizeQuery>;
export type GetWizardAudienceSizeLazyQueryHookResult = ReturnType<typeof useGetWizardAudienceSizeLazyQuery>;
export type GetWizardAudienceSizeSuspenseQueryHookResult = ReturnType<typeof useGetWizardAudienceSizeSuspenseQuery>;
export type GetWizardAudienceSizeQueryResult = Apollo.QueryResult<GetWizardAudienceSizeQuery, GetWizardAudienceSizeQueryVariables>;