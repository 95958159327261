import './AttributesComparison.scss';

import { AppstoreOutlined, BorderOutlined, DownloadOutlined } from '@ant-design/icons';
import { Button, Col, Flex, message, Radio, Row, Spin, Switch, Tabs } from 'antd';
import cn from 'classnames';
import * as htmlToImage from 'html-to-image';
import flatten from 'lodash/flatten';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Attribute,
  AttributeGroup,
  IncomingScenario,
  useBarchartsQuery,
  useFilteringAttributesQuery,
} from '../../../apollo/graphql-types';
import { selectAppliedScenario } from '../../../store/audienceComparison/selectors';
import { NATIONAL_REFERENCE, options } from '../../Blade/collapse/const';
import { ErrorRetryCircle } from '../../Error/ErrorRetryCircle';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AudienceNamesContext } from '../FilledAudienceComparison';
import { IndexDescription } from '../SummaryCardsDetails/IndexDescription';
import { EView, IFavoriteTabItem } from '../typings';
import { AttributeContent } from './AttributeContent/AttributeContent';
import { Filter } from './Filter/Filter';
import { getDefaultExpandedAndCheckedKeys } from './Filter/utils';

interface IAttributesComparisonProps {
  requestId: string;
}

export const ShowSwitcherContext = React.createContext( { showIndexes: false, showNationalReference: true } );
export const AttributesComparison = ( { requestId }: IAttributesComparisonProps ) => {
  const scenario = useSelector( selectAppliedScenario );
  const audienceNames = useContext( AudienceNamesContext );
  const [showIndexes, setShowIndexes] = useState( false );
  const [showNationalReference, setShowNationalReference] = useState( true );
  const [view, setView] = useState<EView>( EView.list );
  const [selectedTab, setSelectedTab] = useState( '' );
  const [showDownloadButton, setShowDownloadButton] = useState( false );

  const tabsRef = useRef<HTMLDivElement>( null );

  const { data: attributesData } = useFilteringAttributesQuery( options() );
  const defaultExpandedAndCheckedKeys = useMemo(
    () => getDefaultExpandedAndCheckedKeys( attributesData?.filteringAttributes ), [attributesData]
  );
  const [attributesToShow, setAttributesToShow] = useState<string[]>( [] );

  const [messageApi, contextHolder] = message.useMessage();

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: 'Something went wrong',
    } );
  };

  const successMessage = ( content: React.ReactNode ) => {
    messageApi.open( {
      type: 'success',
      content,
    } );
  };

  const { data: barchartsData, loading, error, refetch } = useBarchartsQuery( {
    ...options(),
    notifyOnNetworkStatusChange: true,
    variables:                   {
      barchartPrerequisites: {
        requestId,
        scenario,
        attributeNames: null,
      },
    },
  } );

  const favoriteCategories = barchartsData?.barcharts.reduce( ( acc, category ) => {
    const attributes = category.attributes?.filter( ( attribute ) =>
      attribute.favorite && attributesToShow.includes( attribute.attributeName ) );
    const attributeGroups = category.groups
      ?.map( ( group ) => ( {
        ...group,
        attributes: group.attributes
          .filter( ( attr ) => attr.favorite && attributesToShow.includes( attr.attributeName ) )
          .map( ( attribute ) => ( {
            ...attribute,
            attributeName: `${group.groupName} - ${attribute.attributeName}`,
          } ) ),
      } ) )
      .filter( ( group ) => group.attributes.length );

    acc.push( {
      attributes:   attributes,
      groups:       attributeGroups,
      categoryName: category.categoryName,
    } );

    return acc;
  }, [] as Array<IFavoriteTabItem> )
    ?.filter( ( category ) => !!category.attributes?.length || !!category.groups?.length );


  useEffect( () => {
    if ( !!flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ).length ) {
      setAttributesToShow( flatten( Object.values( defaultExpandedAndCheckedKeys[0] ) ) );
    }
  }, [defaultExpandedAndCheckedKeys] );

  const generateTabContent = ( attributes: Array<Attribute | AttributeGroup> ) => attributes.map( ( attribute ) => {
    const attributeContent = 'groupName' in attribute ? attribute.attributes : [attribute];

    return attributeContent.map( ( attribute ) => (
      <AttributeContent
        attribute={attribute}
        key={attribute.attributeName}
        view={view}
        itemsCount={attributes.length}
        successMessage={successMessage}
        errorMessage={errorMessage}
        selectedTab={selectedTab}
      />
    ) );
  } );

  const favoriteTab = useMemo( () => {
    const children = favoriteCategories
      ?.map( ( category ) => (
        <React.Fragment key={category.categoryName}>
          <Row>
            <Col span={24} className='category-title'>
              <TitleWithCaption headingLevel={4} heading={category.categoryName} />
            </Col>
          </Row>
          <Row>
            {generateTabContent( [...( category.attributes || [] ), ...( category.groups || [] )] )}
          </Row>
        </React.Fragment>
      ) );

    return {
      label:       'Favourites',
      children,
      key:         'Favourites',
    };
  }, [barchartsData, view, selectedTab, attributesToShow] );

  const tabs = ( barchartsData?.barcharts ?? [] ).map( ( category ) => {
    const attributes = category.attributes?.filter( ( attribute ) =>
      !!attribute && attributesToShow.includes( attribute.attributeName ) ) || [];

    const attributeGroups = category.groups?.map( ( attributeMap ) => {
      const attributesList = attributeMap.attributes
        .filter( ( attribute ) => attributesToShow.includes( attribute.attributeName ) )
        .map( ( attribute ) => ( {
          ...attribute,
          attributeName: `${attributeMap.groupName} - ${attribute.attributeName}`,
        } ) );
      return {
        ...attributeMap,
        attributes: attributesList,
      };
    } ).filter( ( attribute ) => !!attribute.attributes.length ) || [];

    if ( !attributes.length && !attributeGroups.length ) {
      return null;
    }

    const children = <React.Fragment key={category.categoryName}>
      <Row>
        {generateTabContent( [...attributes, ...attributeGroups] )}
      </Row>
    </React.Fragment>;

    return {
      children,
      key:   category.categoryName,
      label: category.categoryName,
    };
  } ).filter( ( tab ) => !!tab );

  const tabItems = !!favoriteTab?.children?.length
    ? [ favoriteTab, ...tabs]
    : tabs;

  const filter = ( node: HTMLElement ) => {
    const exclusionClasses = ['show-more', 'download-button', 'anticon-info-circle', 'show-all-labels', 'favourite-button'];
    return !exclusionClasses.some( ( classname ) => node.classList?.contains( classname ) );
  };

  const handleDownloadTab = () => {
    const diagramRefs = tabsRef.current?.querySelectorAll( '.ant-tabs-tabpane-active .attribute-content-inner' );

    if ( !!diagramRefs ) {
      diagramRefs.forEach( ( ref ) => {
        const attributeName = ( ref.querySelector( '.attribute-title h5' ) as HTMLHeadingElement )?.innerText;
        const attributeLowCoverage = ref.querySelector( '.low-coverage' );

        if ( !attributeLowCoverage ) {
          htmlToImage.toJpeg( ref as HTMLElement, { backgroundColor: '#ffffff', filter: filter } )
            .then( function ( dataUrl ) {
              const link = document.createElement( 'a' );
              link.download = `CDS_${attributeName}.jpeg`;
              link.href = dataUrl;
              link.click();
            } );
        }
      } );
    }
  };

  useEffect( () => {
    if ( !!tabItems.length ) {
      if ( ( !favoriteTab.children?.length && selectedTab === 'Favourites' ) || !tabItems.find( ( tab ) => tab.label === selectedTab ) ) {
        setSelectedTab( tabItems[0].label );
      }

      if ( !selectedTab ) {
        setSelectedTab( favoriteTab.children?.length ? 'Favourites': tabItems[0].label );
      }
    }
  }, [tabItems, favoriteTab] );

  useEffect( () => {
    if ( !showNationalReference ) {
      setShowIndexes( false );
    }
  }, [showNationalReference] );

  const checkTabIsLowCoverage = () => {
    const tab = selectedTab === 'Favourites'
      ? favoriteCategories?.reduce( ( acc, category ) => {
        if ( category.attributes ) {
          acc.attributes = [...acc.attributes, ...category.attributes];
        }

        if ( category.groups ) {
          acc.groups = [...acc.groups, ...category.groups];
        }

        return acc;

      }, {
        attributes: [] as Attribute[],
        groups:     [] as AttributeGroup[],
      } )
      : barchartsData?.barcharts.find( ( tab ) => tab.categoryName === selectedTab );

    if ( !tab ) return false;

    const attributesLowCoverage = tab.attributes
      ?.filter( ( attr ) => attributesToShow.includes( attr.attributeName ) )
      .map( ( attribute ) => attribute.lowCoverage )
      || [];

    const groupLowCoverage = flatten( tab.groups?.map( ( group ) => group.attributes
      ?.filter( ( attr ) => attributesToShow.includes( attr.attributeName.replace( `${group.groupName} - `, '' ) ) )
      .map( ( attribute ) => attribute.lowCoverage ) ) ) || [];

    const lowCoverage = flatten(
      [
        ...attributesLowCoverage,
        ...groupLowCoverage,
      ]
    );

    return lowCoverage.every( ( attribute ) => attribute?.coverageValue === 0 );
  };

  useEffect( () => {
    if ( checkTabIsLowCoverage() ) {
      setShowDownloadButton( false );
      return;
    }

    setShowDownloadButton( true );
  }, [selectedTab, favoriteCategories] );

  return <>
    {contextHolder}
    <Row className='section-title'>
      <Col span={10}>
        <Flex align='center'>
          <TitleWithCaption headingLevel={2} heading='Audience Distributions' />
        </Flex>
      </Col>
      <Col span={14}>
        <Flex justify='flex-end' align='center'>
          {!error && !loading && (
            <>
              <div className='show-indexes show-switch'>
                {
                  scenario !== IncomingScenario.Compare && (
                    <div className='show-national-reference show-switch'>
                      <Switch
                        size='small'
                        value={showNationalReference}
                        onChange={( value ) => setShowNationalReference( value )}
                      /> Show {NATIONAL_REFERENCE}
                    </div>
                  )
                }

                <Switch
                  size='small'
                  disabled={!showNationalReference}
                  value={showIndexes}
                  onChange={( value ) => setShowIndexes( value )}
                /> Show Indexes <IndexDescription />
              </div>

              <Radio.Group className='view-switcher' value={view} onChange={( e ) => setView( e.target.value )}>
                <Radio.Button value={EView.list}><BorderOutlined /></Radio.Button>
                <Radio.Button value={EView.grid}><AppstoreOutlined /></Radio.Button>
              </Radio.Group>
              <Filter setAttributesToShow={setAttributesToShow} />
            </>
          )}
        </Flex>
      </Col>
    </Row>

    {
      error
        ? (
          <ErrorRetryCircle
            title='Error'
            description={'Something went wrong. Couldn\'t load the data. Please try again.'}
            onClick={() => {
              refetch();
            }}
          />
        )
        : loading || Object.values( audienceNames ).includes( 'loading' )
          ? (
            <Flex
              vertical
              align='center'
              justify='center'
              className={cn( 'attributes-comparison-spinner' )}
            >
              <Spin />
              <span>The request might take a little longer...</span>
            </Flex>
          )
          : (
            <div className='attributes-comparison-content' ref={tabsRef}>
              <ShowSwitcherContext.Provider value={{ showIndexes, showNationalReference }}>
                <Tabs
                  type='card'
                  items={tabItems}
                  activeKey={selectedTab}
                  onTabClick={( tab ) => setSelectedTab( tab )}
                  tabBarExtraContent={showDownloadButton && (
                    <Button
                      className='download-button'
                      icon={<DownloadOutlined />}
                      type='link'
                      onClick={handleDownloadTab}>Download Tab</Button>
                  )}
                />
              </ShowSwitcherContext.Provider>
            </div>
          )
    }
  </>;
};
