import { CheckOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Collapse, Divider, Space } from 'antd';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { usePreBuiltAudienceNamesQuery } from '../../../../apollo/graphql-types';
import { setPreBuiltAudienceIds } from '../../../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAudiencesToCompare,
  selectIsEqualComparisons,
  selectPreBuiltAudiencesByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../store/tab/actions';
import { collapseAllWizards, setPrebuiltAudienceWizard } from '../../../../store/wizzard/actions';
import { selectWizardPrebuiltAudienceByTabName } from '../../../../store/wizzard/selectors';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import { AUDIENCE_ID, options, stepErrorDescription } from '../const';
import { ICollapseProps } from '../types';
import { handleScrollIntoView } from '../utils';

interface IPrebuiltAudienceCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const PrebuiltAudienceCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IPrebuiltAudienceCollapseProps ) => {
  const dispatch = useDispatch();

  const selectedPreBuiltAudiencesSelector = useSelector( selectPreBuiltAudiencesByAudienceId( activeTab ) );
  const selectedPreBuiltAudiences = selectedPreBuiltAudiencesSelector || [];
  const prebuiltAudienceWizard = useSelector( selectWizardPrebuiltAudienceByTabName( activeTab ) );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );

  const { data: preBuiltAudiences, loading, error, refetch } = usePreBuiltAudienceNamesQuery( {
    ...options(),
    notifyOnNetworkStatusChange: true,
  } );
  const scrollRef = useRef<HTMLDivElement | null>( null );

  useEffect( () => {
    if ( !!error?.message ) {
      console.error(`Loading data error: ${error.message}`); //eslint-disable-line
    }
  }, [error] );

  useEffect( () => {
    if ( !loading || !!selectedPreBuiltAudiences ) {
      handleScrollIntoView( scrollRef );
    }
  }, [loading, selectedPreBuiltAudiences] );

  const handlePrebuiltAudienceButtonClick = ( id: number ) => {
    dispatch( setPrebuiltAudienceWizard( { activeTab, isFinished: false } ) );

    if ( selectedPreBuiltAudiences.includes( id ) ) {
      dispatch( setPreBuiltAudienceIds( [activeTab, selectedPreBuiltAudiences.filter( ( audience ) => audience !== id )] ) );

      return;
    }

    dispatch( setPreBuiltAudienceIds( [activeTab, [...selectedPreBuiltAudiences, id]] ) );
  };

  const handlePrebuiltAudienceNextClick = () => {
    dispatch(
      setPrebuiltAudienceWizard( {
        isFinished: true,
        isExpand:   false,
        activeTab,
      } ),
    );
    dispatch( collapseAllWizards( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  useEffect( () => {
    if ( !prebuiltAudienceWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [prebuiltAudienceWizard.isFinished] );

  useEffect( () => {
    if ( !isEmpty( appliedAudienceToCompare ) && isEqualComparisons && !!preBuiltAudiences ) {
      const audience = audiencesToCompare[activeTab];
      if ( !!audience.preBuiltAudienceIds ) {
        dispatch(
          setPrebuiltAudienceWizard( {
            activeTab,
            isFinished: true,
          } ),
        );
        dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
      }
    }
  }, [preBuiltAudiences] );

  const collapseItems = [
    {
      key:   WIZARD_NAME.PREBUILT_AUDIENCE,
      label:  <CollapseHeader
        step={WIZARD_NAME.PREBUILT_AUDIENCE}
        heading={`Step 02: ${STEP_NAMES.PREBUILT_AUDIENCE}`}
        caption='Select audiences curated by data scientists'
        tags={[{ name:  TAG_VALUES.PREBUILT_AUDIENCE, value: selectedPreBuiltAudiences
          .map( ( audience ) => preBuiltAudiences?.preBuiltAudienceNames.find( ( a ) => a.id === audience )?.name || '' ) }]}
      />,
      children: <>
        <Space className='blade-buttons' wrap style={{ width: 640 }}>
          {preBuiltAudiences?.preBuiltAudienceNames.map( ( item ) => {
            const isSelected = selectedPreBuiltAudiences.includes( item.id );
            return (
              <Button
                key={item.name}
                className={cn( { selected: isSelected } )}
                icon={isSelected ? <CheckOutlined /> : ''}
                onClick={() => handlePrebuiltAudienceButtonClick( item.id )}
              >
                {item.name}
              </Button>
            );
          } )}
        </Space>
        <div ref={scrollRef}>
          {selectedPreBuiltAudiences.length !== 0 && !prebuiltAudienceWizard.isLoading && (
            <>
              <Divider style={{ margin: '1rem 0 1rem' }} />
              <div className='next-button'>
                <Button onClick={handlePrebuiltAudienceNextClick}>Finish Wizard</Button>
              </div>
            </>
          )}
        </div>
      </>,
    },
  ];

  return !!error?.message
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          refetch();
        }}
      />
    )
    : loading || !preBuiltAudiences?.preBuiltAudienceNames.length
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          items={collapseItems}
          activeKey={prebuiltAudienceWizard.isExpand ? WIZARD_NAME.PREBUILT_AUDIENCE : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setPrebuiltAudienceWizard, prebuiltAudienceWizard )}
          expandIcon={() =>
            prebuiltAudienceWizard.isExpand
              ? (
                <MinusOutlined style={expandIconStyles} />
              )
              : (
                <PlusOutlined style={expandIconStyles} />
              )
          }
        />
      );
};

export default PrebuiltAudienceCollapse;
