import './AttributeContent.scss';

import { DownloadOutlined, DownOutlined, UpOutlined } from '@ant-design/icons';
import { Badge, Button, Col, Flex, Popover, Row } from 'antd';
import cn from 'classnames';
import * as htmlToImage from 'html-to-image';
import { useContext, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';

import { Attribute, IncomingScenario } from '../../../../apollo/graphql-types';
import { ReactComponent as EmptyIcon } from '../../../../assets/icons/empty.svg';
import { selectAppliedScenario, selectAudienceComparisonBenchmarkId } from '../../../../store/audienceComparison/selectors';
import { AUDIENCE_ID, NATIONAL_REFERENCE, TAB_HEADERS } from '../../../Blade/collapse/const';
import { TextPopover } from '../../../TextPopover/TextPopover';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import { AudienceNamesContext } from '../../FilledAudienceComparison';
import { EView } from '../../typings';
import { ShowSwitcherContext } from '../AttributesComparison';
import { colorPallette } from '../BarChartDiagram/barChartConfig';
import { BarChartDiagram } from '../BarChartDiagram/BarChartDiagram';
import { FavouriteAttribute } from './FavouriteAttribute';

interface IAttributeContentProps {
  attribute: Attribute;
  view: EView;
  itemsCount: number;
  selectedTab: string;
  successMessage: ( content: React.ReactNode ) => void;
  errorMessage: () => void;
}

export const AttributeContent = ( { attribute, view, itemsCount, selectedTab, successMessage, errorMessage }: IAttributeContentProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const titleRef = useRef<HTMLDivElement>( null );
  const comparisonLegendRef = useRef<HTMLDivElement>( null );
  const parentDiagramRef = useRef<HTMLDivElement>( null );
  const [showAllValues, setShowAllValues] = useState( false );

  const benchmarkSegmentId = useSelector( selectAudienceComparisonBenchmarkId );
  const showSwitcher = useContext( ShowSwitcherContext );
  const scenario = useSelector( selectAppliedScenario );

  const segmentsCount = attribute.attributeValues?.[0]?.segmentValues.length;
  const attributeLowCoverage = segmentsCount === attribute.lowCoverage?.length
  && attribute.lowCoverage?.every( ( lowCoverage ) => lowCoverage.coverageValue === 0 );
  const hasMore = ( attribute?.attributeValues.length || 0 ) > 5 && !attributeLowCoverage;

  const filter = ( node: HTMLElement ) => {
    const exclusionClasses = ['show-more', 'download-button', 'anticon-info-circle', 'show-all-labels', 'favourite-button'];
    return !exclusionClasses.some( ( classname ) => node.classList?.contains( classname ) );
  };

  const handleExportDiagram = () => {
    if ( !!parentDiagramRef.current ) {
      htmlToImage.toJpeg( parentDiagramRef.current, { backgroundColor: '#ffffff', filter: filter } )
        .then( function ( dataUrl ) {
          const link = document.createElement( 'a' );
          link.download = `CDS_${attribute.attributeName}.jpeg`;
          link.href = dataUrl;
          link.click();
        } );
    }
  };

  useEffect( () => {
    if ( showAllValues ) {
      setShowAllValues( false );
    }
  }, [selectedTab] );

  return <Col
    key={attribute?.attributeName}
    style={{
      '--values-height':  `${parentDiagramRef.current?.scrollHeight}px`,
      '--headers-height': `${( titleRef.current?.scrollHeight || 0 ) + ( comparisonLegendRef.current?.scrollHeight || 0 )}px`,
    } as React.CSSProperties
    }
    className={cn( 'attribute-content', {
      expanded:     showAllValues,
      'has-more':   hasMore,
      'grid-view':       view === EView.grid,
      even:        itemsCount % 2 === 0,
    } )}
    span={view === EView.grid ? 12 : 24}
  >
    <div className='attribute-content-wrapper'>
      <div ref={parentDiagramRef} className='attribute-content-inner'>
        <Row gutter={24}>
          <Col span={16} className='attribute-title' ref={titleRef}>
            <TitleWithCaption headingLevel={5} heading={ attribute.attributeName } />
          </Col>
          <Col span={8} className='attribute-content-options'>
            <Flex justify='end' align='flex-start' gap={4}>
              <FavouriteAttribute attribute={attribute} successMessage={successMessage} errorMessage={errorMessage} />
              {
                ( !attributeLowCoverage )
              && <Button className='download-button' icon={<DownloadOutlined />} type='link' onClick={handleExportDiagram}>Download</Button>
              }
            </Flex>
          </Col>

          {!attributeLowCoverage && (
            <Col span={24}>
              <div className='bar-chart-legend'>
                {
                  attribute.attributeValues[0].segmentValues
                    .filter( ( segment ) => !showSwitcher.showNationalReference ? segment.segmentId !== benchmarkSegmentId : true )
                    .map( ( segment, index ) => {
                      const lowCoverage = attribute.lowCoverage
                        ?.find( ( lowCoverage ) => lowCoverage.coverageValue !== 0 && lowCoverage.segmentId === segment.segmentId );

                      return (
                        <span
                          key={`${attribute.attributeName}-${index}}`}
                          className={cn( 'legend-color' )}
                          style={{
                            '--legend-color':  colorPallette[showSwitcher.showNationalReference ? index : index + 1],
                          } as React.CSSProperties}
                        >
                          {
                            [IncomingScenario.Exclusive, IncomingScenario.Overlap].includes( scenario )
          && segment.segmentId !== benchmarkSegmentId
                              ? (
                                <div className='name'>
                                  <Popover
                                    getPopupContainer={( node ) => node.parentElement!}
                                    content={ Object.entries( audienceNames ).map( ( [key, name] ) => (
                                      <p key={name}>{TAB_HEADERS[key as AUDIENCE_ID]}: <span>{name}</span></p>
                                    ) )}
                                    placement='bottom'
                                    arrow={false}
                                    overlayClassName='names-popover'
                                  >{scenario === IncomingScenario.Exclusive ? 'Exclusive ' : 'Overlap '}
                                    {Object.keys( audienceNames ).length} Audiences
                                  </Popover>
                                </div>
                              )
                              : (
                                <TextPopover
                                  text={scenario !== IncomingScenario.Compare && showSwitcher.showNationalReference
                                    ? segment.segmentId === benchmarkSegmentId
                                      ? NATIONAL_REFERENCE
                                      : Object.values( audienceNames )[index - 1]
                                    : Object.values( audienceNames )[index]
                                  }
                                  className='name'
                                />
                              )
                          }
                          { lowCoverage && (
                            <Popover
                              arrow={false}
                              placement='bottom'
                              content={<><Badge status='error' /> Low data coverage: {lowCoverage.coverageValue}%</>}
                            >
                              <Badge status='error' />
                            </Popover>
                          )}
                        </span>
                      );
                    } )
                }
              </div>
            </Col>
          )}
          <Col span={24}>
            <div className='diagram-content-wrapper'>
              {
                !attributeLowCoverage
                  ? <BarChartDiagram attributes={attribute?.attributeValues || []} />
                  : (
                    <Flex className='empty low-coverage'>
                      <EmptyIcon />
                      <p className='text'>Attribute is not present for selected audiences</p>
                    </Flex>
                  )
              }
            </div>
          </Col>
          {
            hasMore && (
              <div className='show-more'>
                <Button onClick={() => setShowAllValues( !showAllValues )}>
                  {showAllValues
                    ? <>Show Less Values <UpOutlined /></>
                    : <>Show All Values <DownOutlined /></>}
                </Button>
              </div>
            )
          }
        </Row>
      </div>
    </div>
  </Col>;
};
