import './ScenarioSelectionWindow.scss';

import { MergeCellsOutlined, UserOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Button, Flex, Modal } from 'antd';
import { useState } from 'react';

import { IncomingScenario } from '../../../apollo/graphql-types';
import { ReactComponent as BubblesExclusive } from '../../../assets/icons/bubbles-exclusive.svg';
import { ReactComponent as BubblesOverlap } from '../../../assets/icons/bubbles-overlap.svg';
import { ScenarioGroupCard } from './ScenarioGroupCard';

export const SCENARIO_TYPE_TO_NAME = {
  [IncomingScenario.Compare]:    'Compare',
  [IncomingScenario.Overlap]:    'Overlap',
  [IncomingScenario.Exclusive]:  'Exclusive (Audience 1)',
  [IncomingScenario.Profile]:     'Single Audience',
};

export const ScenarioSelectionWindow = () => {
  const [isModalOpen, setIsModalOpen] = useState( false );
  const showModal = () => {
    setIsModalOpen( true );
  };

  const handleCancel = () => {
    setIsModalOpen( false );
  };

  return (
    <div className='scenario-selection-window'>
      <Button type='primary' onClick={() => showModal()}>Start Audience Analysis</Button>

      <Modal
        width={900}
        footer={false}
        title='Audience Analysis'
        open={isModalOpen}
        onCancel={handleCancel}
        maskClosable={false}
      >
        <p>Choose a scenario which fits your use case. You can always change it later.</p>

        <div className='scenario-group'>
          <p className='scenario-group-title'>Comparison Scenarios</p>
          <ScenarioGroupCard
            title='Compare Multiple Audiences'
            description='Compare two or more audiences profile at the same time'
            scenario={IncomingScenario.Compare}
            icon={<MergeCellsOutlined />}
          />
        </div>

        <div className='scenario-group scenario-group-multiple'>
          <p className='scenario-group-title'>Single Audience Scenarios</p>
          <Flex gap={16}>
            <ScenarioGroupCard
              title='Profile Single Audience'
              description='Get a detailed profile of the single audience'
              scenario={IncomingScenario.Profile}
              icon={<UserOutlined />}
            />
            <ScenarioGroupCard
              title='Overlap between Audiences'
              description='Select two or more audiences to identify and gather insights about the overlapping segment among them'
              scenario={IncomingScenario.Overlap}
              icon={<Icon component={BubblesOverlap} />}
            />

            <ScenarioGroupCard
              title='Exclusive from Audiences'
              description='Select two audiences to identify and gather insights about distinct,
              non-overlapping segments within your chosen groups'
              scenario={IncomingScenario.Exclusive}
              icon={<Icon component={BubblesExclusive} />}
            />
          </Flex>
        </div>
      </Modal>
    </div>
  );
};

