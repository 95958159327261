import dayjs from 'dayjs';
import { sum, uniqBy } from 'lodash';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  D2C,
  MarketingCampaign,
  Origin,
  useBrandMarketingCampaignsLazyQuery,
  useFlavorsSizesLazyQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../apollo/graphql-types';
import { selectAppliedAudiencesToCompare } from '../../store/audienceComparison/selectors';
import { Entries } from '../../store/typings';
import { BuyerType } from '../../typings';
import {
  collectBrandsNames,
  collectManufacturerNames,
  getManufacturersByCategoryId,
  isMarsManufacturer,
} from '../../utils/brand.utils';
import { generateCampaignsNames } from '../../utils/names.utils';
import { buyerTypeToTagName, engagementToTagName } from '../Blade/collapse/AdditionalParametersCollapse/const';
import { calculateNewSizes } from '../Blade/collapse/AdditionalParametersCollapse/FlavorsAndSizes/utils';
import { dateFormat } from '../Blade/collapse/AdditionalParametersCollapse/SpecifyTimeFilter/SpecifyTimeFilter';
import {
  ALL_BRANDS,
  ALL_BUYER_TYPES,
  ALL_MANUFACTURERS,
  AUDIENCE_ID,
  COMPETITORS,
  getSortedBrandList,
  MARS_BRANDS,
  options,
  PREBUILT_AUDIENCES,
} from '../Blade/collapse/const';
import { partyData, webLinks } from './const';

export const getBuyerTypeName = ( buyerTypes: BuyerType[] ) => {
  if ( !buyerTypes.length ) {
    return '';
  }
  if ( buyerTypes.length === 5 ) {
    return ALL_BUYER_TYPES;
  }

  const buyers = buyerTypes.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );

  if ( buyerTypes.length === 1 && buyerTypes[0] === BuyerType.NonBuyer ) {
    return buyers;
  }

  return buyers.concat( ' Buyer' );
};

export const useAudienceShortName = () => {
  const audiencesToCompare = useSelector( selectAppliedAudiencesToCompare );

  const [names, setNames] = useState<Record<AUDIENCE_ID, string>>(
    {
      [AUDIENCE_ID.AUDIENCE_A]: 'loading',
      [AUDIENCE_ID.AUDIENCE_B]: 'loading',
      [AUDIENCE_ID.AUDIENCE_C]: 'loading',
      [AUDIENCE_ID.AUDIENCE_D]: 'loading',
      [AUDIENCE_ID.AUDIENCE_E]: 'loading',
      [AUDIENCE_ID.AUDIENCE_F]: 'loading',
    }
  );

  const [loadPreBuiltAudiences] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadMarsBrands] = useGetBrandsLazyQuery( options() );
  const [loadCompetitors] = useGetBrandsLazyQuery( { ...options(), variables: { isCompetitor: true } } );
  const [loadCampaigns] = useBrandMarketingCampaignsLazyQuery( options() );
  const [loadFlavorsAndSizes] = useFlavorsSizesLazyQuery();

  const renderName = async () => {
    const audiences = ( Object.entries( audiencesToCompare ) as Entries<typeof audiencesToCompare> )
      .filter( ( [key, audience] ) => !!audience?.audienceParameters || !!audience?.preBuiltAudienceIds );

    const namesToShow = await Promise.all( audiences.map( async ( [key, audience] ) => {
      const audienceParameters = audience.audienceParameters;
      const preBuiltAudienceIds = audience.preBuiltAudienceIds;

      if ( !!preBuiltAudienceIds ) {
        const preBuiltAudiences = await loadPreBuiltAudiences();
        const preBuildValues = preBuiltAudienceIds.map( ( id ) =>
          preBuiltAudiences.data?.preBuiltAudienceNames.find( ( audience ) => audience.id === id )?.name || 'loading' );

        return [
          key,
          `${PREBUILT_AUDIENCES} ${preBuildValues.join( preBuildValues.length < 2 ? '' : ', ' )}`,
        ];
      }

      if ( !!audienceParameters ) {
        const selectedBrands = audienceParameters.brands || [];
        const selectedSizes = audienceParameters.sizes;
        const selectedFlavors = audienceParameters.flavors;

        let sizesNames = {} as Record<string, string>;
        let sizesToShow: string | undefined = '';
        let flavorsToShow: string = '';

        if ( !!selectedBrands.length && ( selectedSizes?.length || selectedFlavors?.length ) ) {
          const availableFlavorsAndSizes = await loadFlavorsAndSizes( {
            ...options(),
            variables:                   {
              brandId: selectedBrands[0]?.toString(),
            },
          } );

          if ( !!availableFlavorsAndSizes.data?.flavorsSizes ) {
            const flavors = availableFlavorsAndSizes.data?.flavorsSizes.flavors;
            const flavorGroups = availableFlavorsAndSizes.data?.flavorsSizes.groups;

            if ( !flavorGroups?.length ) {
              flavorsToShow = flavors?.length === selectedFlavors?.length && ( selectedFlavors?.length || 0 ) > 1
                ? 'All Flavours'
                : ( selectedFlavors ?? [] ).join( ', ' );
            } else {
              const allFlavoursCount = ( flavors?.length || 0 )
              + sum( flavorGroups.map( ( group ) => group.flavors.length ) )
              + flavorGroups.length;

              const someFlavoursSelected = flavorGroups.map( ( group ) => {
                if ( selectedFlavors?.includes( group.flavorGroupName || '' ) && group.flavors.length > 1 ) {
                  return `All Group ${group.flavorGroupName} Flavours`;
                }

                return group.flavors
                  .filter( ( flavor ) => selectedFlavors?.includes( flavor.flavorName || '' ) )
                  .map( ( flavor ) => flavor.flavorName )
                  .join( ', ' );
              } )
                .filter( ( group ) => group.length );


              flavorsToShow = allFlavoursCount === selectedFlavors?.length && selectedFlavors.length > 1
                ? 'All Flavours'
                : someFlavoursSelected.join( ', ' );
            }

            const flavorsList = flatten( [
              ...( flatten( availableFlavorsAndSizes?.data?.flavorsSizes.groups?.map( ( group )=> group.flavors ) ) || [] ),
              ...( availableFlavorsAndSizes?.data?.flavorsSizes.flavors || [] ),
            ] );

            const names = fromPairs(
              uniqBy(
                flatten(
                  flavorsList.map( ( flavor ) => flavor.sizes || [] ) ),
                'sizeIdentifier'
              ).map( ( size ) => [size.sizeIdentifier, size.sizeName] )
            );

            sizesNames = names;
            const calculatedSizes = calculateNewSizes( availableFlavorsAndSizes.data?.flavorsSizes, selectedFlavors );

            sizesToShow = calculatedSizes.length === selectedSizes?.length && selectedSizes.length > 1
              ? 'All Package Sizes/Weights'
              : calculatedSizes
                .filter( ( size ) => selectedSizes?.includes( size.sizeIdentifier ) )
                .map( ( size ) => sizesNames[size.sizeIdentifier] ).join( ', ' );
          }
        }

        if ( !isMarsManufacturer( audience.audienceParameters?.manufacturers ) ) {
          const competitorsData = await loadCompetitors();
          const competitors = competitorsData.data?.categorizedBrands;
          const competitorsManufacturerNames = collectManufacturerNames( competitors || [] );
          const manufacturersList = getManufacturersByCategoryId( audienceParameters.category, competitors ) || [];
          const competitorsBrandNames = collectBrandsNames( competitors || [] );
          const availableBrands = flatten( competitors
            ?.find( ( category ) => category.categoryId === audienceParameters?.category )
            ?.manufacturers
            .filter( ( manufacturer ) => audienceParameters?.manufacturers.includes( manufacturer.manufacturerId ) )
            .map( ( manufacturer ) => manufacturer.brands ) );
          const selectedCategoryName = competitors
            ?.find( ( category ) => audienceParameters.category === category.categoryId )?.categoryName || '';
          const isAllBrandsSelected = availableBrands?.length === selectedBrands?.length;
          const isAllManufacturersSelected = manufacturersList?.length === audienceParameters?.manufacturers?.length;
          const SOME_MANUFACTURERS_CAPTION = audienceParameters.manufacturers
            ?.map( ( manufacturer ) => competitorsManufacturerNames[manufacturer] ).join( ', ' );
          const ALL_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION} ${ALL_BRANDS}`;
          const SOME_BRANDS_CAPTION = `${isAllManufacturersSelected
            ? ALL_MANUFACTURERS
            : SOME_MANUFACTURERS_CAPTION} ${getSortedBrandList( selectedBrands, availableBrands )
            ?.map( ( brand ) => competitorsBrandNames[brand] ).join( ', ' )
          }`;

          const brandTagValue = `${selectedCategoryName} ${isAllBrandsSelected && availableBrands.length > 1
            ? [ALL_BRANDS_CAPTION]
            : SOME_BRANDS_CAPTION
          }`;

          const fromDate = audienceParameters.fromDate;
          const toDate = audienceParameters.toDate;
          const date = !!fromDate && !!toDate ? `${dayjs( fromDate ).format( dateFormat )}-${dayjs( toDate ).format( dateFormat )}` : '';

          return [
            key,
            `${COMPETITORS} ${brandTagValue} ${getBuyerTypeName( audienceParameters.buyerTypes || [] )}${!!date ? ' ' + date : ''}${!!flavorsToShow ? ' ' + flavorsToShow : '' }${ !!sizesToShow ? ' ' + sizesToShow : ''}`, // eslint-disable-line
          ];
        } else {
          const originValue = audienceParameters?.origin;

          const marsBrandsData = await loadMarsBrands();
          const marsBrands = marsBrandsData.data?.categorizedBrands;
          const marsBrandNames = collectBrandsNames( marsBrands || [] );
          const selectedCampaigns = audienceParameters?.marketingCampaigns;
          const fromDate = audienceParameters.fromDate;
          const toDate = audienceParameters.toDate;
          const date = !!fromDate && !!toDate ? `${dayjs( fromDate ).format( dateFormat )}-${dayjs( toDate ).format( dateFormat )}` : '';
          let campaignsToShow = '';
          let campaigns: D2C | null | undefined = null;
          let campaignsList: MarketingCampaign[] = [];
          const engagement = !!audienceParameters?.engagementStatus ? engagementToTagName[audienceParameters.engagementStatus] : '';
          const d2c = audienceParameters?.d2c;
          const origin = !!originValue && partyData[originValue];
          const category = audienceParameters?.category;
          const selectedCategoryName = marsBrands
            ?.find( ( categoryItem ) => category === categoryItem.categoryId )?.categoryName || '';
          const categorizedBrandsByCategory = marsBrands
            ?.find( ( categoryItem ) => categoryItem.categoryId === category )?.manufacturers[0].brands;

          if ( originValue === Origin.FirstParty ) {
            const brandsToLoad = selectedBrands.map( ( brandId ) => {
              const name = marsBrandNames[brandId];
              return {
                id: brandId,
                name,
              };
            } );

            const campaignsData = await loadCampaigns( { variables: { brands: brandsToLoad || [] } } );
            campaigns = campaignsData.data?.brandMarketingCampaigns;
            campaignsList = flatten( campaigns?.campaigns?.map( ( affinity ) => affinity.marketingCampaigns ) );

            campaignsToShow = campaignsList.length === selectedCampaigns?.length && selectedCampaigns?.length > 1
              ? 'All Campaigns'
              : generateCampaignsNames( campaignsList, selectedCampaigns, campaignsData.data );
          }

          const d2cList = flatten( campaigns?.d2cCampaigns?.map( ( affinity ) => affinity.marketingCampaigns ) );

          let brandNamesToShow = selectedBrands
            ?.filter( ( brand ) => {
              if ( selectedCampaigns?.length || 0 > 0 ) {
                return !!selectedCampaigns?.map( ( selectedCampaign ) =>
                  campaignsList.find( ( c ) => c.id === selectedCampaign && c.brandName === marsBrandNames?.[brand] ) )
                  .filter( ( item ) => !!item ).length;
              } else if ( !!d2c ) {
                return !!webLinks[brand];
              } else if ( originValue === Origin.FirstParty ) {
                const hasCampaigns = !!campaignsList.find( ( c ) => c.brandName === marsBrandNames?.[brand] );
                const hasD2C = !!d2cList.find( ( c ) => c.brandName === marsBrandNames?.[brand] );

                return hasCampaigns || hasD2C;
              }

              return true;
            } )
            .map( ( brand ) => !!d2c ? webLinks[brand] : marsBrandNames?.[brand] )
            .join( ', ' );

          brandNamesToShow = brandNamesToShow?.split( ',' ).length === categorizedBrandsByCategory?.length
            ? `${selectedCategoryName} ${ALL_BRANDS}`
            : `${selectedCategoryName} ${brandNamesToShow}`;

          return [
            key,
            `${MARS_BRANDS} ${brandNamesToShow}${!!campaignsToShow?.length ? ' ' + campaignsToShow + ' ' + engagement : !!origin && !d2c && !selectedCampaigns ? ' ' + origin : ''}${!!audienceParameters.buyerTypes ? ' ' + getBuyerTypeName( audienceParameters.buyerTypes || [] ) : ''}${!!date ? ' ' + date : ''}${!!flavorsToShow ? ' ' + flavorsToShow : '' }${ !!sizesToShow ? ' ' + sizesToShow : ''}`.trim() // eslint-disable-line
          ];
        }
      }
    } ) );

    // @ts-ignore
    return setNames( fromPairs( namesToShow ) );
  };

  useEffect( () => {
    renderName();
  }, [audiencesToCompare] );

  return names;
};
