import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

import { COMPETITORS, MARS_BRANDS, PREBUILT_AUDIENCES } from '../const';

export const SOURCE_SUFFIX: { [key: string]: string } = {
  [MARS_BRANDS]:        'Brand',
  [PREBUILT_AUDIENCES]: 'Prebuilt Audience',
  [COMPETITORS]:        'Competitor Brands',
};

export const sourceOptions = ( activeButton: string ) => [
  { label: MARS_BRANDS, value: MARS_BRANDS },
  {
    label: (
      <div className='prebuilt-audiences-button'>
        <div>{PREBUILT_AUDIENCES}</div>
        <Popover content='Audiences created by Data Science Team' trigger='hover' placement='bottom'>
          <InfoCircleOutlined
            style={activeButton !== PREBUILT_AUDIENCES ? { color: 'rgba(0, 0, 0, 0.45)' } : { color: '#fff' }}
          />
        </Popover>
      </div>
    ),
    value: PREBUILT_AUDIENCES,
  },
  { label: COMPETITORS, value: COMPETITORS },
];
