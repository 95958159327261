import { DownOutlined, MergeCellsOutlined, UserOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { Dropdown, Modal } from 'antd';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IncomingScenario } from '../../../apollo/graphql-types';
import { ReactComponent as BubblesExclusive } from '../../../assets/icons/bubbles-exclusive.svg';
import { ReactComponent as BubblesOverlap } from '../../../assets/icons/bubbles-overlap.svg';
import {
  setAudienceParameters,
  setAudiencesToCompare,
  setScenario,
  setSingleAudienceById,
} from '../../../store/audienceComparison/actions';
import { selectAudiencesToCompare, selectScenario } from '../../../store/audienceComparison/selectors';
import { IAudienceToCompare } from '../../../store/audienceComparison/typings';
import { setActiveTab, setExclusiveTabs, setSpecificTab } from '../../../store/tab/actions';
import { resetWizzardById, setSingleWizzardById, setWizzardForExclusiveScenario } from '../../../store/wizzard/actions';
import { SCENARIO_TYPE_TO_NAME } from '../../AudienceComparisonEmpty/ScenarioSelectionWindow/ScenarioSelectionWindow';
import { AUDIENCE_ID, TAB_HEADERS } from '../collapse/const';

export const ScenarioSwitcher = () => {
  const dispatch = useDispatch();
  const selectedScenario = useSelector( selectScenario );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const audiencesLength = Object.keys( audiencesToCompare ).length;

  const [isModalOpen, setIsModalOpen] = useState( false );
  const [modalType, setModalType] = useState<IncomingScenario.Profile | IncomingScenario.Exclusive>( IncomingScenario.Profile );
  const [selectedAudience, setSelectedAudience] = useState<null | AUDIENCE_ID>( null );

  const handleScenarioChange = ( selectedScenario: IncomingScenario, key?: AUDIENCE_ID ) => {
    if ( !!key ) {
      setSelectedAudience( key );
    }

    if ( ( selectedScenario === IncomingScenario.Profile && audiencesLength > 1 )
      || ( selectedScenario === IncomingScenario.Exclusive && audiencesLength > 2 ) ) {
      setIsModalOpen( true );
      setModalType( selectedScenario );

      return;
    }

    if ( selectedScenario !== IncomingScenario.Profile && audiencesLength === 1 ) {
      dispatch( setAudienceParameters( [AUDIENCE_ID.AUDIENCE_B, undefined] ) );
      dispatch( resetWizzardById( AUDIENCE_ID.AUDIENCE_B ) );
    }

    dispatch( setScenario( selectedScenario ) );
  };

  const handleOk = () => {
    if ( modalType === IncomingScenario.Profile && !!selectedAudience ){
      dispatch( setSingleAudienceById( selectedAudience ) );
      dispatch( setSingleWizzardById( selectedAudience ) );
    } else {
      dispatch( setWizzardForExclusiveScenario() );
      dispatch( setAudiencesToCompare( {
        audienceA: audiencesToCompare.audienceA, audienceB: audiencesToCompare.audienceB,
      } as Record<AUDIENCE_ID, IAudienceToCompare> )
      );
      dispatch( setExclusiveTabs() );
    }

    setIsModalOpen( false );
    dispatch( setScenario( modalType ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE_A ) );
  };

  const handleCancel = () => {
    setIsModalOpen( false );
    setSelectedAudience( null );
  };

  const getAudiencesToDeleteNames = () => Object.keys( audiencesToCompare )
    ?.slice( modalType === IncomingScenario.Profile ? 0 : 2 )
    ?.filter( ( id ) => modalType === IncomingScenario.Profile ? id !== selectedAudience : true )
    .map( ( id ) => TAB_HEADERS[id as AUDIENCE_ID ] )
    .join( ', ' );

  const items = [
    {
      key:   IncomingScenario.Compare,
      icon:  <MergeCellsOutlined />,
      label: (
        <a target='_blank' onClick={ () => handleScenarioChange( IncomingScenario.Compare )}>
          {SCENARIO_TYPE_TO_NAME[IncomingScenario.Compare]}
        </a>
      ),
    },
    {
      key:   IncomingScenario.Overlap,
      icon:  <Icon component={BubblesOverlap} />,
      label: (
        <a target='_blank' onClick={ () => handleScenarioChange( IncomingScenario.Overlap )}>
          {SCENARIO_TYPE_TO_NAME[IncomingScenario.Overlap]}
        </a>
      ),
    },
    {
      key:   'single',
      icon:  audiencesLength === 1 ? <UserOutlined /> : null,
      label: (
        <a
          target='_blank'
          onClick={ ( e ) => audiencesLength === 1 ? handleScenarioChange( IncomingScenario.Profile ) : e.preventDefault()}
        >
          {SCENARIO_TYPE_TO_NAME[IncomingScenario.Profile]}
        </a>
      ),
      children: audiencesLength === 1
        ? null
        : Object.entries( audiencesToCompare ).map( ( [key, audience], index ) => ( {
          key:   `single-${key}`,
          label:       (
            <a target='_blank' onClick={ () => handleScenarioChange( IncomingScenario.Profile, key as AUDIENCE_ID )}>
            From Audience {index + 1}
            </a>
          ),
          icon:  <UserOutlined />,
        } ) ),
    },
    {
      key:   IncomingScenario.Exclusive,
      icon:  <Icon component={BubblesExclusive} />,
      label: (
        <a target='_blank' onClick={ () => handleScenarioChange( IncomingScenario.Exclusive )}>
          {SCENARIO_TYPE_TO_NAME[IncomingScenario.Exclusive]}
        </a>
      ),
    },
  ];

  return <>
    <Dropdown
      overlayClassName='scenario-switcher'
      menu={{
        items,
      }}
    >
      <a onClick={( e ) => e.preventDefault()} className='scenario-switcher-summary'>
      Scenario: {SCENARIO_TYPE_TO_NAME[selectedScenario]}
        <DownOutlined />
      </a>
    </Dropdown>

    <Modal
      title={`Switch to the ${modalType === IncomingScenario.Profile ? 'single audience scenario' : 'Exclusive scenario'}`}
      open={isModalOpen}
      onOk={handleOk}
      onCancel={handleCancel}
      okText='Confirm'
      cancelText='Back to Wizard'
    >
      <p>You are about to remove {getAudiencesToDeleteNames()} and switch to the
        {modalType === IncomingScenario.Profile
          ? ' Single Audience scenario'
          : ' Exclusive Scenario: Audience 2 will be excluded from Audience 1'}
      </p>
    </Modal>
  </>;
};
