import { CheckOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import cn from 'classnames';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { MarketingCampaign } from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import { selectCampaignsByAudienceId } from '../../../../store/audienceComparison/selectors';
import { setAdditionalParametersWizard } from '../../../../store/wizzard/actions';
import { selectWizardAdditionalParametersByTabName } from '../../../../store/wizzard/selectors';
import { AUDIENCE_ID } from '../const';

interface IMarketingCampaingsContentProps {
  activeTab: AUDIENCE_ID;
  marketingCampaignsList: MarketingCampaign[] | null | undefined;
  affinityName: string;
}
export const MarketingCampaingsContent = ( { activeTab, marketingCampaignsList, affinityName }: IMarketingCampaingsContentProps ) => {
  const dispatch = useDispatch();
  const selectedCampaigns = useSelector( selectCampaignsByAudienceId( activeTab ) );
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( activeTab ) );

  const [showAll, setShowAll] = useState( false );

  const isAllSelected = marketingCampaignsList?.every( ( campaign ) => selectedCampaigns?.includes( campaign.id ) );

  const handleMarketingCampaignsButtonClick = ( id: number ) => {
    dispatch(
      setAdditionalParametersWizard( {
        ...additionalParametersWizard,
        activeTab,
        isFinished: false,
      } ),
    );

    let selectedCampaignsList: number[];
    if ( selectedCampaigns?.includes( id ) ) {
      selectedCampaignsList = selectedCampaigns.filter( ( item ) => item !== id );
    } else {
      selectedCampaignsList = [...( selectedCampaigns || [] ), id];
    }

    dispatch( setAudienceParameters( [activeTab, { marketingCampaigns: selectedCampaignsList }] ) );
  };

  const handleClickAllCampaigns = () => {
    dispatch(
      setAdditionalParametersWizard( {
        ...additionalParametersWizard,
        activeTab,
        isFinished: false,
      } ),
    );

    let selectedCampaignsList: number[];
    if ( isAllSelected ) {
      const campaingIds = marketingCampaignsList?.map( ( campaign ) => campaign.id );
      selectedCampaignsList = selectedCampaigns
        ?.filter( ( item ) => !campaingIds?.includes( item ) ) || [];
    } else {
      selectedCampaignsList = [...( selectedCampaigns || [] ), ...( marketingCampaignsList?.map( ( campaign ) => campaign.id ) || [] )];
    }

    dispatch( setAudienceParameters( [activeTab, { marketingCampaigns: selectedCampaignsList }] ) );
  };

  return <>
    {
      ( marketingCampaignsList?.length || 0 ) > 1
        ? (
          <Button
            className='all-button'
            type={isAllSelected ? 'primary' : 'default'}
            onClick={handleClickAllCampaigns}>
            All {affinityName} Campaigns
          </Button>
        )
        : null
    }
    <Space className='blade-buttons' wrap>
      {marketingCampaignsList?.slice( 0, showAll ? marketingCampaignsList.length : 3 )?.map( ( campaign ) => (
        <Button
          key={campaign.marketingCampaignName}
          className={cn( { selected: selectedCampaigns?.includes( campaign.id ) } )}
          icon={selectedCampaigns?.includes( campaign.id ) ? <CheckOutlined /> : ''}
          onClick={() => handleMarketingCampaignsButtonClick( campaign.id )}
        >
          {campaign.marketingCampaignName}
        </Button>
      ) )}
      {( marketingCampaignsList?.length || 0 ) > 3 && (
        <div className='link-button'>
          <Button type='link' onClick={() => setShowAll( !showAll )}>
          Show {showAll ? 'Less' : 'All'}
          </Button>
        </div>
      )}
    </Space>
  </>;
};
