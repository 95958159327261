import { Attribute, BarchartTab } from '../../../../apollo/graphql-types';


const calculateAttributes = ( attributes: Attribute[], targetTechName: string, remove: boolean ) => attributes?.map( ( attribute ) => {
  if ( targetTechName === attribute.identifierName ) {
    return {
      ...attribute,
      favorite: remove ? false : true,
    };
  }

  return attribute;
} );

export const generateFavorite = ( barcharts: BarchartTab[], targetTechName: string, remove: boolean ) => barcharts.map( ( category ) => {
  let attributes = category.attributes;
  if ( !!attributes ) {
    attributes = calculateAttributes( attributes, targetTechName, remove );
  }

  let groups = category.groups || [];

  if ( !!groups ) {
    groups = groups?.map( ( group ) => {
      const groupAttributes = calculateAttributes( group.attributes, targetTechName, remove );

      return {
        ...group,
        attributes: groupAttributes,
      };
    } );
  }

  return {
    ...category,
    attributes,
    groups:     [...groups],
  };
} );
