import { Typography } from 'antd';

import { ITitleWithCaptionProps } from './types';

const TitleWithCaption: React.FC<ITitleWithCaptionProps> = ( {
  heading,
  headingLevel = 5,
  headingProps,
  headingStyles,
  caption,
  captionFontSize = 14,
  captionProps,
} ) => (
  <>
    {heading && (
      <Typography.Title {...headingProps} style={{ margin: 0, ...headingStyles }} level={headingLevel}>
        {heading}
      </Typography.Title>
    )}
    {caption && (
      <div style={{ fontSize: captionFontSize }} {...captionProps}>
        {caption}
      </div>
    )}
  </>
);

export default TitleWithCaption;
