import { flatten, groupBy, map } from 'lodash';

import { FilterAttribute, FilterAttributeCategory, FilterAttributeGroup } from '../../../../apollo/graphql-types';

export const generateRequestLimits = ( limits: string[] ) => {
  const limitsResult = map(
    groupBy( limits.map( ( limit ) => limit.split( '---' ) ), ( [key] ) => key ),
    ( group, key ) => ( { attributeName: key, attributeValues: group.map( ( [, value] ) => value ) } )
  );

  return limitsResult.length ? limitsResult : undefined;
};

const mapAttributeValues = ( techNameId: string, attributeValues: string[] ) => attributeValues.map( ( attributeValue ) => ( {
  value:      `${techNameId}---${attributeValue}`,
  title:      attributeValue,
  selectable: false,
} ) );

export const generateTreeSelectData = ( data: FilterAttributeCategory[] | undefined ) => data?.map( ( category ) => {
  const attributes = flatten<FilterAttribute | FilterAttributeGroup>( [category.attributes || [], category.attributeGroups || []] );

  const attributesTree = attributes.map( ( attributeGroup ) => {
    let childrenAttributesGroup = 'attributeValues' in attributeGroup && attributeGroup.attributeValues?.length
      ? mapAttributeValues( attributeGroup.techNameId, attributeGroup.attributeValues )
      : undefined;

    const name = 'groupName' in attributeGroup ? attributeGroup.groupName : attributeGroup.attributeName;

    if ( 'attributes' in attributeGroup ) {
      childrenAttributesGroup = attributeGroup.attributes?.map( ( attribute ) => ( {
        value:      attribute.attributeName,
        title:      attribute.attributeName,
        children:   attribute.attributeValues?.length
          ? mapAttributeValues( attribute.techNameId, attribute.attributeValues )
          : undefined,
        selectable: false,
        checkable:  false,
      } ) ) ;
    }

    return {
      value:      'techNameId' in attributeGroup ? name : `GROUP - ${name}`,
      title:      name,
      children:   childrenAttributesGroup,
      selectable: false,
      checkable:  false,
    };
  } );

  return {
    children:   attributesTree.length ? attributesTree : undefined,
    value:      category.categoryName,
    title:      category.categoryName,
    selectable: false,
    checkable:  false,
  };
} );
