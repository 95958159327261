import './AdditionalParametersCollapse.scss';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, CheckboxOptionType, Collapse, Divider, Radio, RadioChangeEvent, Space } from 'antd';
import cn from 'classnames';
import dayjs from 'dayjs';
import { flatten, isEmpty, sortBy, sum } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Origin,
  useBrandMarketingCampaignsLazyQuery,
  useFlavorsSizesLazyQuery,
  useGetBrandsQuery,
} from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectBuyerTypesByAudienceId,
  selectCampaignsByAudienceId,
  selectCategoryByAudienceId,
  selectD2CByAudienceId,
  selectFlavorsByAudienceId,
  selectIsEqualComparisons,
  selectOriginByAudienceId,
  selectSizesByAudienceId,
  selectTimeFilterFromByAudienceId,
  selectTimeFilterToByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../store/tab/actions';
import { collapseAllWizards, setAdditionalParametersWizard } from '../../../../store/wizzard/actions';
import { selectSizesNames, selectWizardAdditionalParametersByTabName } from '../../../../store/wizzard/selectors';
import { BuyerType, DEFAULT_BUYER_TYPES } from '../../../../typings';
import { getBrandsByCategoryId, isMarsManufacturer } from '../../../../utils/brand.utils';
import { generateCampaignsNames } from '../../../../utils/names.utils';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader, { ITag } from '../CollapseHeader';
import { ALL_BUYER_TYPES, AUDIENCE_ID, options } from '../const';
import { ICollapseProps } from '../types';
import { AffinityList } from './AffinityList';
import { BuyerTypes } from './BuyerTypes/BuyerTypes';
import {
  buyerTypeToTagName,
  D2C_WEBSITE_DATA,
} from './const';
import { Flavors } from './FlavorsAndSizes/Flavors';
import { Sizes } from './FlavorsAndSizes/Sizes';
import { calculateNewSizes } from './FlavorsAndSizes/utils';
import { dateFormat, SpecifyTimeFilter } from './SpecifyTimeFilter/SpecifyTimeFilter';

interface IAdditionalParametersProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

enum EAdditionalType {
  buyerTypes = 'Buyer Types',
  marketingCampaigns = 'Marketing Campaigns',
  d2c = 'D2C Website Data',
}

const AdditionalParametersCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IAdditionalParametersProps ) => {
  const dispatch = useDispatch();
  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedOrigin = useSelector( selectOriginByAudienceId( activeTab ) );
  const selectedFlavors = useSelector( selectFlavorsByAudienceId( activeTab ) );
  const selectedSizes = useSelector( selectSizesByAudienceId( activeTab ) );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );

  const { data: marsBrandsData, error: errorMarsBrands } = useGetBrandsQuery(
    {
      ...options(),
      notifyOnNetworkStatusChange: true,
    }
  );
  const [loadCampaigns, {
    loading: loadingCampaigns,
    data: loadedCampaignsData,
    error: errorCampaigns,
  }] = useBrandMarketingCampaignsLazyQuery( { ...options(), notifyOnNetworkStatusChange: true } );

  const [loadFlavorsAndSizes, { data: availableFlavorsAndSizes, error, loading }] = useFlavorsSizesLazyQuery();

  useEffect( () => {
    const errorMessage = errorCampaigns?.message ?? errorMarsBrands?.message;

    if ( !!errorMessage ) {
      console.error(`Loading data error: ${errorMessage}`); //eslint-disable-line
    }
  }, [errorCampaigns, errorMarsBrands] );

  const campaignsData = loadedCampaignsData?.brandMarketingCampaigns;

  const hasD2C = !!campaignsData?.d2cCampaigns?.length;
  const hasMarketingCampaigns = !!campaignsData?.campaigns?.length;
  const isSelectedOnlyD2C = campaignsData?.d2cCampaigns?.length
  && !campaignsData.campaigns?.length;

  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );
  const sizesName = useSelector( selectSizesNames );

  const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData?.categorizedBrands || [] );
  const selectedBuyerTypesSelector = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBuyerTypes = selectedBuyerTypesSelector || [];

  const selectedD2C = useSelector( selectD2CByAudienceId( activeTab ) );
  const selectedCampaigns = useSelector( selectCampaignsByAudienceId( activeTab ) );
  const additionalParametersWizard = useSelector( selectWizardAdditionalParametersByTabName( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );
  const isAllTypesSelected = selectedBuyerTypes.length === Object.values( BuyerType ).length;
  const selectedFromDate = useSelector( selectTimeFilterFromByAudienceId( activeTab ) );
  const selectedToDate = useSelector( selectTimeFilterToByAudienceId( activeTab ) );

  const firstPartyBrands = availableBrands?.filter( ( brand ) => brand.firstParty && selectedBrands?.includes( brand.brandId ) );
  const thirdPartyBrands = availableBrands?.filter( ( brand ) => brand.thirdParty && selectedBrands?.includes( brand.brandId ) );

  const isOnlyOneParty = ( isEmpty( firstPartyBrands ) && !isEmpty( thirdPartyBrands ) )
  || ( !isEmpty( firstPartyBrands ) && isEmpty( thirdPartyBrands ) );

  const getDefaultType = () => {
    if ( selectedCampaigns?.length || selectedD2C ) {
      return selectedCampaigns?.length
        ? EAdditionalType.marketingCampaigns
        : EAdditionalType.d2c;
    }

    if ( isOnlyOneParty && !!firstPartyBrands?.length ) {
      return campaignsData?.campaigns ? EAdditionalType.marketingCampaigns : EAdditionalType.d2c;
    }

    return EAdditionalType.buyerTypes;
  };

  const [selectedType, setSelectedType] = useState<EAdditionalType>( getDefaultType() );

  const campaignsList = flatten(
    campaignsData?.campaigns?.map( ( affinity ) => affinity.marketingCampaigns )
  );

  // const isEngagement = !!selectedCampaigns?.filter( ( campaignId ) =>
  //   campaignsList?.find( ( c ) =>
  //     c.id === campaignId )?.isEmail
  // )?.length;

  const collectTagValues = () => {
    let tagValue: ITag[] = [];

    if ( selectedType === EAdditionalType.marketingCampaigns ) {
      tagValue = [
        { name: 'Collected Through', value: EAdditionalType.marketingCampaigns },
        {
          name:  'Campaigns',
          value: generateCampaignsNames( campaignsList, selectedCampaigns, loadedCampaignsData ),
        },
      ];

      // if ( isEngagement && !!selectedEngagement ) {
      //   tagValue.push( { name: ENGAGEMENT, value: engagementToTagName[selectedEngagement] } );
      // }
    }

    if ( selectedType === EAdditionalType.d2c ) {
      tagValue = [{ name: TAG_VALUES.COLLECTED_THROUGH, value: EAdditionalType.d2c } ];
    }

    if ( selectedType === EAdditionalType.buyerTypes ) {
      if ( !!selectedBuyerTypes.length ) {
        tagValue.push( {
          name:  TAG_VALUES.BUYER_TYPE,
          value: isAllTypesSelected ? ALL_BUYER_TYPES : selectedBuyerTypes.map( ( bt ) => buyerTypeToTagName[bt] ),
        } );
      }

      if ( !!selectedFromDate && !!selectedToDate ) {
        tagValue.push( {
          name:  TAG_VALUES.PURCHASE_TIME_FRAME,
          value: `${dayjs( selectedFromDate ).format( dateFormat )}-${dayjs( selectedToDate ).format( dateFormat )}`,
        } );
      }

      if ( !!selectedFlavors?.length ) {
        const flavors = availableFlavorsAndSizes?.flavorsSizes.flavors;
        const flavorGroups = availableFlavorsAndSizes?.flavorsSizes.groups;

        if ( !flavorGroups?.length ) {

          tagValue.push( {
            name:  TAG_VALUES.FLAVOURS,
            value: flavors?.length === selectedFlavors.length && selectedFlavors.length > 1 ? 'All Flavours' : selectedFlavors,
          } );
        } else {
          const allFlavoursCount = ( flavors?.length || 0 )
          + sum( flavorGroups.map( ( group ) => group.flavors.length ) )
          + flavorGroups.length;

          const someFlavoursSelected = flavorGroups.map( ( group ) => {
            if ( selectedFlavors.includes( group.flavorGroupName || '' ) && group.flavors.length > 1 ) {
              return `All Group ${group.flavorGroupName} Flavours`;
            }

            return group.flavors
              .filter( ( flavor ) => selectedFlavors.includes( flavor.flavorName || '' ) )
              .map( ( flavor ) => flavor.flavorName )
              .join( ', ' );
          } )
            .filter( ( group ) => group.length );

          tagValue.push( {
            name:  TAG_VALUES.FLAVOURS,
            value: allFlavoursCount === selectedFlavors.length && selectedFlavors.length > 1 ? 'All Flavours' : someFlavoursSelected,
          } );
        }
      }

      if ( !!selectedSizes?.length ) {
        const newSizes = calculateNewSizes( availableFlavorsAndSizes?.flavorsSizes, selectedFlavors );
        tagValue.push( {
          name:  TAG_VALUES.SIZES,
          value: newSizes.length === selectedSizes.length && selectedSizes.length > 1
            ? 'All Package Sizes/Weights'
            : newSizes
              .filter( ( size ) => selectedSizes.includes( size.sizeIdentifier ) )
              .map( ( size ) => sizesName[size.sizeIdentifier] ),
        } );
      }
    }

    return tagValue;
  };

  useEffect( () => {
    loadCampaigns( {
      variables: {
        brands: sortBy( availableBrands
          ?.filter( ( brand ) => selectedBrands.includes( brand.brandId ) )
          .map( ( brand ) => ( { id: brand.brandId, name: brand.brandName } ) ), 'id' ),
      },
    } );
  }, [marsBrandsData] );

  useEffect( () => {
    if ( !availableFlavorsAndSizes && ( !!selectedFlavors?.length || !!selectedSizes?.length ) ) {
      loadFlavorsAndSizes( {
        ...options(),
        notifyOnNetworkStatusChange: true,
        variables:                   {
          brandId: selectedBrands[0].toString(),
        },
      } );
    }
  }, [selectedFlavors, selectedSizes] );

  const handleAdditionalParametersRadioGroupChange = ( e: RadioChangeEvent ) => {
    setSelectedType( e.target.value );
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: false,
      } ),
    );

    const parameters = {
      marketingCampaigns: e.target.value === EAdditionalType.marketingCampaigns ? ( selectedCampaigns || [] ) : undefined,
      // engagementStatus:   e.target.value === EAdditionalType.marketingCampaigns ? selectedEngagement : undefined,
      d2c:                e.target.value === EAdditionalType.d2c ? true : undefined,
      buyerTypes:         e.target.value === EAdditionalType.buyerTypes ? DEFAULT_BUYER_TYPES : undefined,
      flavors:            e.target.value === EAdditionalType.buyerTypes ? selectedFlavors : undefined,
      sizes:              e.target.value === EAdditionalType.buyerTypes ? selectedSizes : undefined,
      toDate:             e.target.value === EAdditionalType.buyerTypes ? selectedToDate : undefined,
      fromDate:           e.target.value === EAdditionalType.buyerTypes ? selectedFromDate : undefined,
    };

    dispatch( setAudienceParameters( [activeTab, parameters] ) );
  };

  const handleFinishWizardClick = ( parameter: string ) => {
    dispatch(
      setAdditionalParametersWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );

    dispatch( collapseAllWizards( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  // useEffect( () => {
  //   if ( !!marketingCampaignsList && isEngagement && !selectedEngagement && !isEqualComparisons ) {
  //     dispatch( setAudienceParameters( [activeTab, { engagementStatus: EngagementStatus.Reached }] ) );
  //   }

  //   if ( !!marketingCampaignsList && !isEngagement && !!selectedEngagement && !isEqualComparisons ) {
  //     dispatch( setAudienceParameters( [activeTab, { engagementStatus: undefined }] ) );
  //   }
  // }, [isEngagement] );

  useEffect( () => {
    if ( !additionalParametersWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [additionalParametersWizard.isFinished] );

  useEffect( () => {
    const prepareCollapse = () => {
      const audience = audiencesToCompare[activeTab];

      const isMarsBrandsSource = isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isMarsBrandsSource && (
        !!audience.audienceParameters?.d2c
        || !!audience.audienceParameters?.marketingCampaigns
        || !!audience.audienceParameters?.buyerTypes
        || !!audience.audienceParameters?.flavors
        || !!audience.audienceParameters?.sizes
        || ( !!selectedToDate && !!selectedFromDate )
      ) ) {
        dispatch(
          setAdditionalParametersWizard( {
            activeTab,
            isFinished: true,
            isExpand:   false,
          } ),
        );
        dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
      }
    };

    if ( !isEmpty( appliedAudienceToCompare ) && isEqualComparisons ) {
      prepareCollapse();
    }
  }, [] );

  useEffect( () => {
    if ( isSelectedOnlyD2C && !thirdPartyBrands?.length ) {
      dispatch( setAudienceParameters( [activeTab, { d2c: true }] ) );
    }
  }, [isSelectedOnlyD2C] );


  const generateOptions = () => {
    const options = [] as CheckboxOptionType[];

    if ( selectedOrigin !== Origin.FirstParty || !isOnlyOneParty ) {
      options.push( { label: EAdditionalType.buyerTypes, value: EAdditionalType.buyerTypes } );
    }

    if ( hasMarketingCampaigns ) {
      options.push( { label: EAdditionalType.marketingCampaigns, value: EAdditionalType.marketingCampaigns } );
    }

    if ( hasD2C ) {
      options.push( { label: EAdditionalType.d2c, value: EAdditionalType.d2c } );
    }

    return options;
  };

  const collapseItems = [
    {
      key:      WIZARD_NAME.ADDITIONAL_PARAMETERS,
      label:    <CollapseHeader
        step={WIZARD_NAME.ADDITIONAL_PARAMETERS}
        heading={`Step 04: ${STEP_NAMES.ADDITIONAL_PARAMETERS}`}
        caption='Select additional parameters for a selected brand'
        tags={collectTagValues()}
      />
      ,
      children: <div className='additional-parameters'>
        {selectedOrigin === Origin.FirstParty
        && ( ( isOnlyOneParty && hasMarketingCampaigns && hasD2C ) || ( !isOnlyOneParty && ( hasMarketingCampaigns || hasD2C ) ) )
        && (
          <div className='type-radio-group'>
            <Radio.Group
              options={generateOptions()}
              className={cn( {
                'three-wide-button-group': !isOnlyOneParty && hasMarketingCampaigns && hasD2C,
                'two-wide-button-group':   !isOnlyOneParty && ( hasMarketingCampaigns && !hasD2C || !hasMarketingCampaigns && hasD2C )
                || isOnlyOneParty && hasMarketingCampaigns && hasD2C,
              } )}
              optionType='button'
              buttonStyle='solid'
              value={selectedType}
              onChange={handleAdditionalParametersRadioGroupChange}
            />
            { ( !isOnlyOneParty || hasMarketingCampaigns ) && selectedType === EAdditionalType.d2c && (
              <div className='note-caption'>
                <TitleWithCaption
                  caption='*D2C Website Data is available only for M&MS, Ethel M'
                  captionFontSize={12}
                />
              </div>
            )}
          </div>
        )}
        {hasMarketingCampaigns && selectedType === EAdditionalType.marketingCampaigns && (
          <AffinityList
            activeTab={activeTab}
            affinityList={campaignsData?.campaigns ?? []}
          />
        )}

        {selectedType === EAdditionalType.buyerTypes && (
          <>
            {selectedOrigin === Origin.FirstParty && (
              <Divider style={{ margin: '1rem 0 1rem' }} />
            )}
            <BuyerTypes activeTab={activeTab}/>
            <SpecifyTimeFilter activeTab={activeTab}/>
            <Divider style={{ margin: '1rem 0 1rem' }} />

            <div className='flavors-sizes-wrapper'>
              <div className='flavors-sizes-title'>
                <span>Flavours/Sizes</span>
              </div>
              <div className='flavors-sizes-content'>
                <Flavors activeTab={activeTab}/>
                <Sizes activeTab={activeTab}/>
              </div>
            </div>
          </>
        )}

        {
          isSelectedOnlyD2C && !thirdPartyBrands?.length
          && <Alert message={`We have only ${D2C_WEBSITE_DATA} for this brand.`} type='info' />
        }
        {(
          !!selectedD2C
            || !!selectedCampaigns?.length
            || !!selectedBuyerTypes.length
            || !!selectedFlavors?.length
            || !!selectedSizes?.length
            || ( !!selectedToDate && !!selectedFromDate )
        ) && (
          <>
            <Divider style={{ margin: '1rem 0 1rem' }} />
            <Space className='next-button'>
              <Button onClick={() => handleFinishWizardClick( selectedType )}>Finish Wizard</Button>
            </Space>
          </>
        )}
      </div>,
    },
  ];

  return loadingCampaigns
    ? (
      <BladeLoader />
    )
    : (
      <Collapse
        items={collapseItems}
        defaultActiveKey={WIZARD_NAME.ADDITIONAL_PARAMETERS}
        activeKey={additionalParametersWizard.isExpand ? WIZARD_NAME.ADDITIONAL_PARAMETERS : ''}
        collapsible='icon'
        expandIconPosition='end'
        onChange={() => handleCollapseChange( setAdditionalParametersWizard, additionalParametersWizard )}
        expandIcon={() =>
          additionalParametersWizard.isExpand
            ? (
              <MinusOutlined style={expandIconStyles} />
            )
            : (
              <PlusOutlined style={expandIconStyles} />
            )
        }
      />
    );
};

export default AdditionalParametersCollapse;
