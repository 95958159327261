import { DeleteOutlined, FilterOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Flex, Modal, Select, TreeSelect } from 'antd';
import { useState } from 'react';

import { IncomingScenario, useFilteringAttributesQuery, useSubmitAudienceForActivationMutation } from '../../../../apollo/graphql-types';
import { options } from '../../../Blade/collapse/const';
import { generateRequestLimits, generateTreeSelectData } from './utils';

interface IRequestData {
  requestId: string;
  segmentId: string;
  scenario: IncomingScenario;
  submittedAudiences: string;
}

interface ISubmitForActivationModalProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  open: boolean;
  requestData: IRequestData;
  errorMessage: () => void;
  successMessage: () => void;
}

enum EFilterType {
  limit = 'Limit to',
  exclude = 'Exclude'
}

const defaultTreeSelectProps = {
  showSearch:           true,
  treeCheckable:        true,
  allowClear:           true,
  multiple:             true,
  autoClearSearchValue: false,
  placeholder:          'Please select',
};

export const SubmitForActivationModal = ( {
  setOpen,
  open,
  requestData,
  errorMessage,
  successMessage,
}: ISubmitForActivationModalProps ) => {
  const { data } = useFilteringAttributesQuery( options() );
  const [limitTo, setLimitTo] = useState<string[]>( [] );
  const [exlude, setExclude] = useState<string[]>( [] );
  const [showSecondFilter, setShowSecondFilter] = useState( false );
  const [selectedFirstFilterType, setSelectedFirstFilterType] = useState<EFilterType>( EFilterType.limit );
  const treeData = generateTreeSelectData( data?.filteringAttributes );
  const [submitForActivation, { loading }] = useSubmitAudienceForActivationMutation( {
    ...options(),
    notifyOnNetworkStatusChange: true,
  } );

  const typeOptions = [
    {
      label:    EFilterType.limit,
      value:    EFilterType.limit,
      disabled: showSecondFilter && selectedFirstFilterType === EFilterType.exclude,
    },
    {
      label:    EFilterType.exclude,
      value:    EFilterType.exclude,
      disabled: showSecondFilter && selectedFirstFilterType === EFilterType.limit,
    },
  ];

  const onChange = ( newValue: string[], type: EFilterType ) => {
    if ( type === EFilterType.limit ) {
      setLimitTo( newValue );
      return;
    }

    setExclude( newValue );
  };

  const handleOk = () => {
    const limitToAttributes = generateRequestLimits( limitTo );
    const excludeAttributes = generateRequestLimits( exlude );

    submitForActivation( { variables: {
      audienceActivation: {
        ...requestData,
        excludeAttributes,
        limitToAttributes,
      },
    } } )
      .then( ( { data, errors } ) => {
        if ( !errors && !!data?.submitAudienceForActivation.authorEmail ) {
          successMessage();
        } else {
          errorMessage();
        }
      } )
      .catch( () => errorMessage() );
  };

  const renderSecondFilter = () => {
    const options = typeOptions.map( ( option ) => {
      const disabled = selectedFirstFilterType === option.value;

      return { ...option, disabled };
    } );

    const filterType = selectedFirstFilterType === EFilterType.limit ? EFilterType.exclude : EFilterType.limit;

    return (
      <>
        <Select value={filterType} options={options} />
        <TreeSelect
          {...defaultTreeSelectProps}
          treeExpandAction='click'
          treeData={treeData}
          value={selectedFirstFilterType === EFilterType.limit ? exlude : limitTo}
          onChange={( value ) => onChange( value, filterType )}
        />
        <Button
          className='delete-filter'
          type='link'
          onClick={() => {
            setShowSecondFilter( false );
            selectedFirstFilterType === EFilterType.exclude ? setLimitTo( [] ) : setExclude( [] );
          }}
        ><DeleteOutlined /></Button>
      </>
    );
  };

  return (
    <Modal
      destroyOnClose
      centered
      maskClosable={false}
      okText='Submit for Activation'
      okButtonProps={{ loading }}
      title='Specify Consumer Attributes'
      cancelText='Back to Result Page'
      open={open}
      onOk={handleOk}
      onCancel={() => setOpen( false )}
      width={900}
    >
      <div className='specify-attributes'>
        <div className='specify-attributes-title'><FilterOutlined /> Specify Consumer Attributes</div>
        <Flex className='submit-filter'>
          <Select
            value={selectedFirstFilterType}
            options={typeOptions}
            onChange={( value ) => {
              setSelectedFirstFilterType( value );
              setExclude( value === EFilterType.exclude ? limitTo : [] );
              setLimitTo( value === EFilterType.limit ? exlude : [] );
            }}
          />
          <TreeSelect
            {...defaultTreeSelectProps}
            treeExpandAction='click'
            treeData={treeData}
            value={selectedFirstFilterType === EFilterType.limit ? limitTo : exlude}
            onChange={( value ) => onChange( value, selectedFirstFilterType )}
          />
          <Button
            className='delete-filter'
            type='link'
            onClick={() => {
              if ( !showSecondFilter ) {
                selectedFirstFilterType === EFilterType.exclude ? setExclude( [] ) : setLimitTo( [] );
              } else {
                setShowSecondFilter( false );
                setSelectedFirstFilterType( selectedFirstFilterType === EFilterType.exclude ? EFilterType.limit : EFilterType.exclude );
                selectedFirstFilterType === EFilterType.exclude ? setExclude( [] ) : setLimitTo( [] );
              }
            }}
          ><DeleteOutlined /></Button>
        </Flex>

        <Flex className='second-filter submit-filter'>
          {
            !showSecondFilter
              ? (
                <Button className='add-new-filter' type='link' onClick={() => setShowSecondFilter( true )}>
                  <PlusOutlined /> New Filter
                </Button>
              )
              : renderSecondFilter()
          }
        </Flex>
      </div>
    </Modal>
  );
};
