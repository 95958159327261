import './TextPopover.scss';

import { Popover } from 'antd';
import { useEffect, useRef, useState } from 'react';

type TTextPopoverProps = {
  text?: string;
  maxWidth?: number;
  maxHeight?: number;
} & JSX.IntrinsicElements['div'];

export const TextPopover = ( { text, maxWidth, maxHeight, children, className = '' }: TTextPopoverProps ) => {
  const ref = useRef<HTMLSpanElement | null>( null );
  const [isOverflowed, setIsOverflowed] = useState( true );

  useEffect( () => {
    const text = ref.current;

    if ( text && !!text?.offsetWidth && !!text?.scrollWidth ) {
      const isTextOverflowed = !!maxHeight ? text?.offsetHeight < text?.scrollHeight : text?.offsetWidth < text?.scrollWidth;
      setIsOverflowed( isTextOverflowed );
    }
  } );

  return isOverflowed
    ? <Popover overlayStyle={{
      maxWidth: maxWidth ?? ( ( ref.current?.offsetWidth || 0 ) > 0 ? ref.current?.offsetWidth : '100%' ) }
    }
    content={text}
    placement='bottom'
    >
      <span ref={ref} className={`text-popover-${!!maxHeight ? 'height' : 'width'} ${className}`}>
        <span>{children ?? text}</span>
      </span>
    </Popover>
    : <span
      ref={ref}
      className={`no-popover text-popover-${!!maxHeight ? 'height' : 'width'} ${className}`}>
      <span>{children ?? text}</span>
    </span>;
};
