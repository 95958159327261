import { Button, Col, Flex, Row, Spin } from 'antd';
import classNames from 'classnames';
import { omit } from 'lodash';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useGetWizardAudienceSizeLazyQuery } from '../../../apollo/graphql-types';
import { selectAudiencesToCompare } from '../../../store/audienceComparison/selectors';
import { selectTabs } from '../../../store/tab/selectors';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { AUDIENCE_ID, options } from '../collapse/const';
import CollapseWizard from '../CollapseWizard/CollapseWizard';

interface IBladeContentProps {
  audience: AUDIENCE_ID;
}

const BladeContent = ( { audience }: IBladeContentProps ) => {
  const tabs = useSelector( selectTabs );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );

  const isFinishedTab = tabs[audience].isFinished;

  const [audienceSize, { data, loading, error, refetch }] = useGetWizardAudienceSizeLazyQuery();

  useEffect( () => {
    if ( isFinishedTab ) {
      audienceSize( {
        ...options(),
        notifyOnNetworkStatusChange: true,
        variables:                   {
          audience: omit( audiencesToCompare[audience], 'benchmark' ),
        },
      } );
    }
  }, [isFinishedTab, audiencesToCompare] );

  return (
    <div className='parameters-wizard'>
      <Row>
        <Col span={14}>
          <TitleWithCaption
            heading='Parameters Wizard'
            caption='Choose parameters one by one to get what are you looking for'
            captionFontSize={12}
          />
        </Col>
        {
          isFinishedTab && (
            <Col span={10} className='audience-size'>
              <TitleWithCaption
                heading='Audience Size'
                caption={
                  <>
                    {error && (
                      <span className='audience-size-error'>
                    Error loading audience size. Please <Button type='link' onClick={() => refetch()}>retry</Button>
                      </span>
                    ) }
                    {loading && !error ? <Spin size='small'/> : data?.getWizardAudienceSize.toLocaleString( 'en-US' )}
                  </>
                }
                captionFontSize={14}
                captionProps={{
                  className: 'title-caption',
                }}
              />
            </Col>
          )
        }
      </Row>
      <CollapseWizard audience={audience}/>
    </div>
  );
};

export default BladeContent;
