import './BuyerTypes.scss';

import { CheckOutlined, InfoCircleOutlined } from '@ant-design/icons';
import { Button, Popover, Space } from 'antd';
import cn from 'classnames';
import { useDispatch, useSelector } from 'react-redux';

import { setAudienceParameters } from '../../../../../store/audienceComparison/actions';
import { selectBuyerTypesByAudienceId } from '../../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../../store/tab/actions';
import { BuyerType } from '../../../../../typings';
import { AUDIENCE_ID, BUYER_TYPE_DESCRIPTION, tooltipContent } from '../../const';
import { buyerTypeToTagName } from '../const';

interface IBuyerTypesProps {
  activeTab: AUDIENCE_ID;

}

export const BuyerTypes = ( { activeTab }: IBuyerTypesProps ) => {
  const dispatch = useDispatch();
  const selectedBuyerTypesSelector = useSelector( selectBuyerTypesByAudienceId( activeTab ) );
  const selectedBuyerTypes = selectedBuyerTypesSelector || [];
  const isAllTypesSelected = selectedBuyerTypes.length === Object.values( BuyerType ).length;


  const handleClickAllTypes = () => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    isAllTypesSelected
      ? dispatch( setAudienceParameters( [activeTab, { buyerTypes: [] }] ) )
      : dispatch( setAudienceParameters( [activeTab, { buyerTypes: Object.values( BuyerType ) }] ) );
  };

  const handleBuyerTypeButtonClick = ( name: BuyerType ) => {
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( selectedBuyerTypes?.includes( name ) ) {
      dispatch( setAudienceParameters( [activeTab, { buyerTypes: selectedBuyerTypes.filter( ( buyerType ) => buyerType !== name ) }] ) );

      return;
    }

    dispatch( setAudienceParameters( [activeTab, { buyerTypes: [...selectedBuyerTypes, name] }] ) );
  };

  return (
    <>
      <div className='buyer-types'>
        <span className='buyer_type-info'>Buyer Type</span>
        <Popover content={tooltipContent( BUYER_TYPE_DESCRIPTION )} trigger='hover' placement='bottom'>
          <InfoCircleOutlined className='info-circle' />
        </Popover>
        <div className='buyer-types-buttons'>
          <Button
            key='All Buyer Types'
            type={isAllTypesSelected ? 'primary' : 'default'}
            onClick={handleClickAllTypes}
          >
        All Buyer Types
          </Button>
          <Space className='blade-buttons' wrap>
            {Object.values( BuyerType ).map( ( buyerType ) => {
              const isSelected = selectedBuyerTypes.includes( buyerType );

              return (
                <Button
                  key={buyerType}
                  className={cn( { selected: isSelected } )}
                  icon={isSelected ? <CheckOutlined /> : ''}
                  onClick={() => handleBuyerTypeButtonClick( buyerType )}
                >
                  {buyerTypeToTagName[buyerType]}
                </Button>
              );
            } )}
          </Space>
        </div>
      </div>
    </>
  );
};
