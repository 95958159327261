
import './SpecifyTimeFilter.scss';

import { CalendarOutlined, MinusOutlined } from '@ant-design/icons';
import { Button, Collapse, DatePicker } from 'antd';
import { RangePickerProps } from 'antd/es/date-picker';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setAudienceParameters } from '../../../../../store/audienceComparison/actions';
import { selectTimeFilterFromByAudienceId, selectTimeFilterToByAudienceId } from '../../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../../store/tab/actions';
import { AUDIENCE_ID } from '../../const';

const { RangePicker } = DatePicker;

interface ISpecifyTimeFilterProps {
  activeTab: AUDIENCE_ID;
}

export const dateFormat = 'MM/DD/YYYY';

export const SpecifyTimeFilter = ( { activeTab }: ISpecifyTimeFilterProps ) => {
  const dispatch = useDispatch();

  const [show, setShow] = useState( false );
  const [pickerValue, setPickerValue] = useState<RangePickerProps['pickerValue']>( dayjs() );

  const fromDate = useSelector( selectTimeFilterFromByAudienceId( activeTab ) );
  const toDate = useSelector( selectTimeFilterToByAudienceId( activeTab ) );

  return (
    <Collapse
      className='specify-time-filter'
      items={ [
        {
          key:   'specify-time-filter',
          label: (
            <Button type='text' onClick={() => setShow( !show )}>
              <CalendarOutlined />{!fromDate && !toDate && 'Add '} Purchases Time Frame {
                toDate && fromDate && `(${dayjs( fromDate ).format( dateFormat )}-${dayjs( toDate ).format( dateFormat )})`
              }
            </Button>
          ),
          children: (
            <RangePicker
              format='MM-DD-YYYY'
              minDate={dayjs( '01/01/2022' )}
              maxDate={dayjs( dayjs() )}
              getPopupContainer={( node ) => node.parentElement!}
              needConfirm={false}
              defaultValue={!!fromDate && !!toDate ? [dayjs( dayjs( fromDate ).format() ), dayjs( dayjs( toDate ).format() )] : undefined}
              onChange={( value ) => {
                dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
                dispatch( setAudienceParameters( [activeTab, {
                  fromDate:  !!value ? dayjs( value?.[0] ).format() : undefined,
                  toDate:   !!value ? dayjs( value?.[1] ).format() : undefined,
                }] ) );
              }}
              renderExtraFooter={() => (
                <Button
                  type='link'
                  onClick={() => setPickerValue( [dayjs().add( -1, 'month' ), dayjs().add( -1, 'month' )] )}
                >Today</Button>
              )}
              pickerValue={pickerValue}
              onPickerValueChange={( value ) => setPickerValue( value )}
            />
          ),
        }]}
      defaultActiveKey=''
      activeKey={show ? 'specify-time-filter' : ''}
      collapsible='icon'
      expandIconPosition='end'
      onChange={() => setShow( !show )}
      expandIcon={() =>
        show && <MinusOutlined />
      }
    />
  );
};
