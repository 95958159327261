import { IncomingScenario } from '../../../apollo/graphql-types';
import { AUDIENCE_ID, NATIONAL_REFERENCE, SCENARIO_TYPE_TO_NAME, TAB_HEADERS } from '../collapse/const';

export const generateTabName = ( scenario: IncomingScenario, name: AUDIENCE_ID ) => {
  if ( scenario === IncomingScenario.Compare ) {
    return `${TAB_HEADERS[name]} ${name === AUDIENCE_ID.AUDIENCE_A ? '(Benchmark)' : '(Comparison)'}`;
  }

  return TAB_HEADERS[name];
};

export const generateSummaryCardName = ( scenario: IncomingScenario, name: AUDIENCE_ID ) => {
  if ( name === 'audienceNR' as AUDIENCE_ID ) {
    return `${NATIONAL_REFERENCE} (Benchmark)`;
  } else if ( [IncomingScenario.Overlap, IncomingScenario.Exclusive].includes( scenario ) ) {
    return SCENARIO_TYPE_TO_NAME[scenario];
  }

  if ( scenario === IncomingScenario.Compare ) {
    return `${TAB_HEADERS[name]} ${name === AUDIENCE_ID.AUDIENCE_A ? '(Benchmark)' : '(Comparison)'}`;
  }

  return TAB_HEADERS[name];
};
