import { Space, Spin } from 'antd';
import dayjs from 'dayjs';
import { flatten, fromPairs, sortBy, sum, uniqBy } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  Flavor,
  FlavorsSizesQuery,
  IncomingScenario,
  Origin,
  Scenario,
  Size,
  useBrandMarketingCampaignsLazyQuery,
  useFlavorsSizesLazyQuery,
  useGetAudienceTemplateLazyQuery,
  useGetBrandsLazyQuery,
  usePreBuiltAudienceNamesLazyQuery,
} from '../../apollo/graphql-types';
import { selectScenario } from '../../store/audienceComparison/selectors';
import { IAudienceToCompare } from '../../store/audienceComparison/typings';
import {
  collectBrandsNames,
  collectManufacturerNames,
  getBrandsByCategoryId,
  getManufacturersByCategoryId,
  isMarsManufacturer,
} from '../../utils/brand.utils';
import { generateCampaignsNames } from '../../utils/names.utils';
import { partyDataToName } from '../AudienceComparison/const';
import { ITemplate } from '../AudienceComparisonEmpty/DashboardCard/DashboardCard';
import {
  buyerTypeToTagName,
  D2C_WEBSITE_DATA,
  engagementToTagName,
  MARKETING_CAMPAIGNS,
} from '../Blade/collapse/AdditionalParametersCollapse/const';
import { calculateNewSizes } from '../Blade/collapse/AdditionalParametersCollapse/FlavorsAndSizes/utils';
import { dateFormat } from '../Blade/collapse/AdditionalParametersCollapse/SpecifyTimeFilter/SpecifyTimeFilter';
import { ALL_BRANDS, ALL_BUYER_TYPES, ALL_MANUFACTURERS, AUDIENCE_ID, COMPETITORS, getSortedBrandList, MARS_BRANDS,
  options, PREBUILT_AUDIENCES, SCENARIO_TYPE_TO_NAME } from '../Blade/collapse/const';
import { generateTabName } from '../Blade/WizardTabs/utils';
import CustomTag from '../CustomTag/CustomTag';

interface ITagsProps {
  templateId?: ITemplate['id'];
  width?: number;
  visible: boolean;
  audiences?: ( IAudienceToCompare | undefined | null | AUDIENCE_ID )[];
  selectedScenario?: IncomingScenario;
}

export const Tags = ( { templateId, width, visible, audiences, selectedScenario }: ITagsProps ) => {
  const [error, setError] = useState( false );
  const [loading, setLoading] = useState( false );
  const [tags, setTags] = useState<( JSX.Element | undefined )[][]>();

  const [loadAudiences, { loading: loadingAudiences, error: errorAudiences }] = usePreBuiltAudienceNamesLazyQuery( options() );
  const [loadCompetitors, { loading: loadingCompetitors, error: errorCompetitors }] = useGetBrandsLazyQuery( {
    ...options(), variables: { isCompetitor: true },
  } );
  const [loadCampaigns, { loading: loadingCampaigns, error: errorCampaigns }] = useBrandMarketingCampaignsLazyQuery( options() );
  const [loadMarsBrands, { loading: loadingMarsBrands, error: errorMarsBrands }] = useGetBrandsLazyQuery( options() );
  const [loadTemplate, { data, loading: loadingTemplate, error: errorTemplate }] = useGetAudienceTemplateLazyQuery();
  const [loadFlavorsAndSizes, { loading: loadingFavorites, error: errorFavorites }] = useFlavorsSizesLazyQuery();

  const renderTags = async () => {
    let audiencesToTag = audiences || [];
    let scenario = selectedScenario;

    if ( !!templateId ) {
      audiencesToTag = data?.getAudienceTemplate.scenario.audiences.filter( ( audience ) => !!audience ) || [];

      scenario = data?.getAudienceTemplate.scenario.scenario as unknown as IncomingScenario;
    }

    const tagsToShow = await Promise.all(
      audiencesToTag.map( async ( audience, index ) => {
        const tags = [];

        if ( typeof audience === 'string' ) {
          tags.push(
            <CustomTag
              key={`${audience}-vs`}
              tagValue={[`${generateTabName( scenario as IncomingScenario, audience )}: Not Selected`]}
              style={{ color: 'rgba(0, 0, 0, 0.45)' }}
            />
          );
        } else {
          const audienceParameters = audience?.audienceParameters;
          const audiencePreBuiltAudiencesIds = audience?.preBuiltAudienceIds;
          const selectedBrands = audienceParameters?.brands || [];
          const buyerTypes = audienceParameters?.buyerTypes?.length === 5
            ? ALL_BUYER_TYPES
            : audienceParameters?.buyerTypes?.map( ( bt ) => buyerTypeToTagName[bt] ).join( ', ' );
          const selectedFlavors = audienceParameters?.flavors;
          const selectedSizes = audienceParameters?.sizes;
          const fromDate = audienceParameters?.fromDate;
          const toDate = audienceParameters?.toDate;
          let sizesNames = {} as Record<string, string>;
          let sizesToShow: Size[] = [];
          let flavorsToShow: string = '';

          if ( selectedSizes?.length || selectedFlavors?.length ) {
            const availableFlavorsAndSizes = await loadFlavorsAndSizes( {
              ...options(),
              variables:                   {
                brandId: selectedBrands[0].toString(),
              },
            } );

            if ( !!availableFlavorsAndSizes.data?.flavorsSizes ) {
              const flavors = availableFlavorsAndSizes.data?.flavorsSizes.flavors;
              const flavorGroups = availableFlavorsAndSizes.data?.flavorsSizes.groups;

              if ( !flavorGroups?.length ) {
                flavorsToShow = flavors?.length === selectedFlavors?.length && ( selectedFlavors?.length || 0 ) > 1
                  ? 'All Flavours'
                  : ( selectedFlavors ?? [] ).join( ', ' );
              } else {
                const allFlavoursCount = ( flavors?.length || 0 )
                + sum( flavorGroups.map( ( group ) => group.flavors.length ) )
                + flavorGroups.length;

                const someFlavoursSelected = flavorGroups.map( ( group ) => {
                  if ( selectedFlavors?.includes( group.flavorGroupName || '' ) && group.flavors.length > 1 ) {
                    return `All Group ${group.flavorGroupName} Flavours`;
                  }

                  return group.flavors
                    .filter( ( flavor ) => selectedFlavors?.includes( flavor.flavorName || '' ) )
                    .map( ( flavor ) => flavor.flavorName )
                    .join( ', ' );
                } )
                  .filter( ( group ) => group.length );


                flavorsToShow = allFlavoursCount === selectedFlavors?.length && selectedFlavors.length > 1
                  ? 'All Flavours'
                  : someFlavoursSelected.join( ', ' );
              }

              const flavorsList = flatten( [
                ...( flatten( availableFlavorsAndSizes?.data?.flavorsSizes.groups?.map( ( group )=> group.flavors ) ) || [] ),
                ...( availableFlavorsAndSizes?.data?.flavorsSizes.flavors || [] ),
              ] );

              const names = fromPairs(
                uniqBy(
                  flatten(
                    flavorsList.map( ( flavor ) => flavor.sizes || [] ) ),
                  'sizeIdentifier'
                ).map( ( size ) => [size.sizeIdentifier, size.sizeName] )
              );

              sizesNames = names;
              sizesToShow = calculateNewSizes( availableFlavorsAndSizes.data?.flavorsSizes, selectedFlavors );
            }
          }

          // Pre built audiences tags
          if ( !isEmpty( audiencePreBuiltAudiencesIds ) ) {
            const preBuiltAudiences = await loadAudiences();
            const preAudiencesNames = audiencePreBuiltAudiencesIds
              ?.map( ( preBuilt: number ) => preBuiltAudiences?.data?.preBuiltAudienceNames.find( ( a ) => a.id === preBuilt )?.name );

            tags.push( PREBUILT_AUDIENCES );
            preAudiencesNames && tags.push( preAudiencesNames.join( ', ' ) );

          // Competitors brands tags
          } else {
            if ( !!audienceParameters?.manufacturers && !isMarsManufacturer( audienceParameters?.manufacturers ) ) {
              const competitorsData = await loadCompetitors();
              const competitors = competitorsData.data?.categorizedBrands;
              const competitorsBrandNames = collectBrandsNames( competitors || [] );
              const competitorsManufacturerNames = collectManufacturerNames( competitors || [] );
              const manufacturersList = getManufacturersByCategoryId( audienceParameters.category, competitors ) || [];
              const availableBrands = flatten( competitors
                ?.find( ( category ) => category.categoryId === audienceParameters?.category )
                ?.manufacturers
                .filter( ( manufacturer ) => audienceParameters?.manufacturers.includes( manufacturer.manufacturerId ) )
                .map( ( manufacturer ) => manufacturer.brands ) );
              const selectedCategoryName = competitors
                ?.find( ( category ) => audienceParameters.category === category.categoryId )?.categoryName || '';
              const isAllBrandsSelected = availableBrands?.length === selectedBrands?.length;
              const isAllManufacturersSelected = manufacturersList?.length === audienceParameters?.manufacturers?.length;
              const SOME_MANUFACTURERS_CAPTION = audienceParameters.manufacturers
                ?.map( ( manufacturer ) => competitorsManufacturerNames[manufacturer] ).join( ', ' );
              const ALL_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION} ${ALL_BRANDS}`;
              const SOME_BRANDS_CAPTION = `${isAllManufacturersSelected ? ALL_MANUFACTURERS : SOME_MANUFACTURERS_CAPTION}
               ${getSortedBrandList( selectedBrands, availableBrands )?.map( ( brand ) => competitorsBrandNames[brand] ).join( ', ' )}`;
              const brandTagValue = `${selectedCategoryName} 
              ${isAllBrandsSelected && availableBrands.length > 1 ? [ALL_BRANDS_CAPTION] : SOME_BRANDS_CAPTION}`;

              tags.push( COMPETITORS );
              tags.push( brandTagValue );
              tags.push( buyerTypes );

              if ( !!fromDate && !!toDate ) {
                tags.push( `${dayjs( fromDate ).format( dateFormat )}-${dayjs( toDate ).format( dateFormat )}` );
              }

              if ( selectedFlavors ) {
                tags.push( flavorsToShow );
              }

              if ( !!selectedSizes?.length ) {
                tags.push( sizesToShow.length === selectedSizes.length && selectedSizes.length > 1
                  ? 'All Package Sizes/Weights'
                  : sizesToShow
                    .filter( ( size ) => selectedSizes.includes( size.sizeIdentifier ) )
                    .map( ( size ) => sizesNames[size.sizeIdentifier] ).join( ', ' )
                );
              }
              // Mars brands tags
            } else if ( isMarsManufacturer( audienceParameters?.manufacturers ) ) {
              const selectedCategory = audienceParameters?.category || 0;
              const marsBrandsData = await loadMarsBrands();
              const marsBrands = marsBrandsData.data?.categorizedBrands;
              const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData.data?.categorizedBrands ) || [];
              const marsBrandNames = collectBrandsNames( marsBrands || [] );
              const selectedCampaigns = audienceParameters?.marketingCampaigns;
              const selectedCategoryName = marsBrandsData.data?.categorizedBrands
                ?.find( ( category ) => selectedCategory === category.categoryId )?.categoryName || '';
              const brandsToShow = selectedBrands.length === availableBrands.length
                ? ALL_BRANDS
                : selectedBrands.map( ( brand ) => marsBrandNames[brand] ).join( ', ' );

              tags.push( MARS_BRANDS );
              tags.push( `${selectedCategoryName} ${brandsToShow}` );

              if ( !!audienceParameters?.origin ) {
                tags.push( partyDataToName[audienceParameters.origin] );
              }

              if ( !!audienceParameters?.buyerTypes?.length ) {
                tags.push( buyerTypes );
              }

              if ( !!fromDate && !!toDate ) {
                tags.push( `${dayjs( fromDate ).format( dateFormat )}-${dayjs( toDate ).format( dateFormat )}` );
              }

              if ( selectedFlavors ) {
                tags.push( flavorsToShow );
              }

              if ( !!selectedSizes?.length ) {
                tags.push( sizesToShow.length === selectedSizes.length && selectedSizes.length > 1
                  ? 'All Package Sizes/Weights'
                  : sizesToShow
                    .filter( ( size ) => selectedSizes.includes( size.sizeIdentifier ) )
                    .map( ( size ) => sizesNames[size.sizeIdentifier] ).join( ', ' )
                );
              }

              // load and collect tags for mars campaings
              if ( !!selectedCampaigns ) {
                const brandsToLoad = selectedBrands.map( ( brandId ) => {
                  const name = marsBrandNames[brandId];
                  return {
                    id: brandId,
                    name,
                  };
                } );

                const campaignsData = await loadCampaigns( { variables: { brands: sortBy( brandsToLoad, 'id' ) || [] } } );
                const campaignsList = flatten(
                  campaignsData.data?.brandMarketingCampaigns?.campaigns?.map( ( affinity ) => affinity.marketingCampaigns )
                );

                tags.push( MARKETING_CAMPAIGNS );
                tags.push( generateCampaignsNames( campaignsList, selectedCampaigns, campaignsData.data ), );
              }

              // collect tag for engagement
              if ( !!audienceParameters?.engagementStatus ) {
                tags.push( engagementToTagName[audienceParameters.engagementStatus] );
              }

              if ( !!audienceParameters?.d2c ) {
                tags.push( D2C_WEBSITE_DATA );
              }
            }
          }
        }

        // add VS tag between audiences
        if ( audiencesToTag.length > 1 && index < audiencesToTag.length - 1 ) {
          tags.push(
            <CustomTag
              tagValue={[SCENARIO_TYPE_TO_NAME[scenario as IncomingScenario]]}
              key={index}
              style={{ backgroundColor: 'rgba(0, 0, 0, 0.15)' }}
            />
          );
        }

        return tags.map( ( tag, index ) => typeof tag === 'string'
          ? <CustomTag key={`${tag}-${index}`} maxWidth={!!templateId ? ( width || 200 ) - 55 : undefined} tagValue={tag} />
          : tag
        );
      } )
    );

    return setTags( tagsToShow );
  };

  useEffect( () => {
    if ( !!templateId ) {
      loadTemplate( {
        ...options(),
        variables: { templateId },
      } );
    }
  }, [] );

  useEffect( () => {
    if ( loadingAudiences || loadingCompetitors || loadingCampaigns || loadingMarsBrands || loadingTemplate || loadingFavorites ) {
      setLoading( true );
    } else {
      setLoading( false );
    }
  }, [loadingAudiences, loadingCompetitors, loadingCampaigns, loadingMarsBrands, loadingTemplate, loadingFavorites] );

  useEffect( () => {
    if ( errorAudiences || errorCompetitors || errorCampaigns || errorMarsBrands || errorTemplate || errorFavorites ) {
      setError( true );
    } else {
      setError( false );
    }
  }, [errorAudiences, errorCompetitors, errorCampaigns, errorMarsBrands, errorTemplate, errorFavorites] );

  useEffect( () => {
    renderTags();
  }, [data, audiences] );

  if ( !visible ) return null;

  return (
    <div className='tooltip-tags'>
      {!error
        ? !loading ? tags : <Space className='loader' size='middle'><Spin /></Space>
        : (
          <div className='error'>Couldn't load the data. Please retry.</div>
        )}
    </div>
  );
};
