import { NetworkStatus } from '@apollo/client';
import { Switch, Tree } from 'antd';
import cn from 'classnames';
import { flatten, fromPairs, intersectionBy, isArray, uniqBy } from 'lodash';
import { Key, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Size, useFlavorsSizesLazyQuery } from '../../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../../store/audienceComparison/actions';
import { selectBrandsByAudienceId, selectFlavorsByAudienceId,
  selectSizesByAudienceId } from '../../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../../store/tab/actions';
import { setSizesNames } from '../../../../../store/wizzard/actions';
import { selectSizesNames } from '../../../../../store/wizzard/selectors';
import { ErrorRetryCircle } from '../../../../Error/ErrorRetryCircle';
import { AUDIENCE_ID, options } from '../../const';
import { calculateNewSizes } from './utils';

interface ISizesProps {
  activeTab: AUDIENCE_ID;
}

export const Sizes = ( { activeTab }: ISizesProps ) => {
  const dispatch = useDispatch();

  const selectedBrandsSelector = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedBrands = selectedBrandsSelector || [];
  const selectedFlavors = useSelector( selectFlavorsByAudienceId( activeTab ) );
  const selectedSizes = useSelector( selectSizesByAudienceId( activeTab ) );
  const sizesNames = useSelector( selectSizesNames );

  const [showSizes, setShowSizes] = useState( !!selectedSizes?.length ? true : false );
  const [disabled, setDisabled] = useState( false );
  const [sizesToShow, setSizesToShow] = useState<( Size )[]>( [] );

  const messageSelectedTooManyBrands = 'Sizes/Weights can be chosen for single brand only.';
  const [sizesMessage, setSizesMessage] = useState(
    selectedBrands.length > 1 ? messageSelectedTooManyBrands : ''
  );

  const [loadFlavorsAndSizes, { data, error, loading, refetch, networkStatus }] = useFlavorsSizesLazyQuery();

  const handleSizeCLick = async ( checked: boolean ) => {
    setShowSizes( checked );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );

    if ( checked ) {
      const flavours = await loadFlavorsAndSizes( {
        ...options(),
        notifyOnNetworkStatusChange: true,
        variables:                   {
          brandId: selectedBrands[0].toString(),
        },
      } );

      if ( !!flavours.data?.flavorsSizes ) {
        const flavors = flatten( [
          ...( flatten( flavours?.data?.flavorsSizes.groups?.map( ( group )=> group.flavors ) ) || [] ),
          ...( flavours?.data?.flavorsSizes.flavors || [] ),
        ] );

        const sizes = fromPairs(
          uniqBy(
            flatten(
              flavors.map( ( flavor ) => flavor.sizes || [] ) ),
            'sizeIdentifier'
          ).map( ( size ) => [size.sizeIdentifier, size.sizeName] )
        ) ;

        dispatch( setSizesNames( sizes ) );
      }
    } else {
      dispatch( setAudienceParameters( [activeTab, { sizes: undefined }] ) );
    }
  };

  useEffect( () => {
    if ( !!data ) {
      if ( networkStatus !== NetworkStatus.loading
        && ( ( !data?.flavorsSizes.flavors?.length && !data?.flavorsSizes.groups?.length ) || error )
      ) {
        if ( !data.flavorsSizes.flavors?.length && !data.flavorsSizes.groups?.length ) {
          setDisabled( true );
        }
        setShowSizes( false );
        setSizesMessage( error ? 'Some error occured, please try again' : 'There are no sizes/weights for this brand.' );
      }
    }
  }, [data, networkStatus] );

  useEffect( () => {
    setShowSizes( false );
    setDisabled( false );
    setSizesMessage( selectedBrands.length > 1 ? messageSelectedTooManyBrands : '' );
  }, [selectedBrands] );

  useEffect( () => {
    if ( !!selectedSizes?.length ) {
      handleSizeCLick( true );
    }
  }, [] );

  useEffect( () => {
    if( !!data?.flavorsSizes.flavors?.length || !!data?.flavorsSizes.groups?.length ) {
      const newSizes = calculateNewSizes( data.flavorsSizes, selectedFlavors );
      setSizesToShow( newSizes );

      if ( !selectedSizes?.every( ( size ) => newSizes.find( ( newSize ) => newSize.sizeIdentifier === size ) ) ) {
        dispatch( setAudienceParameters(
          [activeTab, { sizes: selectedSizes?.filter( ( size ) => newSizes.find( ( newSize ) => newSize.sizeIdentifier === size ) ) }]
        ) );
      }

      if ( !!newSizes.length ) {
        setSizesMessage( ( selectedFlavors?.length || 0 ) > 1 ? 'Sizes in common for selected flavours' : '' );

        return;
      }

      setSizesMessage( 'There are no sizes/weights for the selection.' );
    }
  }, [selectedFlavors, data] );

  const generateTreeData = useMemo( () => {
    const treeData = sizesToShow
      .map( ( size ) => ( {
        key:      size.sizeIdentifier,
        title:     size.sizeName,
      } ) );

    return treeData;
  }, [sizesToShow] );

  return (
    <div className={cn( 'flavors-sizes', {
      message: !!sizesMessage,
      visible: showSizes && !loading,
    } )}>
      <Switch
        onClick={handleSizeCLick}
        checked={showSizes}
        disabled={ selectedBrands.length > 1 || disabled}
        loading={[NetworkStatus.loading, NetworkStatus.refetch].includes( networkStatus )}
      /> Specify Package Size/Weight

      {sizesMessage && !loading && (
        <div className='flavors-sizes-message'>
          {sizesMessage}
        </div>
      )}


      { showSizes && error && (
        <ErrorRetryCircle
          title='Sizes/Weights Load Failed'
          description='Some error occured, please try again'
          onClick={() => {
            refetch();
          }}
        />
      )
      }

      { !!sizesToShow.length && showSizes && !error && !loading && (
        <div className='flavors-sizes-content'>
          <div className='flavors-sizes-title'>
          Select Sizes
          </div>
          <div className='flavors-sizes-list'>
            <Tree
              checkable
              selectable={false}
              treeData={generateTreeData}
              checkedKeys={selectedSizes as Key[]}
              onCheck={( checked ) => {
                const sortedSizes = Object.keys( sizesNames )
                  .filter( ( id ) => isArray( checked ) ? checked.includes( id ) : checked.checked.includes( id ) );

                dispatch( setAudienceParameters( [activeTab, { sizes: sortedSizes.length ? sortedSizes : undefined }] ) );
                dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};
