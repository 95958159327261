import './DetailedAttributes.scss';

import { Button, Tag } from 'antd';
import cn from 'classnames';
import { useMemo, useState } from 'react';

import { DetailedAttributeValueRatioIndex, DetailedSummaryCardAttribute, Maybe } from '../../../apollo/graphql-types';
import { TextPopover } from '../../TextPopover/TextPopover';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';

interface IDefaultAttributesProps {
  detailedAttributes: DetailedSummaryCardAttribute[];
}

interface IDetailedAttributesItemProps {
  attributeValues?: Maybe<DetailedAttributeValueRatioIndex[]>;
}

const DetailedAttributeValues = ( { attributeValues }: IDetailedAttributesItemProps ) => {
  const [showAll, setShowAll] = useState( false );

  const attributeValuesToShow = useMemo( () => attributeValues?.slice( 0, !showAll ? 3 : attributeValues.length ), [showAll] );

  return (
    <div className='detailed-attributes-values'>
      { attributeValuesToShow?.map( ( value, index ) => (
        <Tag key={`${value.valueName}-${value.percentage}-${index}`}>
          <TextPopover
            text={`${value.attributeName}, ${value.valueName}`}
            maxWidth={640}
            className='name'
          />
          <span className='percentage'>{value.percentage}%</span>
          {
            value.ratioIndex && (
              <>
                <span className={cn( 'index', {
                  green: value.ratioIndex >= 120,
                  red:   value.ratioIndex < 80,
                } )}><span className='separator'>, </span>{value.ratioIndex}</span> index
              </>
            )
          }
        </Tag>
      ) )}
      {( attributeValues?.length || 0 ) > 3 && (
        <Button onClick={() => setShowAll( !showAll )} type='link'>Show {!showAll ? 'All': 'Less'}</Button>
      )}
    </div>
  );
};

export const DetailedAttributes = ( { detailedAttributes }: IDefaultAttributesProps ) => (
  <div className='detailed-attributes'>
    {
      detailedAttributes.map( ( attribute, index ) => (
        <div className='detailed-attributes-item' key={`${attribute.categoryName}-${index}`}>
          <TitleWithCaption heading={`${attribute.categoryName} Highlights`} headingLevel={5} headingStyles={{ marginBottom: '8px' }} />

          {
            !attribute.highRatioIndexes?.length && !attribute.lowRatioIndexes?.length
              ? <span className='empty-note'>There are no over- and under-indexes for this value.</span>
              : (
                <>
                  <DetailedAttributeValues
                    attributeValues={attribute.highRatioIndexes}
                    key={`${attribute.categoryName}-highest-attribute-values`}
                  />
                  <DetailedAttributeValues
                    attributeValues={attribute.lowRatioIndexes}
                    key={`${attribute.categoryName}-lowest-attribute-values`}
                  />
                </>
              )
          }
        </div>
      ) )
    }
  </div>
);
