import '../../CustomTag/CustomTag.scss';

import { Space } from 'antd';
import { useSelector } from 'react-redux';

import { selectTabsActiveTab } from '../../../store/tab/selectors';
import { selectWizardStepByTabAndStepName } from '../../../store/wizzard/selectors';
import CustomTag from '../../CustomTag/CustomTag';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { WIZARD_NAME } from '../types';

export interface ICollapseHeaderProps {
  step: WIZARD_NAME;
  heading: string;
  caption: string;
  tags: ITag[];
}

export interface ITag {
  name: string;
  value: string | string[];
}


const CollapseHeader = ( { step, heading, caption, tags }: ICollapseHeaderProps ) => {
  const activeTab = useSelector( selectTabsActiveTab );
  const currentStep = useSelector( selectWizardStepByTabAndStepName( activeTab, step ) );
  const isVisible = currentStep.isFinished && !currentStep.isExpand && !!tags.length;

  return (
    <>
      <TitleWithCaption heading={heading} caption={caption} captionFontSize={12} />
      <Space className='collapse-tag__wrapper' wrap style={{ maxWidth: 640, display: 'flex', gap: 0 }}>
        {tags.map( ( tag ) => (
          <CustomTag isVisible={isVisible} tagName={tag.name} tagValue={tag.value} key={tag.name} />
        ) )}
      </Space>
    </>
  );
};

export default CollapseHeader;
