import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import Link from 'antd/es/typography/Link';
import { useDispatch } from 'react-redux';

import { IncomingScenario } from '../../../apollo/graphql-types';
import { setScenario, setSingleAudienceById } from '../../../store/audienceComparison/actions';
import { setWizardIsOpen } from '../../../store/wizzard/actions';
import { AUDIENCE_ID } from '../../Blade/collapse/const';

type TScenarioGroupCardProps = {
  title: string;
  description: string;
  scenario: IncomingScenario;
  icon: JSX.Element;
};

export const ScenarioGroupCard = ( { title, description, scenario, icon }: TScenarioGroupCardProps ) => {
  const dispatch = useDispatch();

  const handleOpenWizzard = () => {
    dispatch( setWizardIsOpen( true ) );
    dispatch( setScenario( scenario ) );

    if ( scenario === IncomingScenario.Profile ) {
      dispatch( setSingleAudienceById( AUDIENCE_ID.AUDIENCE_A ) );
    }
  };

  return <div className='scenario-group-card'>
    {icon}
    <p className='scenario-group-card-title'>{title}</p>
    <p className='scenario-group-card-action'><Link onClick={() => handleOpenWizzard()}>Select Parameters</Link></p>
    <div className='scenario-group-card-description'>
      <Popover
        arrow={false}
        placement='bottom'
        content={description}
      >
        <InfoCircleOutlined className='scenario-group-card-info' />
      </Popover>
    </div>
  </div>;
};
