import { ExportOutlined } from '@ant-design/icons';
import { Button, message } from 'antd';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';

import { attributesExcelAPI } from '../../../apollo/apollo';
import { deleteCookie } from '../../../utils/cookie';
import { AudienceRequestContext } from '../FilledAudienceComparison';

export const ExportXLSX = () => {
  const ref = useRef( null );
  const [downloading, setDownloading] = useState( false );
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: 'XLSX creation failed, please try again later',
    } );
  };

  const requestData = useContext( AudienceRequestContext );

  const onDownloadClick = async () => {
    try {
      if ( !requestData?.requestId ) {
        throw new Error( 'request_id wasn\'t provided' );
      }

      const params = new URLSearchParams();

      params.append( 'query_ids', requestData?.queryId );
      params.append( 'request_id', requestData?.requestId );

      const response = await fetch(
        attributesExcelAPI + params,
        {
          method:      'GET',
          credentials: 'include',
        }
      );

      if ( !response.ok ) {
        if ( response.status === 401 ) {
          deleteCookie( 'session_token' );
          navigate( '/login' );
        }

        throw new Error( 'Something went wrong' );
      }

      const ab = await response.arrayBuffer();
      const wb = XLSX.read( ab );
      let fileName = 'AudienceComparisonExport';
      const index = response.headers.get( 'Content-Disposition' )?.indexOf( 'filename="' );

      if ( !!index ) {
        const newFileName = response.headers.get( 'Content-Disposition' )
          ?.slice(
            index + 10,
            ( response.headers.get( 'Content-Disposition' )?.length || 0 ) - 1
          );

        fileName = !!newFileName ? newFileName : fileName;
      }

      XLSX.writeFile( wb, fileName );
    } catch ( error ) {
      errorMessage();
      console.error( error ); //eslint-disable-line
    }

    setDownloading( false );
  };

  useEffect( () => {
    if ( downloading ) {
      onDownloadClick();
    }
  }, [downloading] );

  return (
    <>
      {contextHolder}
      <a ref={ref} href=''></a>
      <Button loading={downloading} onClick={() => setDownloading( true )} icon={<ExportOutlined />}>Export as XLSX</Button>
    </>
  );
};
