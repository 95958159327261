import './WizardTabs.scss';

import { CheckOutlined, ClearOutlined,
  CopyOutlined, DeleteOutlined, EllipsisOutlined,
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, MenuProps, Tabs, TabsProps } from 'antd';
import cn from 'classnames';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { IncomingScenario } from '../../../apollo/graphql-types';
import { deleteAudience, duplicateAudienceById, setAudienceParameters } from '../../../store/audienceComparison/actions';
import { selectAudiencesToCompare, selectNewAudienceId, selectScenario } from '../../../store/audienceComparison/selectors';
import { deleteTab, setActiveTab, setSpecificTab } from '../../../store/tab/actions';
import { selectTabs, selectTabsActiveTab } from '../../../store/tab/selectors';
import { deleteWizzardById, duplicateWizzardById, resetAllWizards } from '../../../store/wizzard/actions';
import { selectWizardSourceByTabName } from '../../../store/wizzard/selectors';
import BladeContent from '../bladeCommon/BladeContent';
import { AUDIENCE_ID } from '../collapse/const';
import { AddAudience } from './AddAudience';
import { generateTabName } from './utils';

enum EDirection {
  left = 'left',
  right = 'right'
}

const WizardTabs: React.FC = () => {
  const dispatch = useDispatch();
  const ref = useRef<HTMLDivElement>( null );
  const activeTab = useSelector( selectTabsActiveTab );
  const sourceWizard = useSelector( selectWizardSourceByTabName( activeTab ) );
  const tabs = useSelector( selectTabs );
  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const selectedScenario = useSelector( selectScenario );
  const newAudienceId = useSelector( selectNewAudienceId );

  const audiencesCount = Object.keys( audiencesToCompare ).length;
  const audiencesKeys = Object.keys( audiencesToCompare );

  const handleTabChange = ( tabName: string, direction?: EDirection ) => {
    if ( !direction ) {
      dispatch( setActiveTab( tabName as AUDIENCE_ID ) );

      return;
    }

    const currentIndex = audiencesKeys.findIndex( ( audienceId ) => audienceId === tabName );
    const nextIndex = currentIndex - ( direction === EDirection.left ? 1 : -1 );

    dispatch( setActiveTab( audiencesKeys[nextIndex] as AUDIENCE_ID ) );
  };

  const handleClearTab = () => {
    dispatch( resetAllWizards( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    dispatch( setAudienceParameters( [activeTab, undefined] ) );
  };

  const handleDeleteTab = () => {
    dispatch( deleteAudience( activeTab ) );
    dispatch( deleteWizzardById( activeTab ) );
    dispatch( deleteTab( activeTab ) );
    dispatch( setActiveTab( AUDIENCE_ID.AUDIENCE_A ) );
  };

  const handleDuplicate = ( audienceId: AUDIENCE_ID ) => {
    dispatch( duplicateAudienceById( { audienceToDuplicate: audienceId, newAudienceId } ) );
    dispatch( duplicateWizzardById( { audienceToDuplicate: audienceId, newAudienceId } ) );
    dispatch( setActiveTab( newAudienceId ) );
    dispatch( setSpecificTab( { activeTab: newAudienceId, isFinished: true } ) );
  };


  const generateItems: ( audienceId: AUDIENCE_ID ) => MenuProps['items'] = ( audienceId: AUDIENCE_ID ) => [
    {
      key:      'reset',
      disabled: !sourceWizard.isFinished,
      icon:     <ClearOutlined />,
      label:    'Reset parameters',
      onClick:  handleClearTab,
    },
    {
      key:      'duplicate',
      icon:     <CopyOutlined />,
      label:    'Duplicate',
      style:    { display:  ( [IncomingScenario.Exclusive, IncomingScenario.Profile].includes( selectedScenario ) ) ? 'none' : 'inherit' },
      disabled: !tabs[activeTab].isFinished
      || Object.keys( audiencesToCompare ).length === 6,
      onClick:  () => handleDuplicate( audienceId ),
    },
    {
      key:     'delete',
      danger:  true,
      icon:    <DeleteOutlined />,
      label:   ( activeTab === AUDIENCE_ID.AUDIENCE_A || audiencesCount === 2 ) ? null: 'Delete',
      style:   { display:  ( activeTab === AUDIENCE_ID.AUDIENCE_A || audiencesCount === 2 ) ? 'none' : 'inherit' },
      onClick: handleDeleteTab,
    },
  ];

  const generateMenuContent = ( audienceId: AUDIENCE_ID ) => (
    <Dropdown
      trigger={['click']}
      menu={{ items: generateItems( audienceId ) }}
      placement='bottomRight'
      rootClassName='custom-styled-dropdown'
    >
      <EllipsisOutlined style={{ margin: '0 0 0 8px' }} />
    </Dropdown>
  );

  const generateTabItems = () => {
    const items = Object.entries( audiencesToCompare ).map( ( [key, audience] ) => ( {
      key,
      forceRender: true,
      label:       (
        <>
          {generateTabName( selectedScenario, key as AUDIENCE_ID )}
          {generateMenuContent( key as AUDIENCE_ID )}
        </>
      ),
      icon:        tabs[key as AUDIENCE_ID]?.isFinished && <CheckOutlined />,
      children:    <BladeContent audience={key as AUDIENCE_ID} />,
      disabled:    false,
    } ) ) as TabsProps['items'] & {ref: any};

    if ( items.length < 6 && ![IncomingScenario.Profile, IncomingScenario.Exclusive].includes( selectedScenario ) ) {
      items.push( {
        forceRender: true,
        disabled:    true,
        key:         'add_audience',
        label:       <AddAudience />,
        icon:        false,
        children:    <></>,
      } );
    }

    return items;
  };

  useEffect( () => {
    if ( !tabs[activeTab]?.isFinished ) {
      dispatch( setSpecificTab( { activeTab } ) );
    }
  }, [tabs[activeTab]?.isFinished] );

  return (
    <div ref={ref}>
      <Tabs
        rootClassName={cn( 'wizzard-tabs', { 'short': audiencesCount } )}
        defaultActiveKey={activeTab}
        activeKey={activeTab}
        items={generateTabItems()}
        onChange={handleTabChange}
        tabBarExtraContent={{
          left:  activeTab !== AUDIENCE_ID.AUDIENCE_A && audiencesKeys.length > ( selectedScenario === IncomingScenario.Compare ? 2 : 5 )
            ? <Button size='small' icon={<LeftOutlined />} onClick={() => handleTabChange( activeTab, EDirection.left )} />
            : null,
          right: activeTab !== audiencesKeys[audiencesKeys.length - 1]
          && audiencesKeys.length > ( selectedScenario === IncomingScenario.Compare ? 2 : 5 )
            ? <Button size='small' icon={<RightOutlined />} onClick={() => handleTabChange( activeTab, EDirection.right )} />
            : null,
        }}
      />
    </div>
  );
};

export default WizardTabs;
