import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, Flex, Layout } from 'antd';
import cn from 'classnames';
import { useRef, useState } from 'react';

import { AudienceSummaryCard, ComparisonInitiationResult } from '../../../apollo/graphql-types';
import { SummaryCard } from './SummaryCard';


interface ISummaryCardList {
  summaryCards: AudienceSummaryCard[];
  attributesCalculations: ComparisonInitiationResult;
}
export const SummaryCardList = ( { summaryCards, attributesCalculations }: ISummaryCardList ) => {
  const maxStepsCount = summaryCards.length - 3;
  const [step, setStep] = useState( 0 );

  const ref = useRef<HTMLDivElement>( null );

  const handleArrowClick = ( direction: 'left' | 'right' ) => {
    if ( !ref.current ) {
      return;
    }

    if ( direction === 'right' ) {
      setStep( step + 1 );
      ref.current.scrollLeft = ref.current.children[0].children[0].clientWidth*( step + 1 ) + 16*( step +1 );
    } else {
      setStep( step - 1 );
      if ( step === 1 ) {
        ref.current.scrollLeft = 0;
      } else {
        ref.current.scrollLeft = ref.current.children[0].children[0].clientWidth * ( step - 1 ) + 16*( step - 1 );
      }
    }
  };

  return (
    <Layout
      className={cn( 'summary-card-list' )}
      style={{
        '--items-count': summaryCards.length,
        '--step':        step,
      } as React.CSSProperties}
    >
      {
        step !== 0 && summaryCards.length > 3 && (
          <div className='summary-card-list-action-wrapper'>
            <Button className='summary-card-list-action' size='large' icon={<LeftOutlined />} onClick={() => handleArrowClick( 'left' )} />
          </div>
        )
      }

      <div ref={ref} className='summary-cards-wrapper'>
        <Flex
          gap={16}
          className={cn( 'attribute-summary', {
            'count-3': summaryCards?.length === 3,
            'slider':  summaryCards?.length > 3,
            last:      maxStepsCount === step,
          } )
          }
        >
          {
            summaryCards.map( ( card ) => (
              <SummaryCard card={card} key={card.audiencePosition} />
            ) )
          }
        </Flex>
      </div>
      {
        step !== maxStepsCount && summaryCards.length > 3 && (
          <div className='summary-card-list-action-wrapper right'>
            <Button
              className='summary-card-list-action right'
              size='large'
              icon={<RightOutlined />}
              onClick={() => handleArrowClick( 'right' )}
            />
          </div>
        )
      }
    </Layout>
  );
};
