import './CustomTag.scss';

import { Popover, Tag, TagProps } from 'antd';
import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

interface ICustomTagProps extends TagProps {
  tagName?: string;
  tagValue: string[] | string;
  isVisible?: boolean;
  maxWidth?: number;
  maxPopoverWidth?: number;
}

const CustomTag = ( { tagName, tagValue, isVisible = true, style, maxWidth = 640, maxPopoverWidth, ...rest }: ICustomTagProps ) => {
  const tagRef = useRef<HTMLDivElement | null>( null );
  const [isOverflowed, setIsOverflowed] = useState( false );
  const actualTagValue = Array.isArray( tagValue ) ? tagValue?.join( ', ' ) : tagValue;
  const collapseTagClassName = classNames( 'collapse-tag', {
    'is-visible': isVisible,
    'is-hidden':  !isVisible,
  } );

  const completeTag = (
    <Tag
      ref={tagRef}
      style={{
        maxWidth,
        marginLeft:    2,
        marginRight:   2,
        overflow:      'hidden',
        whiteSpace:    'nowrap',
        textOverflow:  'ellipsis',
        ...style,
      }}
      {...rest}
    >
      {tagName && <span className='source'>{`${tagName}:`}</span>}
      <span>{actualTagValue}</span>
    </Tag>
  );

  useEffect( () => {
    const tagElement = tagRef.current;
    if ( tagElement ) {
      const isTextOverflowed = tagElement?.offsetWidth <= tagElement?.scrollWidth;
      setIsOverflowed( isTextOverflowed );
    }
  } );

  return (
    <div className={collapseTagClassName}>
      {isVisible && (
        <div>
          {isOverflowed
            ? (
              <Popover
                overlayStyle={{ maxWidth: maxPopoverWidth ?? maxWidth, wordWrap: 'break-word' }}
                content={actualTagValue}
                placement='bottom'
              >
                {completeTag}
              </Popover>
            )
            : (
              completeTag
            )}
        </div>
      )}
    </div>
  );
};

export default CustomTag;
