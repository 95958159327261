import { isEmpty } from 'lodash';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import FilledAudienceComparison from '../components/AudienceComparison/FilledAudienceComparison';
import AudienceComparisonEmpty from '../components/AudienceComparisonEmpty/AudienceComparisonEmpty';
import Blade from '../components/Blade/bladeCommon/Blade';
import {
  selectAppliedAudiencesToCompare,
} from '../store/audienceComparison/selectors';
import { selectIsWizardOpen } from '../store/wizzard/selectors';
import SharedLayout from './SharedLayout';

const AudienceComparison = () => {
  const [currentStep, setCurrentStep] = useState( 0 );

  const isWizardOpen = useSelector( selectIsWizardOpen );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );

  const handleNext = () => {
    setCurrentStep( currentStep + 1 );
  };

  const handlePrev = () => {
    setCurrentStep( currentStep - 1 );
  };

  return (
    <>
      <SharedLayout />
      {!isEmpty( appliedAudienceToCompare ) ? <FilledAudienceComparison /> : <AudienceComparisonEmpty />}
      <Blade
        open={isWizardOpen}
        currentStep={currentStep}
        handlePrev={handlePrev}
        handleNext={handleNext}
      />
    </>
  );
};

export default AudienceComparison;
