import './IndexDescription.scss';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Popover } from 'antd';

export const IndexDescription = () => (
  <div className='index-description'>
    <Popover
      placement='bottom'
      getPopupContainer={( node ) => node.parentElement!}
      overlayStyle={{ width: '377px', right: 0, left: 'auto' }}
      content={(
        <>
          <p>The index represents the relative difference between the Comparison and Benchmark audiences.</p>
          <p><span className='green'>Index {'>'} 120:</span> Indicates a value of 120 or higher,
                          suggesting a more significant and trustworthy over-representation of
                          this characteristic compared to the benchmark.
          </p>
          <p><span className='red'>Index {'<'} 80:</span> Indicates a value of 80 or lower,
                          suggesting a more significant and trustworthy under-representation of
                           this characteristic compared to the benchmark.
          </p>
          <p><b>80 ≤ Index ≤ 120:</b> Indicates a value between 80 and 120, implying a
                          less substantial and less reliable difference between the audiences.</p>
        </>
      )}
    >
      <InfoCircleOutlined style={{ color: 'rgba(0, 0, 0, 0.25)' }} />
    </Popover>
  </div>
);
