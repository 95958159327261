import { createAction } from '@reduxjs/toolkit';

import { AudienceParameters, IncomingScenario } from '../../apollo/graphql-types';
import { AUDIENCE_ID } from '../../components/Blade/collapse/const';
import { IAudienceComparisonItem, IAudienceComparisonState, IAudienceToCompare } from './typings';

export interface IDuplicatePayload {
  audienceToDuplicate: AUDIENCE_ID;
  newAudienceId: AUDIENCE_ID;
}

export const setPreBuiltAudienceIds = createAction<[AUDIENCE_ID, number[] | undefined]>( 'audienceComparison/setPreBuiltAudienceIds' );
export const setAudienceParameters = createAction<[
  AUDIENCE_ID,
  Partial<AudienceParameters> | undefined]
>( 'audienceComparison/setAudienceParameters' );
export const setAudienceComparisonStats = createAction
<IAudienceComparisonState['stats']>( 'audienceComparison/setAudienceComparisonStats' );
export const setAudienceComparisonIsLoading = createAction<boolean>( 'audienceComparison/setAudienceComparisonIsLoading' );
export const setAudienceComparisonIsAborting = createAction<boolean>( 'audienceComparison/setAudienceComparisonIsAborting' );
export const resetAudienceComparison = createAction( 'audienceComparison/resetAudienceComparison' );
export const setAudiencesToCompare = createAction<Record<AUDIENCE_ID, IAudienceToCompare>>( 'audienceComparison/setAudiencesToCompare' );
export const setAppliedAudiencesToCompare = createAction
<Record<AUDIENCE_ID, IAudienceToCompare>>( 'audienceComparison/setAppliedAudiencesToCompare' );
export const deleteAudience = createAction<AUDIENCE_ID>( 'audienceComparison/deleteAudience' );
export const setSingleAudienceById = createAction<AUDIENCE_ID>( 'audienceComparison/setSingleAudienceById' );
export const duplicateAudienceById = createAction<IDuplicatePayload>( 'audienceComparison/duplicateAudienceById' );
export const setScenario = createAction<IncomingScenario>( 'audienceComparison/setScenario' );
export const setAppliedScenario = createAction<IncomingScenario>( 'audienceComparison/setAppliedScenario' );
