import './Filter.scss';

import { FilterOutlined, MinusOutlined, PlusOutlined, ReloadOutlined, UpOutlined } from '@ant-design/icons';
import { Badge, Button, Collapse, Drawer } from 'antd';
import Search from 'antd/es/input/Search';
import Tree from 'antd/es/tree';
import cn from 'classnames';
import flatten from 'lodash/flatten';
import fromPairs from 'lodash/fromPairs';
import isEqual from 'lodash/isEqual';
import mergeWith from 'lodash/mergeWith';
import uniq from 'lodash/uniq';
import { useEffect, useMemo, useState } from 'react';

import { FilterAttribute, FilterAttributeGroup, useFilteringAttributesQuery } from '../../../../apollo/graphql-types';
import { Entries, Keys } from '../../../../store/typings';
import { options } from '../../../Blade/collapse/const';
import { usePrevious } from './hooks';
import { checkSearchValue, customizer, getDefaultExpandedAndCheckedKeys, getSelectedKeysCount } from './utils';

type TFilterProps = {
  setAttributesToShow: React.Dispatch<React.SetStateAction<string[]>>;
} & JSX.IntrinsicElements['div'];

export const Filter = ( { setAttributesToShow, className }: TFilterProps ) => {
  const { data } = useFilteringAttributesQuery( options() );

  const defaultExpandedAndCheckedKeys = useMemo( () => getDefaultExpandedAndCheckedKeys( data?.filteringAttributes ), [data] );

  const [open, setOpen] = useState( false );
  const [checkedKeys, setCheckedKeys] = useState<Record<string, string[]>>( defaultExpandedAndCheckedKeys[0] );
  const [appliedKeys, setAppliedKeys] = useState<Record<string, string[]>>( defaultExpandedAndCheckedKeys[0] );
  const [expandedKeys, setExpandedKeys] = useState<Record<string, string[]>>( defaultExpandedAndCheckedKeys[1] );
  const [searchText, setSearchText] = useState<string>( '' );
  const [searchValue, setSearchValue] = useState<string>( '' );
  const [searchCount, setSearchCount] = useState( 0 );
  const [selectedCountAll, setSelectedCountAll] = useState( 0 );
  const [collapseActiveKeys, setCollapseActiveKeys] = useState<any>( Object.keys( defaultExpandedAndCheckedKeys[0] ) );

  const prevSearchValue = usePrevious( searchText );

  useEffect( () => {
    setCheckedKeys( defaultExpandedAndCheckedKeys[0] );
    setAppliedKeys( defaultExpandedAndCheckedKeys[0] );
    setExpandedKeys( defaultExpandedAndCheckedKeys[1] );
    setCollapseActiveKeys( Object.keys( defaultExpandedAndCheckedKeys[0] ) );
  }, [defaultExpandedAndCheckedKeys] );

  const collapseItems = useMemo( () => {
    let searchCount = 0;
    let selectedCount = 0;
    const foundKeys: Record<string, string[]> = {};

    const items = data?.filteringAttributes.map( ( category ) => {
      let children: any = 'No attributes yet';

      const selectedKeysCount = getSelectedKeysCount( checkedKeys[category.categoryName], category );
      selectedCount += selectedKeysCount;

      const attributes = flatten<FilterAttribute | FilterAttributeGroup>( [category.attributes || [], category.attributeGroups || []] );

      const treeData = attributes.map( ( attributeGroup ) => {
        let childrenAttributesGroup = undefined;
        const name = 'groupName' in attributeGroup ? attributeGroup.groupName : attributeGroup.attributeName;

        if ( checkSearchValue( name, searchText ) ) {
          searchCount++;
          if ( !foundKeys[category.categoryName] ) {
            foundKeys[category.categoryName] = [];
          }

          if ( !foundKeys[category.categoryName].includes( name ) ) {
            foundKeys[category.categoryName].push( `GROUP - ${name}` );
          }
        }

        if ( 'attributes' in attributeGroup ) {
          childrenAttributesGroup = attributeGroup.attributes?.map( ( attribute ) => {
            if ( checkSearchValue( attribute.attributeName, searchText ) ) {
              searchCount++;
              if ( !foundKeys[category.categoryName] ) {
                foundKeys[category.categoryName] = [];
              }
              if ( !foundKeys[category.categoryName].includes( name ) ) {
                foundKeys[category.categoryName].push( `GROUP - ${name}` );
              }
            }

            return ( {
              key:       attribute.attributeName,
              className: cn( { highlight:  checkSearchValue( attribute.attributeName, searchText ) } ),
              title:     attribute.attributeName,
            } );
          } ) ;
        }

        return {
          key:       'techNameId' in attributeGroup ? name : `GROUP - ${name}`,
          title:     name,
          children:  childrenAttributesGroup,
          className: cn( {
            parent:    'attributes' in attributeGroup ? !!attributeGroup.attributes?.length : false,
            highlight:  checkSearchValue( name, searchText ),
          } ),
        };
      } );

      if ( !!treeData?.length ) {
        children = <Tree
          checkable
          selectable={false}
          treeData={treeData}
          switcherIcon={<UpOutlined />}
          defaultCheckedKeys={checkedKeys[category.categoryName]}
          defaultExpandedKeys={expandedKeys[category.categoryName]}
          checkedKeys={checkedKeys[category.categoryName]}
          expandedKeys={expandedKeys[category.categoryName]}
          onCheck={( checked ) => {
            setCheckedKeys( { ...checkedKeys, [category.categoryName]: checked as string[] } );
          }}
          onExpand={( expandedKeysValue ) => {
            setExpandedKeys( { ...expandedKeys, [category.categoryName]: expandedKeysValue as string[] } );
          }}
        />;
      }

      const finalItem: any = {
        children,
        key:        category.categoryName,
        label:      category.categoryName,
      };

      if ( !!selectedKeysCount ) {
        finalItem.extra = <Badge count={selectedKeysCount} color='#0000A0' size='small'/>;
      }

      return finalItem;
    } );

    if ( prevSearchValue !== searchText && !isEqual( expandedKeys, mergeWith( { ...expandedKeys }, { ...foundKeys }, customizer ) ) ) {
      setExpandedKeys( mergeWith( { ...expandedKeys }, { ...foundKeys }, customizer ) );
    }

    setSearchCount( searchCount );
    setSelectedCountAll( selectedCount );
    setCollapseActiveKeys( [...collapseActiveKeys, ...Object.keys( foundKeys )] );

    return items;
  }, [data, checkedKeys, expandedKeys, searchText] );

  const resetSearch = () => {
    setSearchValue( '' );
    setSearchText( '' );
  };

  return (
    <>
      <Button
        className={cn( 'attributes-comparison-button', className )}
        icon={<FilterOutlined />}
        onClick={() => setOpen( true )}
      >
        Show Attributes ({selectedCountAll})
      </Button>
      <Drawer
        width={400}
        open={open}
        maskClosable={false}
        destroyOnClose
        title='Attributes'
        className='attributes-comparison-drawer'
        afterOpenChange={( open ) => {
          if ( !open ) {
            setCheckedKeys( appliedKeys );
            setExpandedKeys( fromPairs( ( Object.entries( appliedKeys ) as unknown as Entries<typeof appliedKeys> )
              .map( ( [key, value] ) =>
                [key, uniq( value.map( ( attrKey ) => attrKey.toString().split( '-' ).slice( 0, 2 ).join( '-' ) ) )]
              )
            ) );
            setCollapseActiveKeys(
              ( Object.keys( appliedKeys ) as unknown as Keys<typeof appliedKeys> ).filter( ( key ) => !!appliedKeys[key].length ) );
            resetSearch();
          }
        }}
        onClose={() => {
          setOpen( false );
        }}
        extra={
          <>
            <Button
              type='text'
              icon={<ReloadOutlined />}
              onClick={() => setCheckedKeys( defaultExpandedAndCheckedKeys[0] ) }
              disabled={isEqual( checkedKeys, defaultExpandedAndCheckedKeys[0] )}
            >
              Reset
            </Button>
            <Button
              type='primary'
              disabled={!flatten( Object.values( checkedKeys ) ).length || isEqual( checkedKeys, appliedKeys )}
              onClick={() => {
                setOpen( false );
                setAppliedKeys( checkedKeys );
                setAttributesToShow( flatten( Object.values( checkedKeys ) ).filter( ( attr ) => !attr.includes( 'GROUP - ' ) ) );
              }}
            >
              Apply
            </Button>
          </>
        }
      >
        <div className='search'>
          <Search
            allowClear
            placeholder='Search'
            onSearch={( value ) => {
              const validatedValue = value.trim().replace( / +/g, ' ' );
              setSearchText( validatedValue );
              setSearchValue( validatedValue );
            }}
            onChange={( e ) => setSearchValue( e.target.value )}
            value={searchValue}
            className={cn( {
              'show-clear-icon': !!searchValue || !!searchText,
            } )}
          />
          {( !!searchText.length && !!searchCount )
           && <span className='search-matches'>{searchCount} match{searchCount > 1 ? 'es' : ''} found</span>}

          {!!searchText.length && !searchCount && <span className='search-matches'>No matches found</span>}
        </div>
        <Collapse
          ghost
          items={collapseItems}
          expandIconPosition='end'
          expandIcon={( { isActive } ) => isActive ? <MinusOutlined /> : <PlusOutlined />}
          defaultActiveKey={collapseActiveKeys}
          onChange={( activeKeys ) => {
            setCollapseActiveKeys( activeKeys );
          }}
          activeKey={ collapseActiveKeys }
        />
      </Drawer>
    </>
  );
};
