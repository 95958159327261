import { HeartFilled, HeartOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import cn from 'classnames';

import {
  Attribute,
  useAddFavoriteAttributesMutation,
  useDeleteFavoriteAttributesMutation,
} from '../../../../apollo/graphql-types';
import { generateFavorite } from './utils';

interface IFavouriteAttributeProps {
  attribute: Attribute;
  successMessage: ( content: React.ReactNode ) => void;
  errorMessage: () => void;
}

export const FavouriteAttribute = ( { attribute, successMessage, errorMessage }: IFavouriteAttributeProps ) => {
  const [addFavorite, {
    loading: addingFavorite,
  }] = useAddFavoriteAttributesMutation( {
    variables: {
      attributes: [attribute.identifierName],
    },
    update:    ( cache, { data } ) => {
      cache.modify( {
        fields: {
          barcharts ( barcharts: any ) {
            if ( !data?.addFavoriteAttributes[0] ) {
              return barcharts;
            }

            return generateFavorite( barcharts, data?.addFavoriteAttributes[0], false );
          },
        },
      } );
    },
  } );
  const [deleteFavorite, {
    loading: deletingFavorite,
  }] = useDeleteFavoriteAttributesMutation( {
    variables: {
      attributes: [attribute.identifierName],
    },
    update:    ( cache, { data } ) => {
      cache.modify( {
        fields: {
          barcharts ( barcharts: any ) {
            if ( !data?.deleteFavoriteAttributes?.[0] ) {
              return barcharts;
            }

            return generateFavorite( barcharts, data?.deleteFavoriteAttributes[0], true );
          },
        },
      } );
    },
  } );

  const handleFavouriteClick = async () => {
    try {
      if ( !attribute.favorite ) {
        const response = await addFavorite();

        if ( !!response && !response.errors ) {
          successMessage( `Attribute ${attribute.attributeName} is added to Favourites` );
        } else {
          errorMessage();
        }
      } else {
        const response = await deleteFavorite();

        if ( !!response && !response.errors ) {
          successMessage( `Attribute ${attribute.attributeName} is removed to Favourites` );
        } else {
          errorMessage();
        }
      }
    } catch ( error ) {
      console.error(error); //eslint-disable-line
      errorMessage();
    }
  };

  return (
    <>
      <Button
        loading={addingFavorite || deletingFavorite}
        type='link'
        className={cn( 'favourite-button', { checked: attribute.favorite } )}
        icon={attribute.favorite ? <HeartFilled /> : <HeartOutlined />}
        onClick={handleFavouriteClick}
      >Favourite</Button>
    </>
  );
};
