import { InfoCircleOutlined, MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Alert, Button, Collapse, Divider, Popover, Radio, RadioChangeEvent, Space } from 'antd';
import { isEmpty } from 'lodash';
import sortBy from 'lodash/sortBy';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Origin, useBrandMarketingCampaignsLazyQuery, useGetBrandsQuery } from '../../../../apollo/graphql-types';
import { setAudienceParameters } from '../../../../store/audienceComparison/actions';
import {
  selectAppliedAudiencesToCompare,
  selectAudiencesToCompare,
  selectBrandsByAudienceId,
  selectCategoryByAudienceId,
  selectIsEqualComparisons,
  selectOriginByAudienceId,
  selectTimeFilterFromByAudienceId,
  selectTimeFilterToByAudienceId,
} from '../../../../store/audienceComparison/selectors';
import { setSpecificTab } from '../../../../store/tab/actions';
import {
  collapseAllWizards,
  resetAdditionalParametersWizard,
  setAdditionalParametersWizard,
  setMarsBrandsWizard,
  setOriginWizard,
  setSourceWizard,
} from '../../../../store/wizzard/actions';
import { selectWizardOriginByTabName } from '../../../../store/wizzard/selectors';
import { DEFAULT_BUYER_TYPES } from '../../../../typings';
import { getBrandsByCategoryId, isMarsManufacturer } from '../../../../utils/brand.utils';
import { partyDataToName } from '../../../AudienceComparison/const';
import { ErrorRetryCircle } from '../../../Error/ErrorRetryCircle';
import TitleWithCaption from '../../../TitleWithCaption/TitleWithCaption';
import BladeLoader from '../../bladeCommon/BladeLoader';
import { STEP_NAMES, TAG_VALUES, WIZARD_NAME } from '../../types';
import CollapseHeader from '../CollapseHeader';
import { AUDIENCE_ID, options, stepErrorDescription } from '../const';
import { ICollapseProps } from '../types';
import { handleScrollIntoView } from '../utils';

interface IOriginCollapseProps extends ICollapseProps {
  activeTab: AUDIENCE_ID;
}

const OriginCollapse = ( { handleCollapseChange, expandIconStyles, activeTab }: IOriginCollapseProps ) => {
  const dispatch = useDispatch();
  const { data: marsBrandsData, loading: isMarsBrandsLoading, error: errorMarsBrands, refetch: refetchMarsBrands } = useGetBrandsQuery(
    {
      ...options(),
      notifyOnNetworkStatusChange: true,
    }
  );

  const originWizard = useSelector( selectWizardOriginByTabName( activeTab ) );

  const selectedOrigin = useSelector( selectOriginByAudienceId( activeTab ) );
  const selectedBrands = useSelector( selectBrandsByAudienceId( activeTab ) );
  const selectedCategory = useSelector( selectCategoryByAudienceId( activeTab ) );
  const selectedFromDate = useSelector( selectTimeFilterFromByAudienceId( activeTab ) );
  const selectedToDate = useSelector( selectTimeFilterToByAudienceId( activeTab ) );

  const audiencesToCompare = useSelector( selectAudiencesToCompare );
  const appliedAudienceToCompare = useSelector( selectAppliedAudiencesToCompare );
  const isEqualComparisons = useSelector( selectIsEqualComparisons );


  const availableBrands = getBrandsByCategoryId( selectedCategory, marsBrandsData?.categorizedBrands || [] );

  const firstPartyBrands = availableBrands?.filter( ( brand ) => brand.firstParty && selectedBrands?.includes( brand.brandId ) );
  const thirdPartyBrands = availableBrands?.filter( ( brand ) => brand.thirdParty && selectedBrands?.includes( brand.brandId ) );


  const isOnlyOneParty = ( isEmpty( firstPartyBrands ) && !isEmpty( thirdPartyBrands ) )
  || ( !isEmpty( firstPartyBrands ) && isEmpty( thirdPartyBrands ) );

  const scrollRef = useRef<HTMLDivElement | null>( null );

  useEffect( () => {
    if ( !!errorMarsBrands?.message ) {
      console.error(`Loading data error: ${errorMarsBrands?.message}`); //eslint-disable-line
    }
  }, [errorMarsBrands] );

  const setOrigin = ( origin: Origin, includeBuyerTypes?: boolean ) => {
    dispatch( setAudienceParameters( [activeTab, {
      origin,
      buyerTypes:         includeBuyerTypes ? DEFAULT_BUYER_TYPES : undefined,
      marketingCampaigns: undefined,
      engagementStatus:   undefined,
      d2c:                undefined,
      flavors:            undefined,
      sizes:              undefined,
      toDate:             undefined,
      fromDate:           undefined,
    }] ) );
  };

  // When we change value in 'Origin' Button Group
  const handleRadioGroupChange = ( e: RadioChangeEvent ) => {
    handleScrollIntoView( scrollRef );

    dispatch(
      setOriginWizard( {
        isFinished: false,
        activeTab,
      } ),
    );
    dispatch( resetAdditionalParametersWizard( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    setOrigin( e.target.value );
  };

  // When we click 'Finish Wizard' after 'First-Party Data' choice
  const handleFinishWizardClick = ( parameter: Origin ) => {
    setOrigin( parameter );

    dispatch(
      setOriginWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( resetAdditionalParametersWizard( activeTab ) );
    dispatch( collapseAllWizards( activeTab ) );
    dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
  };

  // When we click 'Next - Additional Parameters' after 'First-Party Data' choice
  const handleAdditionalParametersClick = ( parameter: Origin ) => {
    setOrigin( parameter, isOnlyOneParty && firstPartyBrands?.length ? false : true );

    dispatch(
      setOriginWizard( {
        activeTab,
        isFinished: true,
        isExpand:   false,
      } ),
    );
    dispatch( setAdditionalParametersWizard( { isExpand: true, isVisible: true, activeTab } ) );
    dispatch( setSourceWizard( { activeTab, isExpand: false } ) );
    dispatch( setMarsBrandsWizard( { activeTab, isExpand: false } ) );
  };

  useEffect( () => {
    if ( !originWizard.isFinished ) {
      dispatch( setSpecificTab( { activeTab, isFinished: false } ) );
    }
  }, [originWizard.isFinished] );

  useEffect( () => {
    if ( isOnlyOneParty && !selectedOrigin ) {
      setOrigin( firstPartyBrands?.length ? Origin.FirstParty : Origin.ThirdParty );
    }

    if ( isOnlyOneParty || !!selectedOrigin ) {
      handleScrollIntoView( scrollRef );
    }
  }, [selectedOrigin, isOnlyOneParty] );

  useEffect( () => {
    if ( !isEmpty( appliedAudienceToCompare ) && isEqualComparisons ) {
      const audience = audiencesToCompare[activeTab];
      const isMarsBrandsSource = isMarsManufacturer( audience.audienceParameters?.manufacturers );

      if ( isMarsBrandsSource ) {
        dispatch(
          setOriginWizard( {
            activeTab,
            isFinished: true,
          } ),
        );

        if (
          !audience.audienceParameters?.marketingCampaigns
           && !audience.audienceParameters?.d2c
          && !audience.audienceParameters?.buyerTypes
          && !audience.audienceParameters?.flavors
          && !audience.audienceParameters?.sizes
          && !selectedToDate
          && !selectedFromDate
        ) {
          dispatch( setSpecificTab( { activeTab, isFinished: true } ) );
        } else {
          dispatch( setAdditionalParametersWizard( { activeTab, isVisible: true } ) );
        }
      }
    }
  }, [] );

  const getNoteCaption = () => {
    const brands = selectedOrigin === Origin.FirstParty ? firstPartyBrands : thirdPartyBrands;
    if ( !selectedOrigin || brands?.length === selectedBrands?.length ) return '';
    return `*${partyDataToName[selectedOrigin]} is available only for ${brands?.map( ( brand ) => brand.brandName ).join( ', ' )}`;
  };

  const getLabelText = ( origin: Origin ) => (
    <>
      {origin === Origin.FirstParty ? partyDataToName.FIRST_PARTY: partyDataToName.THIRD_PARTY}
      <Popover content={
        origin === Origin.FirstParty ? 'Data from brand-specific campaigns and websites' : 'Offline transaction data'
      } trigger='hover' placement='bottom'>
        <InfoCircleOutlined className='info-circle' />
      </Popover>
    </>

  );

  const collapseItems = [
    {
      key:   WIZARD_NAME.ORIGIN,
      label:  <CollapseHeader
        step={WIZARD_NAME.ORIGIN}
        heading={`Step 03: ${STEP_NAMES.ORIGIN}`}
        caption='Select an origin for a selected brand'
        tags={[{
          name:  TAG_VALUES.ORIGIN,
          value: selectedOrigin === Origin.FirstParty ? partyDataToName.FIRST_PARTY : partyDataToName.THIRD_PARTY,
        }]}
      />,
      children: <>
        {isOnlyOneParty
          ? (
            <Alert
              message={
                `We have only 
                ${!!selectedOrigin && partyDataToName[selectedOrigin]} for 
                ${( selectedBrands?.length || 0 ) > 1 ? 'these brands.' : 'this brand.'} `
              }
              type='info'
            />
          )
          : (
            <>
              <Radio.Group
                options={[
                  { label: getLabelText( Origin.FirstParty ), value: Origin.FirstParty },
                  { label: getLabelText( Origin.ThirdParty ), value: Origin.ThirdParty },
                ]}
                className='two-wide-button-group'
                optionType='button'
                buttonStyle='solid'
                value={selectedOrigin}
                style={{ width: '100%' }}
                onChange={handleRadioGroupChange}
              />
              {( selectedBrands?.length || 0 ) > 1 && (
                <div className='note-caption'>
                  <TitleWithCaption
                    caption={getNoteCaption() }
                    captionFontSize={12}
                  />
                </div>
              )}

            </>
          )}
        <div ref={scrollRef}>
          {!!selectedOrigin && (
            <>
              <Divider style={{ margin: '1rem 0 1rem' }} />
              <Space className='next-button'>
                <Button onClick={() => handleAdditionalParametersClick( selectedOrigin )}>Next - Additional Parameters</Button>
                <Button onClick={() => handleFinishWizardClick( selectedOrigin )}>
                  Finish Wizard
                  <Popover
                    content={
                      `The audience will be created based on ${ selectedOrigin === Origin.FirstParty
                        ? 'brand-specific campaigns and websites'
                        : 'offline transaction data only'}
                      `
                    }
                    trigger='hover'
                    placement='bottom'
                  >
                    <InfoCircleOutlined className='info-circle' />
                  </Popover>
                </Button>
              </Space>
            </>
          )}
        </div>
      </>,
    },
  ];

  return !!errorMarsBrands
    ? (
      <ErrorRetryCircle
        title='Step Load Failed'
        description={stepErrorDescription}
        onClick={() => {
          refetchMarsBrands();
        }}
      />
    )
    : isMarsBrandsLoading
      ? (
        <BladeLoader />
      )
      : (
        <Collapse
          items={collapseItems}
          defaultActiveKey={WIZARD_NAME.ORIGIN}
          activeKey={originWizard.isExpand ? WIZARD_NAME.ORIGIN : ''}
          collapsible='icon'
          expandIconPosition='end'
          onChange={() => handleCollapseChange( setOriginWizard, originWizard )}
          expandIcon={() =>
            originWizard.isExpand ? <MinusOutlined style={expandIconStyles} /> : <PlusOutlined style={expandIconStyles} />
          }
        />
      );
};

export default OriginCollapse;
