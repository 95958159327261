import './SubmitForActivation.scss';

import { DownOutlined, FilterOutlined, LoadingOutlined, PoweroffOutlined } from '@ant-design/icons';
import { Button, Dropdown, message } from 'antd';
import { useContext, useState } from 'react';
import { useSelector } from 'react-redux';

import { IncomingScenario, useSubmitAudienceForActivationMutation } from '../../../../apollo/graphql-types';
import { selectAppliedScenario } from '../../../../store/audienceComparison/selectors';
import { AUDIENCE_ID, options } from '../../../Blade/collapse/const';
import { AudienceNamesContext } from '../../FilledAudienceComparison';
import { SubmitForActivationModal } from './SubmitForActivationModal';

interface ISubmitForActivationProps {
  requestId: string;
  position: string;
  segmentId: string;
}

export const SubmitForActivation = ( { requestId, position, segmentId }: ISubmitForActivationProps ) => {
  const scenario = useSelector( selectAppliedScenario );
  const [messageApi, contextHolder] = message.useMessage();
  const [submitForActivation, { loading }] = useSubmitAudienceForActivationMutation( { ...options(), notifyOnNetworkStatusChange: true } );
  const audienceNames = useContext( AudienceNamesContext );
  const [open, setOpen] = useState( false );
  const submitError = <>Submission failed. Please retry or contact
    <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
  if the issue continues.
  </>;
  const submittedAudiences = [IncomingScenario.Exclusive, IncomingScenario.Overlap].includes( scenario )
    ? Object.values( audienceNames ).join( ' VS ' )
    : position === 'audienceNR' ? 'National Reference' : audienceNames[position as AUDIENCE_ID];

  const errorMessage = () => {
    messageApi.open( {
      type:    'error',
      content: submitError,
    } );
  };

  const successMessage = () => {
    messageApi.open( {
      type:    'success',
      content: 'Submission received. Xi Chen will contact you for the next steps. Thank you for your patience.',
    } );
  };

  const items = [
    {
      label:   'Specify Consumer Attributes',
      icon:    <FilterOutlined />,
      key:     'specify',
      onClick: () => {
        setOpen( true );
      },
    },
    {
      label:   'Submit for Activation',
      icon:    <PoweroffOutlined />,
      key:     'submit',
      onClick: () => {
        if ( !requestId || !segmentId ) {
          errorMessage();
          return;
        }

        submitForActivation( { variables: {
          audienceActivation: {
            requestId,
            segmentId,
            scenario,
            submittedAudiences,
          },
        } } )
          .then( ( { data, errors } ) => {
            if ( !errors && !!data?.submitAudienceForActivation.authorEmail ) {
              successMessage();
            } else {
              errorMessage();
            }
          } )
          .catch( () => errorMessage() );
      },
    },
  ];

  return <div className='submit-for-activation'>
    {contextHolder}
    <Dropdown
      menu={{ items }}
      placement='bottom'
      trigger={['click']}
      getPopupContainer={( node ) => node.parentElement!}
    >
      <Button type='link' className='activation-button'>Activation {loading ? <LoadingOutlined /> : <DownOutlined/>}</Button>
    </Dropdown>

    {open && <SubmitForActivationModal
      open={open}
      setOpen={setOpen}
      requestData={{ requestId, segmentId, scenario, submittedAudiences }}
      errorMessage={errorMessage}
      successMessage={successMessage}
    />}
  </div>;
};
