import './SummaryCardDetails.scss';

import { Alert, Divider, Drawer, Space, Spin } from 'antd';
import { useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';

import {
  AttributesCalcInitResult,
  IncomingScenario,
  SegmentCalculationAnnotation,
  useDetailedSummaryCardLazyQuery,
} from '../../../apollo/graphql-types';
import { selectAppliedScenario } from '../../../store/audienceComparison/selectors';
import { AUDIENCE_ID, NATIONAL_REFERENCE, options } from '../../Blade/collapse/const';
import Summary from '../../Blade/Summary/Summary';
import CustomTag from '../../CustomTag/CustomTag';
import { ErrorRetryCircle } from '../../Error/ErrorRetryCircle';
import TitleWithCaption from '../../TitleWithCaption/TitleWithCaption';
import { DefaultAttributes } from '../DefaultAttributes/DefaultAttributes';
import { AudienceNamesContext, AudienceRequestContext } from '../FilledAudienceComparison';
import { DetailedAttributes } from './DetailedAttributes';
import { IndexDescription } from './IndexDescription';

interface ISummaryCardDetailsProps {
  open: boolean;
  name: string;
  segmentId: string;
  audienceId: AUDIENCE_ID;
  handleClose: () => void;
}

export const SummaryCardDetails = ( {
  open,
  name,
  segmentId,
  audienceId,
  handleClose,
}: ISummaryCardDetailsProps ) => {
  const audienceNames = useContext( AudienceNamesContext );
  const scenario = useSelector( selectAppliedScenario );
  const requestData = useContext( AudienceRequestContext );

  const [loadDetailedSummary, { data: summaryCardDetails, loading, error, refetch }] = useDetailedSummaryCardLazyQuery();

  useEffect( () => {
    if ( open && !!segmentId && !!requestData?.requestId ) {
      loadDetailedSummary( {
        ...options(),
        notifyOnNetworkStatusChange: true,
        variables:                   {
          summaryCardPrerequisites: {
            segmentId:  segmentId,
            requestId:  requestData.requestId,
            queryId:    requestData.queryId,
          },
        },
      } );
    }
  }, [open] );

  return (
    <Drawer
      rootClassName='summary-card-details'
      maskClosable={false}
      title={<TitleWithCaption
        headingLevel={4}
        heading={name}
        headingStyles={{ fontFamily: 'WorkSans-SemiBold' }}
      />}
      placement='right'
      size='large'
      onClose={handleClose}
      open={open}
    >
      {
        error
          ? (
            <div className='error-wrapper'>
              <ErrorRetryCircle
                title='Data Load Failed'
                description={ <>
              Data couldn't be loaded. Please retry or contact
                  <a target='_blank' href='mailto:CDS_SUPPORT@effem.com?subject=CDS Error'> support </a>
             if the issue persists.
                </>}
                onClick={() => {
                  refetch();
                }}
              />
            </div>
          )
          : loading
            ? (
              <Space className='loader' size='middle'>
                <Spin />
              </Space>
            )
            : (
              <>
                {audienceId === 'audienceNR' as AUDIENCE_ID
                  ? <CustomTag
                    tagValue={NATIONAL_REFERENCE}
                    key={NATIONAL_REFERENCE}
                  />
                  : <Summary
                    audienceToShow={[IncomingScenario.Exclusive, IncomingScenario.Overlap].includes( scenario ) ? undefined : audienceId}
                    finishedOnly={false}
                  />}
                <Divider />
                <p className='summary-card-details-description'>{summaryCardDetails?.detailedSummaryCard.description}</p>
                {(
                  ( scenario === IncomingScenario.Compare && audienceId !== AUDIENCE_ID.AUDIENCE_A )
                  || ( [IncomingScenario.Overlap, IncomingScenario.Exclusive, IncomingScenario.Profile].includes( scenario )
                    && audienceId !== 'audienceNR' as AUDIENCE_ID
                  )
                )
                  && (
                    <Alert
                      message={
                        `Index is calculated against ${scenario === IncomingScenario.Compare
                          ? `Benchmark Audience: ${audienceNames.audienceA}`
                          : `${NATIONAL_REFERENCE}.`
                        }`
                      }
                      type='info'
                      rootClassName='summary-card-details-info-message'
                      action={
                        <IndexDescription />
                      }
                    />
                  )
                }
                {summaryCardDetails?.detailedSummaryCard.defaultAttributes && (
                  <DefaultAttributes defaultAttributes={summaryCardDetails?.detailedSummaryCard.defaultAttributes} />
                )}
                {summaryCardDetails?.detailedSummaryCard.detailedAttributes && (
                  <DetailedAttributes detailedAttributes={summaryCardDetails?.detailedSummaryCard.detailedAttributes} />
                )}
              </>
            )
      }
    </Drawer>
  );
};
