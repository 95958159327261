import { Attribute, AttributeGroup } from '../../apollo/graphql-types';


export enum EView {
  list = 'list',
  grid = 'grid'
}

export interface IFavoriteTabItem {
  categoryName: string;
  attributes?: Array<Attribute>;
  groups?: Array<AttributeGroup>;
}
