import { createReducer } from '@reduxjs/toolkit';
import { fromPairs, omit } from 'lodash';

import { AUDIENCE_ID, audiencesIdList } from '../../components/Blade/collapse/const';
import * as actions from './actions';


export interface ITabSlice {
  activeTab: AUDIENCE_ID;
  tabsList: Record<AUDIENCE_ID, ITabItem>;
}

export interface ITabItem {
  isFinished: boolean;
}

const initialTabs = {
  [AUDIENCE_ID.AUDIENCE_A]: {} as ITabItem,
  [AUDIENCE_ID.AUDIENCE_B]: {} as ITabItem,
  [AUDIENCE_ID.AUDIENCE_C]: {} as ITabItem,
  [AUDIENCE_ID.AUDIENCE_D]: {} as ITabItem,
  [AUDIENCE_ID.AUDIENCE_E]: {} as ITabItem,
  [AUDIENCE_ID.AUDIENCE_F]: {} as ITabItem,
};

const initialState: ITabSlice = {
  activeTab: AUDIENCE_ID.AUDIENCE_A,
  tabsList:  initialTabs,
};

export const tabsReducer = createReducer<ITabSlice>(
  initialState,
  ( builder ) =>
	  builder
      .addCase( actions.setActiveTab, ( state, { payload } ) => {
        state.activeTab = payload;

        return state;
      } )
      .addCase( actions.resetTabs, ( state ) => {
        state = initialState;
        return state;
      } )
      .addCase( actions.setExclusiveTabs, ( state ) => {
        const audienceA = state.tabsList.audienceA;
        const audienceB = state.tabsList.audienceB;

        state.tabsList = {
          ...initialTabs,
          [AUDIENCE_ID.AUDIENCE_A]: audienceA,
          [AUDIENCE_ID.AUDIENCE_B]: audienceB,
        };

        return state;
      } )
      .addCase( actions.setSpecificTab, ( state, { payload } ) => {
        const activeTab = payload.activeTab;
        const reset = payload.reset;

        if ( !activeTab ) return state;

        if ( reset ) {
          state.tabsList[activeTab] = { ...initialState.tabsList.audienceA };
          return state;
        }

        state.tabsList[activeTab] = { ...state.tabsList[activeTab], ...omit( payload, 'activeTab' ) };
      } )
      .addCase( actions.deleteTab, ( state, { payload } ) => {
        delete state.tabsList[payload];
        const filteredTabs = Object.values( state.tabsList ).filter( ( value ) => !!value );

        const newTabList = fromPairs(
          audiencesIdList
            .map( ( id, index ) => ( [id, filteredTabs[index] ?? initialState.tabsList.audienceA] ) )
        ) as ITabSlice['tabsList'];

        state.tabsList = newTabList;
        return state;
      } )
);
